import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MatSelectionList, MAT_DIALOG_DATA } from '@angular/material';
import { DateConversionService } from '../../../../core/services/date-conversion.service';
import { CandidateProfileService, client } from '../../services/candidate-profile.service';
import { AdminFilterSelectionComponent } from '../transferee-list-filter/filter-selection/filter-selection.component';
@Component({
  selector: 'app-unprocessed-filter-modal',
  templateUrl: './unprocessed-filter-modal.component.html',
  styleUrls: ['./unprocessed-filter-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class UnprocessedFilterModalComponent implements OnInit {
  statusStartDate: Date | string;
  statusEndDate: Date | string;

  selectedFilters: {
    [filterType: string]: any
  } = {};

  disableFilter = false;
  authorizationDate = {};
  clientList: client[];
  clients = new FormControl([]);

  statusCheckBoxList = [
    { id : 0, name : "Submission Failure",checked : false},
    { id : 1, name : "Duplicate Check", checked : false}
  ];

  selectedStatus: string[] = [];

  constructor(
    public filterDialogRef: MatDialogRef<AdminFilterSelectionComponent>,
    public dialog: MatDialog,
    private candidateService: CandidateProfileService,
    private dateConversionService: DateConversionService,
    @Inject(MAT_DIALOG_DATA) public data: any)
   { }

  ngOnInit() {
    const dateConvert = this.convertDateToUTC;
    if(this.data.selectedUnprocessedFilters) {
      this.selectedFilters = this.data.selectedUnprocessedFilters;
      this.clients.setValue(this.data.selectedUnprocessedFilters.clientPartyIds);

      this.selectedStatus = this.data.selectedUnprocessedFilters.status ? this.data.selectedUnprocessedFilters.status : [];
      if(this.selectedStatus && this.selectedStatus.length > 0) {
        for (let value of this.statusCheckBoxList) {
          for (let selectedValue of this.selectedStatus) {
            if(value.name === selectedValue)
              value.checked = true;
          }
        }
      } 
    }
    if(this.data && this.data.selectedAuthDateRange && (this.data.selectedAuthDateRange.startDate  || this.data.selectedAuthDateRange.endDate)){
      this.authorizationDate = this.data.selectedAuthDateRange;
      this.statusStartDate = dateConvert(this.data.selectedAuthDateRange.startDate);
      this.statusEndDate = dateConvert(this.data.selectedAuthDateRange.endDate);
    }
     this.getClientList();
  }

  onSubmit(): void {
    this.filterDialogRef.close(this.selectedFilters);
    this.candidateService.unprocessedFilesFiletrs.next(this.selectedFilters);
  }

  onReset() {
    this.filterDialogRef.close(this.selectedFilters);
  }

  getClientList() {
    this.candidateService.getClientList().subscribe(
      (res) => {
        this.clientList = res.clients;
      }
    )
  }

  onClientSelection(e) {
    this.selectedFilters['clientPartyIds'] = this.clients.value;
  }

  selectedDataSetChange(source: string, newValue: any) {
    if (source === 'startDate' || source === 'endDate') {
      const date = this.dateConversionService.convertToYyyyMmDd(newValue);
      newValue = date;
    }
    this.authorizationDate[source] = newValue;
    this.selectedFilters['authorizationDateRange'] = this.authorizationDate;
  }

  getErrorMessage(){
    if ( this.statusStartDate > this.statusEndDate && this.statusEndDate) {
      this.disableFilter = true;
      return "Status start date shouldn't be greater than Status end date.";
    } else {
      this.disableFilter = false;
    }
  }

  clearAllSelectedStatus() {
    this.selectedStatus = [];
    this.statusCheckBoxList.map(item => item.checked = false);
    delete this.selectedFilters.status;
  }
  
  onStatusChange() {
    this.selectedStatus = this.statusCheckBoxList.filter(item => item.checked).map(item => item.name);
    this.selectedFilters['status'] = this.selectedStatus;
  }

  clearAllSelectedClients() {
    this.clients.setValue([]);
    delete this.selectedFilters.clientPartyIds;
  }

  
  /**
   * Checks for Value or will return 'empty'
   * @param value any
   */
  convertDateToUTC(dateToConvert) {
    try {
      if(dateToConvert){
        const localTime = new Date();
        let diff = localTime.getTimezoneOffset();
        if(diff > 0){
          return new Date(dateToConvert.split('T')[0].concat('T23:00:00.000Z'));
        }else{
          return new Date(dateToConvert.split('T')[0].concat('T00:00:00.000Z'));
        }
      }else{
        return '';
      }
    } catch (error) {
      return '';
    }
  }
}
