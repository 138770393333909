import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[multi-type-char]'
})
export class MultiTypeDirective {
  private readonly _el: NgControl;
  constructor(ngControl: NgControl) {
    this._el = ngControl;
  }

  /** allowed chars are
   *  hypen and en dash em dash converted to hypen(-)
      Apostrophes ''
      Numbers
      Spaces
      Commas ,
      Periods  .
   */

  @HostListener('input', ['$event.target.value']) onInputChange(value: string) {
    value = value.replace(/[–—]/g, '-');  /**conevertion of em,en dash to hypen */
    this._el.control.patchValue(value.replace(/[^-,A-Za-z0-9À-úა-ჰ一-蠼赋'. ]*/g, ''));
  }

}
