
import { Component, OnInit, Output } from '@angular/core';
import { Observable, Subscription, from } from 'rxjs';
import { UserContextService } from '../../../../core/services/user-context.service';
import { Contexts } from '../../../../core/models/contexts.model';
import { trigger, state, style, transition, animate, group } from '@angular/animations';
import { AppConfigService } from '../../../../core/services/app-config.service';
import { UserPreferencesService } from '../../../../core/services/user-preferences.service';
import { Router } from '@angular/router';
import { ToastrComponentlessModule } from 'ngx-toastr';
import { FormControl } from '@angular/forms';
import { UserPreference } from '../../../../core/models/user-preference.model';
import { UserConfigService } from '../../../../core/services/user-config.service';
import { ToastrService } from 'ngx-toastr';


const SlideInOutAnimation = [
  trigger('slideInOut', [
    state('in', style({
      'max-height': '500px', 'height': 'auto', 'opacity': '1', 'visibility': 'visible'
    })),
    state('out', style({
      'max-height': '0', 'height': '0', 'opacity': '0', 'visibility': 'hidden'
    })),
    transition('in => out', [group([
      animate('400ms ease-in-out', style({
        'opacity': '0'
      })),
      animate('600ms ease-in-out', style({
        'max-height': '0px',
        'height': '0px'
      })),
      animate('700ms ease-in-out', style({
        'visibility': 'hidden'
      }))
    ]
    )]),
    transition('out => in', [group([
      animate('1ms ease-in-out', style({
        'visibility': 'visible'
      })),
      animate('300ms ease-in-out', style({
        'max-height': '500px',
        'height': 'auto'
      })),
      animate('800ms ease-in-out', style({
        'opacity': '1'
      }))
    ]
    )])
  ]),
];

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss'],
  animations: [SlideInOutAnimation],
  providers: [AppConfigService]

})

export class AccountSettingsComponent implements OnInit {

  /** holds subscription to the data */
  sub: Subscription;

  userContext: Contexts;
  userContacts: { 'phone': any, 'email': any };
  /** holda the state of the animation for the phone section, in or out, out by default (no display) */
  phoneAnimationState = 'out';

  /** holda the state of the animation for the email section, in or out, out by default (no display) */
  emailAnimationState = 'out';

  /**isViewer variable to hold the isViewer flag value */
  isViewer: Boolean;

  currencyList: any[];

  edit: Boolean = false;
  edits: Boolean = false;
  @Output() tokens;
  currency: FormControl;
  cronofyId: boolean = false;
  changePasswordUrl = '/#/changepassword?appname=MovePro';
  constructor(
    private readonly userContextService: UserContextService,
    private readonly appConfigService: AppConfigService,
    private readonly userPreferenceService: UserPreferencesService,
    private readonly userConfigService: UserConfigService,
    private readonly toastrServiice: ToastrService,
    private router: Router
  ) {
    const cronoAvailable = sessionStorage.getItem('cronofyId');
    (cronoAvailable && cronoAvailable != 'undefined') ? this.cronofyId = true : this.cronofyId = false;
   }

  linkToChangePassword(): void {

    const link = this.appConfigService.getConfig('changePasswordUrl');
    if (link) {

      this.router.navigate(['/externalRedirect',
        { externalUrl: `${link}&urlPath=/settings/${this.userContext.partyId}` }], { skipLocationChange: true, }
      );
    } else {
      console.log('unable to find URL');
    }
  }

  ngOnInit() {
    this.userPreferenceService
      .getPreference('user_config', false)
      .subscribe((response) => {
        if (response) {
          response.forEach((item) => {
            if (item.preferenceconfig.name == 'preferredCurrency') {
              this.currencyList = item.preferenceconfig.options;
              if (
                'preferencesetting' in item && typeof item.preferencesetting.value === "string"       ) {
                this.currency = new FormControl({ currencyAbbr : item.preferencesetting.value,
                                  currencyName : item.preferencesetting.value && item.preferencesetting.value.length !== 0 ?this.currencyList.find(curr => curr.currencyAbbr === item.preferencesetting.value).currencyName : ""
                                });
              } else {
                this.currency = new FormControl("");
              }
            }
          });
        }
      });
    this.userContextService.getUserContext().toPromise().then((context) => {
      this.userContext = context;
    });

    this.userContextService.getUserContacts().subscribe(data => {
      this.userContacts = data;


      if (this.userContacts.phone.length == 0 || this.userContacts.phone == undefined) {
        this.userContacts.phone.phoneNumberDesc = 'N/A';
      }

      if (this.userContacts.email.length == 0 || this.userContacts.email == undefined) {
        this.userContacts.email.emailAddressDesc = 'N/A';
      }

    });

    if (sessionStorage.getItem('car-ses-con')) {
      sessionStorage.getItem('car-ses-vie') === 'true' ? this.isViewer = true : this.isViewer = false;
    }
  }

  /** toggles the phone display between out (no display) and in (display).  The animation handles the transistion between these states */
  togglePhoneDisplay() {
    this.phoneAnimationState = this.phoneAnimationState === 'out' ? 'in' : 'out';
  }

  /** toggles the email display between out (no display) and in (display).  The animation handles the transistion between these states */
  toggleEmailDisplay() {
    this.emailAnimationState = this.emailAnimationState === 'out' ? 'in' : 'out';
  }

  compareCurrency(curr1: any, curr2: any) {
    return (curr1.currencyAbbr === curr2.currencyAbbr);
  }

  save() {
    const reqObj = {
      name: 'preferredCurrency',
      value: this.currency.value.currencyAbbr,
    };
    this.userConfigService.updatePreference(reqObj).subscribe((userConfig) => {
      if (userConfig) {
        const preference: UserPreference = {
          Key: 'user_config',
          Value: userConfig,
          Persist: false,
        };
        this.userPreferenceService.setPreference(preference);
        this.edit = !this.edit;
        this.toastrServiice.info('Changes Saved', null,
          {
            closeButton: true,
            timeOut: 5000,
            enableHtml: true,
            progressBar: true,
            disableTimeOut: false // User must explicitly dismiss error messages
          }
        );
      }
    });
  }
  cancelWorkHours() {
    this.edits = !this.edits;
    
  }
  
  cancel() {
    this.edit = !this.edit;
    this.userPreferenceService
      .getPreference('user_config', false)
      .subscribe((response) => {
        if (response) {
          response.forEach((item) => {
            if (item.preferenceconfig.name == 'preferredCurrency') {
              this.currencyList = item.preferenceconfig.options;
              if (
                'preferencesetting' in item &&
                item.preferencesetting.value
              ) {
                this.currency = new FormControl({ currencyAbbr : item.preferencesetting.value,
                                  currencyName : this.currencyList.find(curr => curr.currencyAbbr === item.preferencesetting.value).currencyName
                                });
              } else {
                this.currency = new FormControl("");
              }
            }
          });
        }
      });
  }
}
