import { Component, OnInit, Inject, Injector, ViewChild, Output, NgZone, ElementRef, ViewContainerRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl, ControlContainer, FormControl, ValidationErrors, FormArray, FormControlName } from '@angular/forms';
import { LocationsService } from '../../../../../public/administration/services/locations.service';
import { ClientContactsService } from '../../../../../public/administration/services/client-contacts.service';
import { Country } from '../../../../../core/models/country.model';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatAutocompleteTrigger } from '@angular/material';
import { Subscription, Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Locations } from '../../../../../core/models/locations.model';
import { candidateProfile } from '../../../../../core/models/candidate.model';
import { shortAddress } from '../../../../../core/models/address.model';
import { CandidateProfileService } from '../../../../../public/administration/services/candidate-profile.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { map, startWith } from 'rxjs/operators';
import PlaceResult = google.maps.places.PlaceResult;
export const createCandidateResponse = `Added Transferee Successfully`;
export const updateCandidateResponse = `Updated Transferee Successfully`;
type ResetStates = { departureStateList: Array<any>, destinationStateList: Array<any> };
type updatedKeyInterface = { group: 'departureAddr' | 'destinationAddr', type: 'country' | 'city' | 'state' | 'streetLine1' | 'streets' | 'cities' | 'states' };
@Component({
  selector: 'app-update-depature-destination',
  templateUrl: './update-depature-destination.component.html',
  styleUrls: ['./update-depature-destination.component.scss']
})
export class UpdateDepatureDestinationComponent implements OnInit {
  /**Form group name */
  addCandidateForm: FormGroup;
  /**Countries retrieved from service */
  countryList: Array<Country>;
  countryNameList: any[];
  /**Currency retrieved from service */
  // currencyList: Array<Country> = [];
  /**Subscription to observe location form */
  locationFormObserver: Subscription;
  /**to disable close button when snackbar is opened */
  formDisable = false;
  /** Title of form being displayed */
  title: string;
  /** List of cities to be populated in departure city list */
  departureCityList: Array<any> = [];
  /** List of cities to be populated in destination city list */
  destinationCityList: Array<any> = [];
  /** List of cities to be populated in departure state list */
  departureStateList: Array<any> = [];
  /** List of cities to be populated in destination state list */
  destinationStateList: Array<any> = [];
  /** Stores value in address form */
  addressFormValue: any;
  /** Country code of selected departure country */
  departureCountryCode: string;
  /** Country code of selected destination country */
  destinationCountryCode: string;
  /** To set departure country to read only when country is empty */
  hasDepartureCountry: boolean;
  /** To set destination country to read only when country is empty */
  hasDestinationCountry: boolean;
  /** Object to store candidate profile details */
  candidateProfile: candidateProfile = {} as candidateProfile;

  dismissWarningMsg: string = 'Dismissing will remove this transferee record ?';
  /** flag to render edit or add form */
  isEdit: boolean = false;
  editCandidateProfile: candidateProfile = {} as candidateProfile;
  /** to distinguish transferee as traditional or benefit builder */
  isTraditional: boolean = false;
  /** Stored initial form value during load to check for changes */
  initialFormValue: any = {} as any;
  /**edit point flag */
  editPoint: boolean;
  hasDepartureStreet: boolean;
  hasDepartureCity: boolean;
  hasDepartureState: boolean;
  hasDestinationStreet: boolean;
  hasDestinationCity: boolean;
  hasDestinationState: boolean;
  departureCountrycodes = [];
  departCountryCode = '';
  destiCountryCode = '';
  transferee: any;
  filteredOptions: Observable<Country[]>;
  filteredCountryOptions: Observable<Country[]>;
  filteredDestCountryOptions: Observable<Country[]>;
  filteredClientContactDetails: Observable<any[]>;
  filteredDefaultHostCountry: Observable<Country[]>;
  filteredSingleSelect: any[] = [];
  @ViewChild('autoCompleteCountryInput', { read: MatAutocompleteTrigger, static: true }) autoCompleteCountry: MatAutocompleteTrigger;
  @ViewChild('autoCompleteInput', { read: MatAutocompleteTrigger, static: true }) autoComplete: MatAutocompleteTrigger;
  @ViewChild('autoCompleteDestCountryInput', { read: MatAutocompleteTrigger, static: true }) autoCompleteDestCountry: MatAutocompleteTrigger;
  @ViewChild('autoCompleteCci', { read: MatAutocompleteTrigger, static: true }) autoCompleteCci: MatAutocompleteTrigger;
  @ViewChild('departStreet', { static: false })
  public departStreetElementRef: ElementRef;
  cciDetails: any[];
  /**client party id for the corrosponding transferee */
  clientPartyId: string;

  /**
   * Initializes form elements
   * @param formBuilder - property for form elements
   * @param locationService - location service
   * @param dialogRef - instance of dialog
   * @param data - data from list of transferees
   * @param spinner - inject spinner
   * @param injector - inject injector
   * @param candidateProfileService - inject service to add candidate details
   * @param dialog - dialog to be opened on dismiss
   * @param toastrService - inject toastrService
   *
   */
  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly locationService: LocationsService,
    private readonly clientContactsService: ClientContactsService,
    public dialogRef: MatDialogRef<UpdateDepatureDestinationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly spinner: NgxSpinnerService,
    private injector: Injector,
    private candidateProfileService: CandidateProfileService,
    public dialog: MatDialog,
    private readonly toastrService: ToastrService,
    public live: LiveAnnouncer,
  ) {
    dialogRef.disableClose = true;
  }


  ngOnInit() {
    this.addCandidateForm = this.populateForm(this.data);
    this.locationSearchInit();
    this.initialFormValue = this.addCandidateForm.value; // set initial value
    this.locationService.countryList.subscribe(countryList => {
      if (countryList.length > 0) {
        countryList.sort((a, b) => a.countryName.localeCompare(b.countryName));
        this.countryList = countryList;
        const currList = [];
        this.filteredCountryOptions = this.addCandidateForm.get('addressForm.departureAddr.country').valueChanges
          .pipe(
            startWith(''),
            map(value => this._filter(value))
          );
        this.filteredDestCountryOptions = this.addCandidateForm.get('addressForm.destinationAddr.country').valueChanges
          .pipe(
            startWith(''),
            map(value => this._filter(value))
          );
      }
    });

    this.addressFormValue = this.addCandidateForm.value['addressForm'];
  }

  private _filter(value): Country[] {
    if (value) {
      const filterValue = typeof value === 'number' ? value : value.toLowerCase();
      const countryList = this.countryList.filter(option => option.countryName.toLowerCase().indexOf(filterValue) === 0);
      return countryList;
    } else {
      return this.countryList;
    }
  }

  pushToDepartureCountryCodes(countryCode, editData = null) {
    this.departCountryCode = countryCode ? countryCode.toLowerCase() : this.departCountryCode;
    if (countryCode) {
      const isRequiredvalidation = ['US', 'CA'].includes(countryCode) ? true : false;
      this.streets.removeAt(0);
      this.AddDepartureStreets(editData);
      this.cities.removeAt(0);
      this.AddDeparatureCities(editData);
      this.states.removeAt(0);
      this.AddDepartureStates(editData, isRequiredvalidation);
      this.postalCodes.removeAt(0);
      this.AddDeparaturePostalCodes(editData, isRequiredvalidation);
    }
  }
  pushToDestinationCountryCodes(countryCode, editData = null) {
    this.destiCountryCode = countryCode ? countryCode.toLowerCase() : this.destiCountryCode;
    if (countryCode) {
      const isRequiredvalidation = ['US', 'CA'].includes(countryCode) ? true : false;
      this.destiStreets.removeAt(0);
      this.AddDestinationStreets(editData);
      this.destiCities.removeAt(0);
      this.AddDestinationCities(editData);
      this.destiStates.removeAt(0);
      this.AddDestinationStates(editData, isRequiredvalidation);
      this.destiPostalCodes.removeAt(0);
      this.AddDestinationPostalCodes(editData, false);
    }
  }
  onAutocompleteSelected(result: PlaceResult) {
    this.updateDepartureAddresValue(result, 'street');
  }
  updateDepartureAddresValue(result: PlaceResult, fieldTriggered: string) {
    let streetLine = '';
    const addressForm: FormGroup = (this.addCandidateForm.controls.addressForm as FormGroup);
    let isCity = false;
    result.address_components.forEach(element => {
      if (element.types[0] === 'postal_code') {
        this.postalCodes.setValue([element.long_name]);
      } else if (element.types[0] === 'administrative_area_level_1' &&
        (fieldTriggered === 'city' || fieldTriggered === 'street' || fieldTriggered === 'state' || fieldTriggered === 'postalcode')) {
        this.states.setValue([element.long_name]);
        this.hasDepartureState = true;
      } else if ((element.types[0] === 'locality' || element.types[0] === 'sublocality_level_1' || element.types[0] === 'postal_town') && (fieldTriggered === 'city' || fieldTriggered === 'street' || fieldTriggered === 'postalcode')) {
        this.cities.setValue([element.long_name]);
        isCity = true;
        this.hasDepartureCity = true;
      } else {
        if (!isCity && fieldTriggered === 'street') {
          streetLine = streetLine.length > 0 ?
            `${streetLine}, ${element.long_name}` : element.long_name;
          this.hasDepartureStreet = true;
        }
      }
    });
    this.streets.setValue([streetLine]);
  }
  onLocationSelected(location: Location) {
  }

  onDepartureCityAutocompleteSelected(result: PlaceResult) {
    this.updateDepartureAddresValue(result, 'city');
  }
  onDepartureStateAutocompleteSelected(result: PlaceResult) {
    this.updateDepartureAddresValue(result, 'state');
  }

  onDeparturePostalCodeAutocompleteSelected(result: PlaceResult) {
    this.updateDepartureAddresValue(result, 'postalcode');
  }

  resetDestinationAddress() {
    const addressForm: FormGroup = (this.addCandidateForm.controls.addressForm as FormGroup);
    (addressForm.controls.destinationAddr as FormGroup).controls.postalCode.reset();
    (addressForm.controls.destinationAddr as FormGroup).controls.state.reset();
    (addressForm.controls.destinationAddr as FormGroup).controls.city.reset();
    (addressForm.controls.destinationAddr as FormGroup).controls.streetLine1.reset();
  }

  updateDestinationAddresValue(result: PlaceResult, fieldTriggered: string) {
    let streetLine = '';
    const addressForm: FormGroup = (this.addCandidateForm.controls.addressForm as FormGroup);
    let isCity = false;
    result.address_components.forEach(element => {
      if (element.types[0] === 'postal_code') {
        this.destiPostalCodes.setValue([element.long_name]);
      } else if (element.types[0] === 'administrative_area_level_1' &&
        (fieldTriggered === 'city' || fieldTriggered === 'street' || fieldTriggered === 'state' || fieldTriggered === 'postalcode')) {
        this.destiStates.setValue([element.long_name]);
        this.hasDestinationState = true;
      } else if ((element.types[0] === 'locality' || element.types[0] === 'sublocality_level_1' || element.types[0] === 'postal_town') && (fieldTriggered === 'city' || fieldTriggered === 'street' || fieldTriggered === 'postalcode')) {
        this.destiCities.setValue([element.long_name]);
        isCity = true;
        this.hasDestinationCity = true;
      } else {
        if (!isCity && fieldTriggered === 'street') {
          streetLine = streetLine.length > 0 ?
            `${streetLine}, ${element.long_name}` : element.long_name;
          this.hasDestinationStreet = true;
        }
      }
    });
    this.destiStreets.setValue([streetLine]);
  }

  onDestinationStreetAutocompleteSelected(result: PlaceResult) {
    this.updateDestinationAddresValue(result, 'street');
  }

  onDestinationCityAutocompleteSelected(result: PlaceResult) {
    this.updateDestinationAddresValue(result, 'city');
  }

  onDestinationStateAutocompleteSelected(result: PlaceResult) {
    this.updateDestinationAddresValue(result, 'state');
  }

  onDestinationPostalCodeAutocompleteSelected(result: PlaceResult) {
    this.updateDestinationAddresValue(result, 'postalcode');
  }
  /**
   * Will instantiate the form and populate values to form with existing data
   * @param editData contains existing transferee information
   */
  populateForm(editData): FormGroup {
    const check = this.isNullCheck;
    const addForm: FormGroup = this.formBuilder.group({
      addressForm: this.formBuilder.group({
        departureAddr: this.formBuilder.group({
          country: [editData ? check(editData.departureAddr, 'country') : '', [Validators.required]],
          cities: new FormArray([
            new FormControl(editData ? check(editData.departureAddr, 'city') : '', [Validators.required, this.depCityMatch.bind(this)])
          ]),
          streets: new FormArray([
            new FormControl(editData ? check(editData.departureAddr, 'streetLine1') : '')
          ]),
          states: new FormArray([
            new FormControl(editData ? check(editData.departureAddr, 'state') : '', [Validators.minLength(3)])
          ]),
          postalCodes: new FormArray([
            new FormControl(editData ? check(editData.departureAddr, 'postalCode') : '',
              [Validators.minLength(1), Validators.maxLength(15)])
          ]),
        }),
        destinationAddr: this.formBuilder.group({
          country: [editData ? check(editData.destinationAddr, 'country') : '', [Validators.required]],
          destiStreets: new FormArray([
            new FormControl(editData ? check(editData.destinationAddr, 'streetLine1') : '')
          ]),
          destiCities: new FormArray([
            new FormControl(editData ? check(editData.destinationAddr, 'city') : '', [Validators.required, this.destCityMatch.bind(this)])
          ]),
          destiStates: new FormArray([
            new FormControl(editData ? check(editData.destinationAddr, 'state') : '', [Validators.minLength(3)])
          ]),
          destiPostalCodes: new FormArray([
            new FormControl(editData ? check(editData.destinationAddr, 'postalCode') : '',
              [Validators.minLength(1), Validators.maxLength(15)])
          ])
        })
      }),
    });
    if (editData && editData.moveStatus && editData.moveStatus === 'Withdrawn') {
      Object.keys(addForm.controls).forEach(group => addForm.controls[group].disable());
    }
    return addForm;
  }

  get streets(): FormArray {
    const addressForm: FormGroup = (this.addCandidateForm.controls.addressForm as FormGroup);
    return (addressForm.controls.departureAddr as FormGroup).controls.streets as FormArray;
  }
  get cities(): FormArray {
    const addressForm: FormGroup = (this.addCandidateForm.controls.addressForm as FormGroup);
    return (addressForm.controls.departureAddr as FormGroup).controls.cities as FormArray;
  }
  get states(): FormArray {
    const addressForm: FormGroup = (this.addCandidateForm.controls.addressForm as FormGroup);
    return (addressForm.controls.departureAddr as FormGroup).controls.states as FormArray;
  }
  get postalCodes(): FormArray {
    const addressForm: FormGroup = (this.addCandidateForm.controls.addressForm as FormGroup);
    return (addressForm.controls.departureAddr as FormGroup).controls.postalCodes as FormArray;
  }

  get destiStreets(): FormArray {
    const addressForm: FormGroup = (this.addCandidateForm.controls.addressForm as FormGroup);
    return (addressForm.controls.destinationAddr as FormGroup).controls.destiStreets as FormArray;
  }
  get destiCities(): FormArray {
    const addressForm: FormGroup = (this.addCandidateForm.controls.addressForm as FormGroup);
    return (addressForm.controls.destinationAddr as FormGroup).controls.destiCities as FormArray;
  }
  get destiStates(): FormArray {
    const addressForm: FormGroup = (this.addCandidateForm.controls.addressForm as FormGroup);
    return (addressForm.controls.destinationAddr as FormGroup).controls.destiStates as FormArray;
  }
  get destiPostalCodes(): FormArray {
    const addressForm: FormGroup = (this.addCandidateForm.controls.addressForm as FormGroup);
    return (addressForm.controls.destinationAddr as FormGroup).controls.destiPostalCodes as FormArray;
  }

  AddDepartureStreets(editData = null) {
    const check = this.isNullCheck;
    this.streets.push(new FormControl(editData ? check(editData.departureAddr, 'streetLine1') : ''));

    (editData && check(editData.departureAddr, 'streetLine1')) ? this.hasDepartureStreet = true :
      this.hasDepartureStreet = false;
  }

  AddDeparatureCities(editData = null) {
    const check = this.isNullCheck;
    this.cities.push(new FormControl(editData ? check(editData.departureAddr, 'city') : '',
      [Validators.required, this.depCityMatch.bind(this)]));
    (editData && check(editData.departureAddr, 'city')) ? this.hasDepartureCity = true :
      this.hasDepartureCity = false;
  }

  AddDepartureStates(editData = null, isRequiredValidation = false) {
    const check = this.isNullCheck;
    if (!isRequiredValidation) {
      this.states.push(new FormControl(editData ? check(editData.departureAddr, 'state') : '', [Validators.minLength(3)]));
    } else {
      this.states.push(new FormControl(editData ? check(editData.departureAddr, 'state') : '',
        [Validators.minLength(3), Validators.required]));
    }
  }

  AddDeparaturePostalCodes(editData = null, isRequiredValidation = false) {
    const check = this.isNullCheck;
    if (!isRequiredValidation) {
      this.postalCodes.push(new FormControl(editData ? check(editData.departureAddr, 'postalCode') : '',
        [Validators.minLength(1), Validators.maxLength(15)]));
    } else {
      this.postalCodes.push(new FormControl(editData ? check(editData.departureAddr, 'postalCode') : '',
        [Validators.minLength(1), Validators.maxLength(15), Validators.required]));
    }
  }

  AddDestinationStreets(editData = null) {
    const check = this.isNullCheck;
    this.destiStreets.push(new FormControl(editData ? check(editData.destinationAddr, 'streetLine1') : ''));
    (editData && check(editData.destinationAddr, 'streetLine1')) ? this.hasDestinationStreet = true :
      this.hasDestinationStreet = false;
  }

  AddDestinationCities(editData = null) {
    const check = this.isNullCheck;
    this.destiCities.push(new FormControl(editData ? check(editData.destinationAddr, 'city') : '',
      [Validators.required, this.destCityMatch.bind(this)]));
    (editData && check(editData.destinationAddr, 'city')) ? this.hasDestinationCity = true :
      this.hasDestinationCity = false;
  }

  AddDestinationStates(editData = null, isRequiredvalidation = false) {
    const check = this.isNullCheck;
    if (!isRequiredvalidation) {
      this.destiStates.push(new FormControl(editData ? check(editData.destinationAddr, 'state') : '', [Validators.minLength(3)]));
    } else {
      this.destiStates.push(new FormControl(editData ? check(editData.destinationAddr, 'state') : '',
        [Validators.minLength(3), Validators.required]));
    }
  }

  AddDestinationPostalCodes(editData = null, isRequiredvalidation = false) {
    const check = this.isNullCheck;
    if (!isRequiredvalidation) {
      this.destiPostalCodes.push(new FormControl(editData ? check(editData.destinationAddr, 'postalCode') : '',
        [Validators.minLength(1), Validators.maxLength(15)]));
    } else {
      this.destiPostalCodes.push(new FormControl(editData ? check(editData.destinationAddr, 'postalCode') : '',
        [Validators.minLength(1), Validators.maxLength(15), Validators.required]));
    }
  }
  /**
   * To validate if the values provided in departure city auto complete fields are from the options provided
   * @param formControl - field parameter to validate against respective list
   */
  private depCityMatch(control: FormControl): ValidationErrors | null {
    const selection: any = control.value;
    if (this.departureCityList && this.departureCityList.length > 0 && selection) {
      if ((this.departureCityList.filter(val => val.cityLongName.toLowerCase() == selection.toLowerCase())).length < 1) {
        return {
          requireMatch: true
        };
      }
    }
    return null;
  }

  /**
   * To validate if the values provided in destination city auto complete fields are from the options provided
   * @param formControl - field parameter to validate against respective list
   */
  private destCityMatch(control: FormControl): ValidationErrors | null {
    const selection: any = control.value;
    if (this.destinationCityList && this.destinationCityList.length > 0 && selection) {
      if ((this.destinationCityList.filter(val => val.cityLongName.toLowerCase() == selection.toLowerCase())).length < 1) {
        return {
          requireMatch: true
        };
      }
    }
    return null;
  }

  /**
   * To validate if the values provided in departure state auto complete fields are from the options provided
   * @param formControl - field parameter to validate against respective list
   */
  private depStateMatch(control: FormControl): ValidationErrors | null {
    const selection: any = control.value;
    if (this.departureStateList && this.departureStateList.length > 0 && selection) {
      if ((this.departureStateList.filter(val => val.stateLongName.toLowerCase() == selection.toLowerCase())).length < 1) {
        return {
          requireMatch: true
        };
      }
    }
    return null;
  }

  /**
   * To validate if the values provided in destination state auto complete fields are from the options provided
   * @param formControl - field parameter to validate against respective list
   */
  private destStateMatch(control: FormControl): ValidationErrors | null {
    const selection: any = control.value;
    if (this.destinationStateList && this.destinationStateList.length > 0 && selection) {
      if ((this.destinationStateList.filter(val => val.stateLongName.toLowerCase() == selection.toLowerCase())).length < 1) {
        return {
          requireMatch: true
        };
      }
    }
    return null;
  }

  /**
   * Custom error messages for Firstname, lastname and Email to verify special character or empty errors
   * @param fieldName - field parameter to check for errors
   */
  getErrorMessage(formControl, fieldName, index = -1) {
    if (index === -1) {
      if (this.addCandidateForm.get(formControl).hasError('required')) {
        return ('You must enter ' + fieldName);
      } else if (this.addCandidateForm.get(formControl).hasError('pattern') || this.addCandidateForm.get(formControl).hasError('minlength') ||
        this.addCandidateForm.get(formControl).hasError('maxlength') || (this.addCandidateForm.get(formControl).hasError('requireMatch')
          && this.addCandidateForm.get(formControl).value.length < 3) || this.addCandidateForm.get(formControl).hasError('min')) {
        return ('You must enter a valid ' + fieldName);
      } else if (this.addCandidateForm.get(formControl).hasError('requireMatch') &&
        this.addCandidateForm.get(formControl).value.length >= 3) {
        return ('Your ' + fieldName + ' entry must match valid options');
      }
    } else {
      const ctrl = (this.addCandidateForm.get(formControl) as FormArray).controls[0] as FormControl;
      if (ctrl.hasError('required')) {
        return ('You must enter ' + fieldName);
      } else if ((ctrl.hasError('pattern') || ctrl.hasError('minlength') ||
        ctrl.hasError('maxlength') || ctrl.hasError('requireMatch')
        && ctrl.value.length < 3) || ctrl.hasError('min')) {
        return ('You must enter a valid ' + fieldName);
      } else if (ctrl.hasError('requireMatch') &&
        ctrl.value.length >= 3) {
        return ('Your ' + fieldName + ' entry must match valid options');
      }
    }
    return '';
  }

  /**
   * Checks for Value or will return 'empty'
   * @param value any
   */
  isNullCheck(obj: Object, key: string) {
    try {
      return ((obj[key] || obj[key] == false) && obj[key] !== null) ? obj[key] : '';
    } catch (error) {
      return '';
    }
  }

  onFocusOutDepartureCountryCode() {
    const countryObj = this.countryList.find(country => (this.addCandidateForm.value.addressForm.departureAddr.country).toLowerCase().
      includes(country.countryName.toLowerCase()));
    const countryCode = countryObj ? countryObj.countryiso2CharCode : null;
    console.log("country code =",countryCode);
    
    const index = this.countryList.findIndex(
      (country) => (this.addCandidateForm.value.addressForm.departureAddr.country).toLowerCase() ===
        country.countryName.toLowerCase());
    if (index === -1) {
      ((this.addCandidateForm.controls.addressForm as FormGroup).controls.departureAddr as FormGroup).controls.country.setErrors({ 'incorrect': true });
    } else if (countryCode === this.departureCountryCode) {
      return;
    }
    this.hasDepartureCountry = true;
    this.departureCountryCode = countryCode;
    this.pushToDepartureCountryCodes(this.departureCountryCode);
    this.modifyLocationFormValidations(this.addCandidateForm.controls.addressForm as FormGroup,
      this.departureCountryCode, this.destinationCountryCode);
  }

  onFocusOutDestinationCountryCode() {
    const countryObj = this.countryList.find(country => (this.addCandidateForm.value.addressForm.destinationAddr.country).toLowerCase().
      includes(country.countryName.toLowerCase()));
    const countryCode = countryObj ? countryObj.countryiso2CharCode : null;
    const index = this.countryList.findIndex(
      (country) => (this.addCandidateForm.value.addressForm.destinationAddr.country).toLowerCase() ===
        country.countryName.toLowerCase());
    if (index === -1) {
      ((this.addCandidateForm.controls.addressForm as FormGroup).controls.destinationAddr as FormGroup).controls.country.setErrors({ 'incorrect': true });
    } else if (countryCode === this.destinationCountryCode) {
      return;
    }
    this.hasDestinationCountry = true;
    this.destinationCountryCode = countryCode;
    this.pushToDestinationCountryCodes(this.destinationCountryCode);
    this.modifyLocationFormValidations(this.addCandidateForm.controls.addressForm as FormGroup,
      this.departureCountryCode, this.destinationCountryCode);
  }
  /**
   * Initialize location search observer..
   */
  locationSearchInit() {
    this.hasDepartureCountry = (this.addCandidateForm.value.addressForm.departureAddr.country != '') ? true : false;
    this.hasDestinationCountry = (this.addCandidateForm.value.addressForm.destinationAddr.country != '') ? true : false;
    this.locationFormObserver = (this.addCandidateForm.controls.addressForm as FormGroup).valueChanges.pipe(
      debounceTime(300)
    ).subscribe(value => {
      const newFormValue = { ...value };
      const updatedKey: updatedKeyInterface = this.findUpdatedKey(newFormValue, this.addressFormValue);
      if (updatedKey && updatedKey.type == 'country') {
        if (this.addCandidateForm.value.addressForm.departureAddr.country != '') {
          const countryObj = this.countryList.find(country => (this.addCandidateForm.value.addressForm.departureAddr.country).
            includes(country.countryName));
          this.departureCountryCode = countryObj ? countryObj.countryiso2CharCode : null;
          this.hasDepartureCountry = true;
          if (newFormValue.departureAddr.country !== this.addressFormValue.departureAddr.country) {
            this.pushToDepartureCountryCodes(this.departureCountryCode);
          }
        }
        if (this.addCandidateForm.value.addressForm.destinationAddr.country != '') {
          const country = this.countryList.find(country => (this.addCandidateForm.value.addressForm.destinationAddr.country).
            includes(country.countryName));
          this.destinationCountryCode = country ? country.countryiso2CharCode : null;
          this.hasDestinationCountry = true;
          if (newFormValue.destinationAddr.country !== this.addressFormValue.destinationAddr.country) {
            this.pushToDestinationCountryCodes(this.destinationCountryCode);
          }
        }
        this.modifyLocationFormValidations(this.addCandidateForm.controls.addressForm as FormGroup,
          this.departureCountryCode, this.destinationCountryCode);
        this.resetStates(updatedKey);
      }
      if (updatedKey && updatedKey.group === 'departureAddr') {
        if (!newFormValue.departureAddr.streets || (newFormValue.departureAddr.streets && newFormValue.departureAddr.streets.length > 0
          && newFormValue.departureAddr.streets[0] === '' && newFormValue.departureAddr.streets[0].length === 0)) {
          this.hasDepartureStreet = false;
        }

        if (!newFormValue.departureAddr.cities || (newFormValue.departureAddr.cities && newFormValue.departureAddr.cities.length > 0
          && newFormValue.departureAddr.cities[0] === '' && newFormValue.departureAddr.cities[0].length === 0)) {
          this.hasDepartureCity = false;
        }
        if (!newFormValue.departureAddr.states || (newFormValue.departureAddr.states && newFormValue.departureAddr.states.length > 0
          && newFormValue.departureAddr.states[0] === '' && newFormValue.departureAddr.states[0].length === 0)) {
          this.hasDepartureState = false;
        }
      }

      if (updatedKey && updatedKey.group === 'destinationAddr') {
        if (!newFormValue.destinationAddr.destiStreets || (newFormValue.destinationAddr.destiStreets &&
          newFormValue.destinationAddr.destiStreets.length > 0 && newFormValue.destinationAddr.destiStreets[0] === ''
          && newFormValue.destinationAddr.destiStreets[0].length === 0)) {
          this.hasDestinationStreet = false;
        }
        if (!newFormValue.destinationAddr.destiCities ||
          (newFormValue.destinationAddr.destiCities && newFormValue.destinationAddr.destiCities.length > 0
            && newFormValue.destinationAddr.destiCities[0] === '' && newFormValue.destinationAddr.destiCities[0].length === 0)) {
          this.hasDestinationCity = false;
        }
        if (!newFormValue.destinationAddr.destiStates ||
          (newFormValue.destinationAddr.destiStates && newFormValue.destinationAddr.destiStates.length > 0
            && newFormValue.destinationAddr.destiStates[0] === '' && newFormValue.destinationAddr.destiStates[0].length === 0)) {
          this.hasDestinationState = false;
        }
      }
      this.addressFormValue = value;
    });
  }

  /**
   * Will automatically reset the state if there are any changes to city
   * @param updatedKey contains the updated {key}
   */
  resetStates(updatedKey: updatedKeyInterface) {
    setTimeout(() => {
      const addressForm: FormGroup = (this.addCandidateForm.controls.addressForm as FormGroup);
      if (updatedKey.group == 'departureAddr' && (updatedKey.type == 'city' || updatedKey.type == 'country')) {
        this.states.setValue(['']);
        this.streets.setValue(['']);
        if (updatedKey.type == 'country') {
          this.cities.setValue(['']);
          this.postalCodes.setValue(['']);
          this.streets.setValue(['']);
          this.hasDepartureCity = false;
          this.hasDepartureState = false;
        }
        this.hasDepartureStreet = false;
      }

      if (updatedKey.group == 'destinationAddr' && (updatedKey.type == 'city' || updatedKey.type == 'country')) {
        this.destiStates.setValue(['']);
        if (updatedKey.type == 'country') {
          this.destiCities.setValue(['']);
          this.destiPostalCodes.setValue(['']);
          this.hasDestinationCity = false;
        }
        this.hasDestinationState = false;
      }
      if (updatedKey.group == 'departureAddr' && (updatedKey.type == 'city' || updatedKey.type == 'country')) {
        this.departureStateList = [];
      }
      if (updatedKey.group == 'departureAddr' && updatedKey.type == 'country') {
        this.departureCityList = [];
      }
      if (updatedKey.group == 'destinationAddr' && (updatedKey.type == 'city' || updatedKey.type == 'country')) {
        this.destinationStateList = [];
      }
      if (updatedKey.group == 'destinationAddr' && updatedKey.type == 'country') {
        this.destinationCityList = [];
      }

    }, 500);
  }
  /**
     * Will determine which field has been updated in Address Form (for Address and Review Component).
     * @param newFormValue AddressForm new values
     * @param oldFormValue AddressForm old values
     */
  findUpdatedKey(newFormValue, oldFormValue): updatedKeyInterface {
    let updatedKey = null;

    Object.keys(newFormValue.departureAddr).map(key => {
      if (newFormValue.departureAddr[key] !== oldFormValue.departureAddr[key]) {
        updatedKey = key;
      }
    });

    if (updatedKey) { return { group: 'departureAddr', type: updatedKey }; }

    Object.keys(newFormValue.destinationAddr).map(key => {
      if (newFormValue.destinationAddr[key] !== oldFormValue.destinationAddr[key]) {
        updatedKey = key;
      }
    });

    if (updatedKey) { return { group: 'destinationAddr', type: updatedKey }; }

    return null;
  }

  locationSearch(addressForm: FormGroup, updatedKey: updatedKeyInterface, updatedLocationFormValue: any,
    departureCountryCode: string, destinationCountryCode: string): Observable<Locations> {

    return Observable.create(_observer => {

      const spinner = this.injector.get(NgxSpinnerService);
      const locationService = this.injector.get(LocationsService);

      if (!updatedLocationFormValue[updatedKey.group][updatedKey.type]) { return; }

      const searchType = updatedKey.type === 'city' ? 'cities' : 'states';
      const searchText: string = updatedLocationFormValue[updatedKey.group as any][updatedKey.type];

      // Search only if search text length is greater than 3;
      if (searchText.length < 3) { return; }

      spinner.show();
      locationService.locationSearch(searchType, updatedLocationFormValue[updatedKey.group as any][updatedKey.type], {
        country: updatedKey.group === 'departureAddr' ? departureCountryCode : destinationCountryCode,
        cityLongName: updatedKey.group == 'departureAddr' ? updatedLocationFormValue.departureAddr.city :
          updatedLocationFormValue.destinationAddr.city,
        cityShortName: updatedKey.group == 'departureAddr' ? updatedLocationFormValue.departureAddr.city :
          updatedLocationFormValue.destinationAddr.city
      }).subscribe(data => {
        if (!data) {
          if (updatedKey.group == 'departureAddr') {
            (updatedKey.type == 'city') ?
              (addressForm.controls.departureAddr as FormGroup).controls.city.setValue('') :
              (addressForm.controls.departureAddr as FormGroup).controls.state.setValue('');
          } else {
            (updatedKey.type == 'city') ?
              (addressForm.controls.destinationAddr as FormGroup).controls.city.setValue('') :
              (addressForm.controls.destinationAddr as FormGroup).controls.state.setValue('');
          }
          _observer.next(null);
          _observer.complete();
        }
        _observer.next(data);
        _observer.complete();

      }, () => {
        _observer.next(null);
        _observer.complete();

      }, () => console.log('hide spinner')
      );
    });
  }


  /**
   * State/Province & postal code required for locations in the United States of America and Canada only
   */
  modifyLocationFormValidations(addressForm: FormGroup, departureCountryCode: string, destinationCountryCode: string) {
    if (!['US', 'CA'].includes(departureCountryCode)) {
      //  (addressForm.controls.departureAddr as FormGroup).controls.state.clearValidators();
      this.states.controls[0].setValidators([Validators.minLength(3),
      this.depStateMatch.bind(this)]);
      // (addressForm.controls.departureAddr as FormGroup).controls.postalCode.clearValidators();
      this.postalCodes.controls[0].setValidators([Validators.minLength(2),
      Validators.maxLength(50)]);

    } else {
      this.states.controls[0].setValidators([Validators.required,
      this.depStateMatch.bind(this), Validators.minLength(3)]);
      this.postalCodes.controls[0].setValidators([Validators.required,
      Validators.minLength(2), Validators.maxLength(50)]);

    }
    if (!['US', 'CA'].includes(destinationCountryCode)) {
      this.destiStates.controls[0].setValidators([this.destStateMatch.bind(this),
      Validators.minLength(3)]);
      this.destiPostalCodes.controls[0].setValidators([Validators.minLength(2),
      Validators.maxLength(50)]);
    } else {
      this.destiStates.controls[0].setValidators([Validators.required,
      this.destStateMatch.bind(this), Validators.minLength(3)]);
      this.destiPostalCodes.controls[0].setValidators([Validators.minLength(2),
      Validators.maxLength(50)]);
    }
    this.states.controls[0].updateValueAndValidity();
    this.postalCodes.controls[0].updateValueAndValidity();
    this.destiStates.controls[0].updateValueAndValidity();
    this.destiPostalCodes.controls[0].updateValueAndValidity();
  }

  /**
   * On click of send invite button inside the addCandidateForm dialog window
   */
  sendInvite() {

  }
  /**
   *On click of Save Draft button inside the addCandidateForm dialog window
   */
  saveData(type: string) {
    this.spinner.show();
    this.saveCandidate(this.populateValues(this.data.location), type);

  }

  /**
   * Click on Save Draft button, check if the values of the fields provided are valid
   */
  findInvalidControls(formGroup: FormGroup) {
    let isValid = true;

    const controls = formGroup.controls;

    if (isValid) {
      return true;
    } else {
      return false;
    }
  }
  /**
   * Populate form values to object for submission
   */

  populateValues(editData: candidateProfile): candidateProfile {
    this.candidateProfile = {} as candidateProfile;
    /**Departure  Address Details */
    const departAddress: shortAddress = {} as shortAddress;
    (this.addCandidateForm.value.addressForm.departureAddr.cities &&
      this.addCandidateForm.value.addressForm.departureAddr.cities.length > 0 &&
      this.addCandidateForm.value.addressForm.departureAddr.cities[0]) ?
      departAddress.city = this.addCandidateForm.value.addressForm.departureAddr.cities[0] : null;
    (this.addCandidateForm.value.addressForm.departureAddr.country) ? departAddress.country =
      this.addCandidateForm.value.addressForm.departureAddr.country : null;
    (this.addCandidateForm.value.addressForm.departureAddr.postalCodes
      && this.addCandidateForm.value.addressForm.departureAddr.postalCodes.length > 0 &&
      this.addCandidateForm.value.addressForm.departureAddr.postalCodes[0]) ? departAddress.postalCode =
    this.addCandidateForm.value.addressForm.departureAddr.postalCodes[0] : null;
    (this.addCandidateForm.value.addressForm.departureAddr.states
      && this.addCandidateForm.value.addressForm.departureAddr.states.length > 0 &&
      this.addCandidateForm.value.addressForm.departureAddr.states[0]) ? departAddress.state =
    this.addCandidateForm.value.addressForm.departureAddr.states[0] : null;
    (this.addCandidateForm.value.addressForm.departureAddr.streets &&
      this.addCandidateForm.value.addressForm.departureAddr.streets.length > 0 &&
      this.addCandidateForm.value.addressForm.departureAddr.streets[0]) ? departAddress.streetLine1 =
    this.addCandidateForm.value.addressForm.departureAddr.streets[0] : null;
    Object.keys(departAddress).length ? this.candidateProfile.departureAddr = departAddress : null;

    /**Destination  Address Details */
    const destAddress: shortAddress = {} as shortAddress;
    (this.addCandidateForm.value.addressForm.destinationAddr.destiCities &&
      this.addCandidateForm.value.addressForm.destinationAddr.destiCities.length > 0 &&
      this.addCandidateForm.value.addressForm.destinationAddr.destiCities[0]) ? destAddress.city =
    this.addCandidateForm.value.addressForm.destinationAddr.destiCities[0] : null;
    (this.addCandidateForm.value.addressForm.destinationAddr.country) ? destAddress.country =
      this.addCandidateForm.value.addressForm.destinationAddr.country : null;
    (this.addCandidateForm.value.addressForm.destinationAddr.destiPostalCodes &&
      this.addCandidateForm.value.addressForm.destinationAddr.destiPostalCodes.length > 0 &&
      this.addCandidateForm.value.addressForm.destinationAddr.destiPostalCodes[0]) ? destAddress.postalCode =
    this.addCandidateForm.value.addressForm.destinationAddr.destiPostalCodes[0] : null;
    (this.addCandidateForm.value.addressForm.destinationAddr.destiStates &&
      this.addCandidateForm.value.addressForm.destinationAddr.destiStates.length > 0 &&
      this.addCandidateForm.value.addressForm.destinationAddr.destiStates[0]) ? destAddress.state =
    this.addCandidateForm.value.addressForm.destinationAddr.destiStates[0] : null;
    (this.addCandidateForm.value.addressForm.destinationAddr.destiStreets &&
      this.addCandidateForm.value.addressForm.destinationAddr.destiStreets.length > 0 &&
      this.addCandidateForm.value.addressForm.destinationAddr.destiStreets[0]) ? destAddress.streetLine1 =
    this.addCandidateForm.value.addressForm.destinationAddr.destiStreets[0] : null;
    Object.keys(destAddress).length ? this.candidateProfile.destinationAddr = destAddress : null;
    return this.candidateProfile;
  }

  /**
   * Save candidate create/update
   * @param type notifies whether to add/edit
   */
  saveCandidate(data: candidateProfile, type) {
    const candidateServiceSave = this.candidateProfileService.updateCandidateLocation(this.candidateProfile, this.data.currOrderRequestId);
    candidateServiceSave.subscribe(
      (response) => {
        this.spinner.hide();
        if (response) {
          this.flashAndCloseDialog(updateCandidateResponse, true);
        }
      },
      err => {
        this.spinner.hide();
      }
    );
  }

  /**
   * Invoked on click of dismiss/close
   */
  onDismiss(evt) {
    evt.preventDefault();
    this.dialogRef.close(null)
  }

  /**
   * function to flash toastr with status message and close dialog on success
   *@param response string
   */
  flashAndCloseDialog(message: string, success) {
    if (success) {
      this.toastrService.info(message, null, {
        closeButton: true,
        enableHtml: true,
        disableTimeOut: false // User must explicitly dismiss error messages
      });
      this.live.announce(message);
      this.dialogRef.close(this.candidateProfile);
    } else {
      this.toastrService.error(message, null, {
        closeButton: true,
        enableHtml: true,
        disableTimeOut: false // User must explicitly dismiss error messages
      });
    }
  }
}


/**
 * Component to display warning dialog box on dismiss
 */
@Component({
  // tslint:disable-next-line: component-selector
  selector: 'warning-dialog',
  styleUrls: ['./update-depature-destination.component.scss'],
  template: `
  <style>button:focus  {    text-decoration: underline;} 
        div:focus { outline: none !important}  
  </style>
  <div mat-dialog-content tabindex = "0"><span aria-hidden = 'true'>{{ data.message }}</span><div>
  <div>
   <button class="edit-ok" mat-button tabindex = 0 (click)='onNoClick()'>OK</button>
   <button class="edit-cancel" mat-button tabindex = 0 [mat-dialog-close]='true'>Cancel</button>
 </div>`
})

export class WarningDialogComponent {

  constructor(
    public dialog: MatDialogRef<WarningDialogComponent>,
    public live: LiveAnnouncer,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.live.announce(this.data.candidate.message);
  }
  onNoClick(): void {
    this.dialog.close({
      action: 'dismiss'
    });
  }

}
