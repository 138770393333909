import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatButton } from '@angular/material/button';
import { Observable, Subscription } from 'rxjs';
import { AggregationFetchparameter } from '../../core/models/aggregation-fetch-parameter.model';
import { AggregationFilter } from '../../core/models/aggregation-filter.model';
import { PowerBiReportConfig } from '../../core/models/powerbi-report-config.model';
import { PowerBiVisualConfig } from '../../core/models/powerbi-visual-config.model';
import { UserConfig } from '../../core/models/user-config.model';
import { UserPreference } from '../../core/models/user-preference.model';
import { DateConversionService } from '../../core/services/date-conversion.service';
import { PowerBiReportService } from '../../core/services/powerbi-report.service';
import { UserConfigService } from '../../core/services/user-config.service';
import { UserContextService } from '../../core/services/user-context.service';
import { UserPreferencesService } from '../../core/services/user-preferences.service';
import { AggregationViewComponent } from './components/aggregation-view/aggregation-view.component';
import { DashboardViewModalComponent } from './components/dashboard-view-modal/dashboard-view-modal.component';
import { EmployeeDetailsComponent } from './components/employee-details/employee-details.component';
import { FilterModalComponent } from './components/filter-modal/filter-modal.component';
import { DataviewSelectionModalComponent } from './components/dataview-selection-modal/dataview-selection-modal.component';
import { CLIENT_LIST } from '../../core/models/powerbi-disabled-clients.model';
import { FeatureFlagService } from '../../core/services/feature-flag.service';

/** DashboardComponent to view the dashboard */
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit, OnDestroy {
  /** @viewChild to get the child component */
  @ViewChild(EmployeeDetailsComponent, { static: false }) childObj: EmployeeDetailsComponent;

  /** @viewChild to get the child aggregation view component */
  @ViewChild(AggregationViewComponent, { static: false }) aggreChildObj: AggregationViewComponent;

  /** @viewChild to get the Dashboard configuration button  */
  @ViewChild('btnDashboardConfig', { static: false }) btnDashboardConfig: MatButton;

  /** aggregationDashboardBy variable to store the selected aggregation view value */
  aggregateDashboardBy = 'employeeList';

  /** aggregationDashboardBy filter */
  filters: AggregationFilter;

  /** userPreferences variable to store the UserConfig object. */
  userPreferences: UserConfig;

  /** dialogRef to access the Dialog */
  dialogRef: MatDialogRef<DashboardViewModalComponent>;

  /** filterDialogRef to access the Dialog */
  filterDialogRef: MatDialogRef<FilterModalComponent>;

  //  dataviewSelection to access the dialog
  dataviewSelection: MatDialogRef<DataviewSelectionModalComponent>;
  /** UserConfig api subscription */
  private userConfigSub: Subscription;

  /** Report config */
  public configs$: Observable<PowerBiVisualConfig[] | PowerBiReportConfig[]>;

  /** result of Save method */
  private saveResult: boolean;

  /** display column for filter popup */
  displayedColumns: string[] = [];

  /** show/hide powerbi */
  showPowerBI: boolean = false;

  /** list of clients to be hidden */
  powerBIHiddenClientList = CLIENT_LIST;

  /** filterDetailsList variable to store the FilterDetails object. */
  filterDetailsListItems: any = [];

  /** filterOptions variable to store the updated filter list */
  filterOptions: any;

  /** searchValue to hold the search text enter in table search input */
  searchValue: string;

  isSearchCleared = true;

  /** resultsExists to hold the boolean value for results exists or not */
  resultsExists = false;

  /** filterDetailsListCounter will hold the filters to display as chips */
  filterDetailsListCounter = 0;

  /** filterCount will hold the number of times the filters are applied */
  filterCount = 0;

  /** endDateExist will hold the boolean value whether end date exists or not */
  endDateExist = true;

  /** maxCostExist will hold the boolean value whether max cost exists or not */
  maxCostExist = true;

  /** sortDetails to hold the sort column name and sort direction */
  sortDetails: any;

  /** aggregationFetchParameter to output the value and
   * pass that to child component employee details*/
  aggregationFetchParameter: AggregationFetchparameter;
  /** contextFlag to hold the logged in user context multi currency flag value */
  contextFlag: Boolean;

  isMultiCurrencyFlag = false;
  empListDataView: string[] = ['EmployeeListViewColumnDisplay'];
  /** isSearchTriggered to hold the boolean value whether the search box has been triggered for results */
  isSearchTriggered = false;
  /** flag to enable disable filter icon */
  enableFilterIcon;

  /** Base constructor method
   * @param dialog MatDialog injection
   * @param userConfigService UserConfigService injection
   * @param filterDetailsService FilterDetailsService injection
   * @param powerBiService PowerBiReportService injection
   * @param employeeDetailsService EmployeeDetailsService injection
   * @param featureFlagService FeatureFlagService injection
  */
  constructor(
    public dialog: MatDialog,
    private userConfigService: UserConfigService,
    private powerBiService: PowerBiReportService,
    private userPreferenceService: UserPreferencesService,
    private dateConversionService: DateConversionService,
    private userContextService: UserContextService,
    private featureFlagService: FeatureFlagService
  ) { }

  status($event) {
    this.resultsExists = $event;
  }

  /** sortOptions method will get the sort column name and sort direction */
  sortOptions($event) {
    this.sortDetails = $event;
    // const sortPreference = {
    //   sortColumnName: this.sortDetails.sortColumnName,
    //   sortOrder: this.sortDetails.sortDirection
    // };
    const sortPreference = this.childObj.getSortingColumns(this.sortDetails.sortColumnName);
    const sortOptionsPreference: UserPreference = {
      Key: 'sort_options',
      Value: sortPreference,
      Persist: false,
    };
    this.userPreferenceService.setPreference(sortOptionsPreference);
  }

  /** dashboardViewDialog method to open the Dashboard View modal popup */
  dashboardViewDialog(): void {
    // Uncomment this code once we have this API available
    // this.userConfigService.setUserConfigDetails(this.userPreferences)
    //   .pipe(take(1))
    //   .subscribe(value => {
    //     this.saveResult = value;
    //   });
    this.dialogRef = this.dialog.open(DashboardViewModalComponent, {
      data: { aggregateDashboardBy: this.aggregateDashboardBy },
      panelClass: 'dashboardViewDialog'
    });

    /** Dialog afterclose method to subscribe the result */
    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const preference: UserPreference = {
          Key: 'aggregation_dashboard',
          Value: result,
          Persist: true
        };
        this.userPreferenceService.setPreference(preference);
        this.userPreferences.aggregateDashboardBy = result;
        this.aggregateDashboardBy = this.userPreferences.aggregateDashboardBy;
        // Uncomment this code once we have this API available
        // this.userConfigService.setUserConfigDetails(this.userPreferences)
        //   .pipe(take(1))
        //   .subscribe(value => {
        //     this.saveResult = value;
        //   });
      }
      // Set focus back to button after close
      this.btnDashboardConfig.focus();
    });
  }

  filterIcon() {
    // tslint:disable-next-line: prefer-const
    let filterArray = [ 'division', 'departure', 'destination', 'policy', 'totalCost', 'starred', 'fileStatusDesc',
                        'effectiveTransferDate', 'movePhase' ];
    if (this.displayedColumns.length > 0) {
      this.enableFilterIcon = this.displayedColumns.some((val) => filterArray.includes(val));
    }
    return this.enableFilterIcon;
  }

  /** filterModal method to open the Filter modal popup */
  filterModal(): void {
    if (this.filterIcon()) {
        this.filterDialogRef = this.dialog.open(FilterModalComponent, {
        data: {
          dataKey : this.displayedColumns
        },
        panelClass: 'filterModal',
        autoFocus: false,
        restoreFocus: false
        });
        this.filterDialogRef.afterClosed().subscribe(filters => {
          this.displayChips(filters);
        });
      }
  }

  /** dataview selection method to open modal popup */
  dataViewSelectionModal() {
    this.dataviewSelection = this.dialog.open(DataviewSelectionModalComponent, {
      data: {
        dataKey : this.empListDataView
      },
      panelClass: 'DataviewSelectionModal',
      autoFocus: false,
      restoreFocus: false
    });
    this.dataviewSelection.afterClosed().subscribe(displayedColumn => {
      if (displayedColumn && displayedColumn.length > 0) {
        this.displayedColumns = displayedColumn;
        this.filterIcon();
        this.childObj.updateDisplayedColumn(displayedColumn);
        this.showHideFilterOptions(displayedColumn);
      }

    });
  }

  /** show hide display chip and filter options */
  showHideFilterOptions(displayCol) {
    this.userPreferenceService.getPreference('aggregation_filter', false).subscribe((response) => {
      if (response) {
        this.filters = response;
      }
    });
    // this.filterOptions = $event;
    // Move Phase
    if (!displayCol.includes('movePhase')) {
        this.filters.movePhase = [];
        this.sortDetails = [];
    }

    // File Status
    if (!displayCol.includes('fileStatusDesc')) {
      this.filters.fileStatusDesc = [];
      this.sortDetails = [];
    }

    // Departure
    if (!displayCol.includes('departue')) {
      this.filters.departure = [];
      this.sortDetails = [];
    }

    // Destination
    if (!displayCol.includes('destination')) {
      this.filters.destination = [];
      this.sortDetails = [];
    }

    // Policy
    if (!displayCol.includes('policy')) {
      this.filters.policy = [];
      this.sortDetails = [];
    }

      // Division
      if (!displayCol.includes('division')) {
        this.filters.division = [];
        this.sortDetails = [];
      }

    // Effective Transfer Date Range
    if (!displayCol.includes('effectiveTransferDate')) {
      this.filters.transferStartDate = null;
      this.filters.transferEndDate = null;
      this.sortDetails = [];
    }

    // Total Cost Range
    if (!displayCol.includes('totalCost')) {
      this.filters.minTotalCost = null;
      this.filters.maxTotalCost = null;
      this.sortDetails = [];
    }

    if (!displayCol.includes('starred')) {
      this.filters.favorites = null;
      this.sortDetails = [];
    }

    const filterOptionsPreference: UserPreference = {
      Key: 'aggregation_filter',
      Value: this.filters,
      Persist: false,
    };
    this.userPreferenceService.setPreference(filterOptionsPreference);
    this.displayChips(this.filters);

    this.childObj.pageEvent.pageIndex = 0;
    const aggregationFetchParameter: AggregationFetchparameter = {
      itemsPerPage: this.childObj.paginator.pageSize,
      pageNumber: this.childObj.pageEvent.pageIndex
    };
    if (this.searchValue && (this.searchValue.length > 2)) {
      aggregationFetchParameter.searchText = this.searchValue;
    }

    // tslint:disable-next-line: triple-equals
    if (this.sortDetails != []) {
      aggregationFetchParameter.sortColumnName = this.sortDetails.sortColumnName;
      aggregationFetchParameter.sortOrder = this.sortDetails.sortDirection;
      aggregationFetchParameter.sortObject = [{
                  sortColumnName : this.sortDetails.sortColumnName,
                  sortOrder : this.sortDetails.sortDirection
                }];
    } else {
      aggregationFetchParameter.sortColumnName = 'lastName';
      aggregationFetchParameter.sortOrder = 'asc';
      aggregationFetchParameter.sortObject = [{
        sortColumnName : 'lastName',
        sortOrder : 'asc'
      }];
    }

    // const sortPreference = {
    //   sortColumnName: aggregationFetchParameter.sortColumnName,
    //   sortOrder: aggregationFetchParameter.sortOrder
    // };
    const sortPreference = this.childObj.getSortingColumns(aggregationFetchParameter.sortColumnName);
    const preference: UserPreference = {
      Key: 'sort_options',
      Value: sortPreference,
      Persist: false
    };
    this.userPreferenceService.setPreference(preference);

    if (this.filters) {
      aggregationFetchParameter.filters = this.filters;
    }
    this.childObj.filterEmployeeDetails(aggregationFetchParameter);
    this.childObj.applyFilter(this.searchValue);
  }


  displayChips(filters) {
    if (filters) {
      this.filters = filters;
      const preference: UserPreference = {
        Key: 'aggregation_filter',
        Value: this.filters,
        Persist: false
      };
      this.userPreferenceService.setPreference(preference);
      // Move Phase
      if (this.filters.movePhase) {
        const movePhaseObject = this.filterDetailsListItems.filter(items => items.category === 'Move Phase');
        const removeItems = [];
        movePhaseObject.map(data => {
          const index = this.filters.movePhase.filter((value) => value === data);
          if (index.length === 0) {
            removeItems.push(data);
          }
        });
        const filteredItems = this.filterDetailsListItems.filter(item => !removeItems.includes(item));
        this.filterDetailsListItems = filteredItems;
        this.filters.movePhase.map(data => {
          let checkFilter = true;
          const filtersCount = this.filterDetailsListItems.filter(items => items.value === data);
          if (filtersCount.length > 0) {
            checkFilter = false;
          }
          // tslint:disable-next-line: rxjs-no-unsafe-scope
          this.filterDetailsListCounter++;
          // tslint:disable-next-line: rxjs-no-unsafe-scope
          if (this.filterCount > 0 && checkFilter) {
            this.filterDetailsListItems.unshift({
              // tslint:disable-next-line: rxjs-no-unsafe-scope
              id: this.filterDetailsListCounter,
              category: 'Move Phase',
              value: data.value
            });
          } else if (checkFilter) {
            this.filterDetailsListItems.push({
              // tslint:disable-next-line: rxjs-no-unsafe-scope
              id: this.filterDetailsListCounter,
              category: 'Move Phase',
              value: data.value
            });
          }
        });
      }

      // File Status
      if (this.filters.fileStatusDesc) {
        const fileStatusObject = this.filterDetailsListItems.filter(items => items.category === 'File Status');
        const removeItems = [];
        fileStatusObject.map(data => {
          const index = this.filters.fileStatusDesc.filter((value) => value === data.value);
          if (index.length === 0) {
            removeItems.push(data);
          }
        });
        const filteredItems = this.filterDetailsListItems.filter(item => !removeItems.includes(item));
        this.filterDetailsListItems = filteredItems;
        this.filters.fileStatusDesc.map(data => {
          let checkFilter = true;
          const filtersCount = this.filterDetailsListItems.filter(items => items.value === data);
          if (filtersCount.length > 0) {
            checkFilter = false;
          }
          // tslint:disable-next-line: rxjs-no-unsafe-scope
          this.filterDetailsListCounter++;
          // tslint:disable-next-line: rxjs-no-unsafe-scope
          if (this.filterCount > 0 && checkFilter) {
            this.filterDetailsListItems.unshift({
              // tslint:disable-next-line: rxjs-no-unsafe-scope
              id: this.filterDetailsListCounter,
              category: 'File Status',
              value: data
            });
          } else if (checkFilter) {
            this.filterDetailsListItems.push({
              // tslint:disable-next-line: rxjs-no-unsafe-scope
              id: this.filterDetailsListCounter,
              category: 'File Status',
              value: data
            });
          }
        });
      }

      // Departure
      if (this.filters.departure) {
        if (this.filters.departure.length === 0) {
          this.filterDetailsListItems = this.filterDetailsListItems.filter(data => {
            return (data.category !== 'Departure');
          });
        } else {
          const deptCountryObject = this.filterDetailsListItems.filter(items => items.type === 'country' && items.category === 'Departure');
          const removeCountryItems = [];
          deptCountryObject.map(data => {
            // tslint:disable-next-line: rxjs-no-unsafe-scope
            const index = this.filters.departure[0].values.filter((value) => value === data.value);
            if (index.length === 0) {
              removeCountryItems.push(data);
            }
          });
          const filteredCountryItems = this.filterDetailsListItems.filter(item => !removeCountryItems.includes(item));
          this.filterDetailsListItems = filteredCountryItems;
          this.filters.departure[0].values.map(data => {
            let checkFilter = true;
            const filtersCount = this.filterDetailsListItems.filter(items => items.value === data && items.category === 'Departure');
            if (filtersCount.length > 0) {
              checkFilter = false;
            }
            // tslint:disable-next-line: rxjs-no-unsafe-scope
            this.filterDetailsListCounter++;
            // tslint:disable-next-line: rxjs-no-unsafe-scope
            if (this.filterCount > 0 && checkFilter) {
              this.filterDetailsListItems.unshift({
                // tslint:disable-next-line: rxjs-no-unsafe-scope
                id: this.filterDetailsListCounter,
                category: 'Departure',
                value: data,
                type: 'country'
              });
            } else if (checkFilter) {
              this.filterDetailsListItems.push({
                // tslint:disable-next-line: rxjs-no-unsafe-scope
                id: this.filterDetailsListCounter,
                category: 'Departure',
                value: data,
                type: 'country'
              });
            }
          });

          const deptStateObject = this.filterDetailsListItems.filter(items => items.type === 'state' && items.category === 'Departure');
          const removeStateItems = [];
          deptStateObject.map(data => {
            // tslint:disable-next-line: rxjs-no-unsafe-scope
            const index = this.filters.departure[1].values.filter((value) => value === data.value);
            if (index.length === 0) {
              removeStateItems.push(data);
            }
          });
          const filteredStateItems = this.filterDetailsListItems.filter(item => !removeStateItems.includes(item));
          this.filterDetailsListItems = filteredStateItems;
          this.filters.departure[1].values.map(data => {
            let checkFilter = true;
            const filtersCount = this.filterDetailsListItems.filter(items => items.value === data && items.category === 'Departure');
            if (filtersCount.length > 0) {
              checkFilter = false;
            }
            // tslint:disable-next-line: rxjs-no-unsafe-scope
            this.filterDetailsListCounter++;
            // tslint:disable-next-line: rxjs-no-unsafe-scope
            if (this.filterCount > 0 && checkFilter) {
              this.filterDetailsListItems.unshift({
                // tslint:disable-next-line: rxjs-no-unsafe-scope
                id: this.filterDetailsListCounter,
                category: 'Departure',
                value: data,
                type: 'state'
              });
            } else if (checkFilter) {
              this.filterDetailsListItems.push({
                // tslint:disable-next-line: rxjs-no-unsafe-scope
                id: this.filterDetailsListCounter,
                category: 'Departure',
                value: data,
                type: 'state'
              });
            }
          });

          const deptCityObject = this.filterDetailsListItems.filter(items => items.type === 'city' && items.category === 'Departure');
          const removeCityItems = [];
          deptCityObject.map(data => {
            // tslint:disable-next-line: rxjs-no-unsafe-scope
            const index = this.filters.departure[2].values.filter((value) => value === data.value);
            if (index.length === 0) {
              removeCityItems.push(data);
            }
          });
          const filteredCityItems = this.filterDetailsListItems.filter(item => !removeCityItems.includes(item));
          this.filterDetailsListItems = filteredCityItems;
          this.filters.departure[2].values.map(data => {
            let checkFilter = true;
            const filtersCount = this.filterDetailsListItems.filter(items => items.value === data && items.category === 'Departure');
            if (filtersCount.length > 0) {
              checkFilter = false;
            }
            // tslint:disable-next-line: rxjs-no-unsafe-scope
            this.filterDetailsListCounter++;
            // tslint:disable-next-line: rxjs-no-unsafe-scope
            if (this.filterCount > 0 && checkFilter) {
              this.filterDetailsListItems.unshift({
                // tslint:disable-next-line: rxjs-no-unsafe-scope
                id: this.filterDetailsListCounter,
                category: 'Departure',
                value: data,
                type: 'city'
              });
            } else if (checkFilter) {
              this.filterDetailsListItems.push({
                // tslint:disable-next-line: rxjs-no-unsafe-scope
                id: this.filterDetailsListCounter,
                category: 'Departure',
                value: data,
                type: 'city'
              });
            }
          });
        }
      }

      // Destination
      if (this.filters.destination) {
        if (this.filters.destination.length === 0) {
          this.filterDetailsListItems = this.filterDetailsListItems.filter(data => {
            return !(data.category === 'Destination');
          });
        } else {
          const destCountryObj = this.filterDetailsListItems.filter(items => items.type === 'country' && items.category === 'Destination');
          const removeCountryItems = [];
          destCountryObj.map(data => {
            // tslint:disable-next-line: rxjs-no-unsafe-scope
            const index = this.filters.destination[0].values.filter((value) => value === data.value);
            if (index.length === 0) {
              removeCountryItems.push(data);
            }
          });
          const filteredCountryItems = this.filterDetailsListItems.filter(item => !removeCountryItems.includes(item));
          this.filterDetailsListItems = filteredCountryItems;
          this.filters.destination[0].values.map(data => {
            let checkFilter = true;
            const filtersCount = this.filterDetailsListItems.filter(items => items.value === data && items.category === 'Destination');
            if (filtersCount.length > 0) {
              checkFilter = false;
            }
            // tslint:disable-next-line: rxjs-no-unsafe-scope
            this.filterDetailsListCounter++;
            // tslint:disable-next-line: rxjs-no-unsafe-scope
            if (this.filterCount > 0 && checkFilter) {
              this.filterDetailsListItems.unshift({
                // tslint:disable-next-line: rxjs-no-unsafe-scope
                id: this.filterDetailsListCounter,
                category: 'Destination',
                value: data,
                type: 'country'
              });
            } else if (checkFilter) {
              this.filterDetailsListItems.push({
                // tslint:disable-next-line: rxjs-no-unsafe-scope
                id: this.filterDetailsListCounter,
                category: 'Destination',
                value: data,
                type: 'country'
              });
            }
          });

          const destStateObject = this.filterDetailsListItems.filter(items => items.type === 'state' && items.category === 'Destination');
          const removeStateItems = [];
          destStateObject.map(data => {
            // tslint:disable-next-line: rxjs-no-unsafe-scope
            const index = this.filters.destination[1].values.filter((value) => value === data.value);
            if (index.length === 0) {
              removeStateItems.push(data);
            }
          });
          const filteredStateItems = this.filterDetailsListItems.filter(item => !removeStateItems.includes(item));
          this.filterDetailsListItems = filteredStateItems;
          this.filters.destination[1].values.map(data => {
            let checkFilter = true;
            const filtersCount = this.filterDetailsListItems.filter(items => items.value === data && items.category === 'Destination');
            if (filtersCount.length > 0) {
              checkFilter = false;
            }
            // tslint:disable-next-line: rxjs-no-unsafe-scope
            this.filterDetailsListCounter++;
            // tslint:disable-next-line: rxjs-no-unsafe-scope
            if (this.filterCount > 0 && checkFilter) {
              this.filterDetailsListItems.unshift({
                // tslint:disable-next-line: rxjs-no-unsafe-scope
                id: this.filterDetailsListCounter,
                category: 'Destination',
                value: data,
                type: 'state'
              });
            } else if (checkFilter) {
              this.filterDetailsListItems.push({
                // tslint:disable-next-line: rxjs-no-unsafe-scope
                id: this.filterDetailsListCounter,
                category: 'Destination',
                value: data,
                type: 'state'
              });
            }
          });

          const destCityObject = this.filterDetailsListItems.filter(items => items.type === 'city' && items.category === 'Destination');
          const removeCityItems = [];
          destCityObject.map(data => {
            // tslint:disable-next-line: rxjs-no-unsafe-scope
            const index = this.filters.destination[2].values.filter((value) => value === data.value);
            if (index.length === 0) {
              removeCityItems.push(data);
            }
          });
          const filteredCityItems = this.filterDetailsListItems.filter(item => !removeCityItems.includes(item));
          this.filterDetailsListItems = filteredCityItems;
          this.filters.destination[2].values.map(data => {
            let checkFilter = true;
            const filtersCount = this.filterDetailsListItems.filter(items => items.value === data && items.category === 'Destination');
            if (filtersCount.length > 0) {
              checkFilter = false;
            }
            // tslint:disable-next-line: rxjs-no-unsafe-scope
            this.filterDetailsListCounter++;
            // tslint:disable-next-line: rxjs-no-unsafe-scope
            if (this.filterCount > 0 && checkFilter) {
              this.filterDetailsListItems.unshift({
                // tslint:disable-next-line: rxjs-no-unsafe-scope
                id: this.filterDetailsListCounter,
                category: 'Destination',
                value: data,
                type: 'city'
              });
            } else if (checkFilter) {
              this.filterDetailsListItems.push({
                // tslint:disable-next-line: rxjs-no-unsafe-scope
                id: this.filterDetailsListCounter,
                category: 'Destination',
                value: data,
                type: 'city'
              });
            }
          });
        }
      }

      // Policy
      if (this.filters.policy) {
        const policyObject = this.filterDetailsListItems.filter(items => items.category === 'Policy');
        const removeItems = [];
        policyObject.map(data => {
          const index = this.filters.policy.filter((value) => value === data);
          if (index.length === 0) {
            removeItems.push(data);
          }
        });
        const filteredItems = this.filterDetailsListItems.filter(item => !removeItems.includes(item));
        this.filterDetailsListItems = filteredItems;
        this.filters.policy.map(data => {
          let checkFilter = true;
          const filtersCount = this.filterDetailsListItems.filter(items => items.value === data);
          if (filtersCount.length > 0) {
            checkFilter = false;
          }
          // tslint:disable-next-line: rxjs-no-unsafe-scope
          this.filterDetailsListCounter++;
          // tslint:disable-next-line: rxjs-no-unsafe-scope
          if (this.filterCount > 0 && checkFilter) {
            this.filterDetailsListItems.unshift({
              // tslint:disable-next-line: rxjs-no-unsafe-scope
              id: this.filterDetailsListCounter,
              category: 'Policy',
              value: data
            });
          } else if (checkFilter) {
            this.filterDetailsListItems.push({
              // tslint:disable-next-line: rxjs-no-unsafe-scope
              id: this.filterDetailsListCounter,
              category: 'Policy',
              value: data
            });
          }
        });
      }
      // Division
      if (this.filters.division) {
        const divisionObject = this.filterDetailsListItems.filter(items => items.category === 'Division');
        const removeItems = [];
        divisionObject.map(data => {
          const index = this.filters.division.filter((value) => value === data);
          if (index.length === 0) {
            removeItems.push(data);
          }
        });
        const filteredItems = this.filterDetailsListItems.filter(item => !removeItems.includes(item));
        this.filterDetailsListItems = filteredItems;
        this.filters.division.map(data => {
          let checkFilter = true;
          const filtersCount = this.filterDetailsListItems.filter(items => items.value === data);
          if (filtersCount.length > 0) {
            checkFilter = false;
          }
          // tslint:disable-next-line: rxjs-no-unsafe-scope
          this.filterDetailsListCounter++;
          // tslint:disable-next-line: rxjs-no-unsafe-scope
          if (this.filterCount > 0 && checkFilter) {
            this.filterDetailsListItems.unshift({
              // tslint:disable-next-line: rxjs-no-unsafe-scope
              id : this.filterDetailsListCounter,
              category : 'Division',
              value: data.divisionName
            });
          } else if (checkFilter) {
            this.filterDetailsListItems.push({
              // tslint:disable-next-line: rxjs-no-unsafe-scope
              id : this.filterDetailsListCounter,
              category : 'Division',
              value: data.divisionName
            });
          }
        });
      }
      // Effective Transfer Date Range
      if (this.filters.transferStartDate || this.filters.transferEndDate) {
        const transferStartDate = this.dateConversionService.convertToDisplayDate(this.filters.transferStartDate.toString());
        let dateRange: any, transferEndDate: any;
        if (!this.filters.transferEndDate) {
          this.endDateExist = false;
          dateRange = transferStartDate + ' - ';
        } else {
          this.endDateExist = true;
          transferEndDate = this.dateConversionService.convertToDisplayDate(this.filters.transferEndDate.toString());
          dateRange = transferStartDate + ' - ' + transferEndDate;
        }
        this.filterDetailsListCounter++;
        for (let i = 0; i < this.filterDetailsListItems.length; i++) {
          if (this.filterDetailsListItems[i].category === 'Date Range') {
            this.filterDetailsListItems.splice(i, 1);
            break;
          }
        }
        if (this.filterCount > 0 && this.endDateExist) {
          this.filterDetailsListItems.unshift({
            id: this.filterDetailsListCounter,
            category: 'Date Range',
            value: dateRange
          });
        } else if (this.filterCount > 0 && !this.endDateExist) {
          this.filterDetailsListItems.unshift({
            id: this.filterDetailsListCounter,
            category: 'Date Range',
            value: dateRange,
            infinite: true
          });
        } else if (!this.endDateExist) {
          this.filterDetailsListItems.push({
            id: this.filterDetailsListCounter,
            category: 'Date Range',
            value: dateRange,
            infinite: true
          });
        } else {
          this.filterDetailsListItems.push({
            id: this.filterDetailsListCounter,
            category: 'Date Range',
            value: dateRange
          });
        }
      } else {
        const dateRangeObject = this.filterDetailsListItems.filter(items => items.category === 'Date Range');
        const filteredItems = this.filterDetailsListItems.filter(range => !dateRangeObject.includes(range));
        this.filterDetailsListItems = filteredItems;
      }

      // Total Cost Range
      if (this.filters.minTotalCost || this.filters.maxTotalCost) {
        let costRange: any;
        if (!this.filters.maxTotalCost) {
          this.maxCostExist = false;
          costRange = this.filters.minTotalCost + ' - ';
        } else {
          this.maxCostExist = true;
          costRange = this.filters.minTotalCost + ' - ' + this.filters.maxTotalCost;
        }
        this.filterDetailsListCounter++;
        for (let i = 0; i < this.filterDetailsListItems.length; i++) {
          if (this.filterDetailsListItems[i].category === 'Cost Range') {
            this.filterDetailsListItems.splice(i, 1);
            break;
          }
        }
        if (this.filterCount > 0 && this.maxCostExist) {
          this.filterDetailsListItems.unshift({
            id: this.filterDetailsListCounter,
            category: 'Cost Range',
            value: costRange
          });
        } else if (this.filterCount > 0 && !this.maxCostExist) {
          this.filterDetailsListItems.unshift({
            id: this.filterDetailsListCounter,
            category: 'Cost Range',
            value: costRange,
            infinite: true
          });
        } else if (!this.maxCostExist) {
          this.filterDetailsListItems.push({
            id: this.filterDetailsListCounter,
            category: 'Cost Range',
            value: costRange,
            infinite: true
          });
        } else {
          this.filterDetailsListItems.push({
            id: this.filterDetailsListCounter,
            category: 'Cost Range',
            value: costRange
          });
        }
      } else {
        const costRangeObject = this.filterDetailsListItems.filter(items => items.category === 'Cost Range');
        const filteredItems = this.filterDetailsListItems.filter(range => !costRangeObject.includes(range));
        this.filterDetailsListItems = filteredItems;
      }

      // favourites
      if (this.filters.favorites === 1) {
        const favouritesObject = this.filterDetailsListItems.filter(items => items.category === 'favorites');
        if (favouritesObject.length === 0) {
          this.filterDetailsListItems.push({
            id: this.filterDetailsListCounter,
            category: 'favorites',
            value: 'Starred Only'
          });
        }
      } else {
        const favouritesObject = this.filterDetailsListItems.filter(items => items.category === 'favorites');
        const filteredItems = this.filterDetailsListItems.filter(favouite => !favouritesObject.includes(favouite));
        this.filterDetailsListItems = filteredItems;
      }


      const filtersPreference: UserPreference = {
        Key: 'chips_data',
        Value: this.filterDetailsListItems,
        Persist: false
      };
      this.userPreferenceService.setPreference(filtersPreference);
    }

    if (this.searchValue && (this.searchValue.length > 2)) {
      filters.searchText = this.searchValue;
    }
    this.filterCount++;
  }

  /** getUpdatedFilterOptions method to get the updated list of filters from filter chip component */
  getUpdatedFilterOptions($event) {
    this.userPreferenceService.getPreference('aggregation_filter', false).subscribe((response) => {
      if (response) {
        this.filters = response;
      }
    });
    this.filterOptions = $event;
    // Move Phase
    if (this.filterOptions.category === 'Move Phase') {
      for (let i = 0; i < this.filters.movePhase.length; i++) {
        if (this.filters.movePhase[i].value === this.filterOptions.value) {
          this.filters.movePhase.splice(i, 1);
        }
      }
    }

    // File Status
    if (this.filterOptions.category === 'File Status') {
      const index = this.filters.fileStatusDesc.indexOf(this.filterOptions.value);
      if (index >= 0) {
        this.filters.fileStatusDesc.splice(index, 1);
      }
    }

    // Departure
    if (this.filterOptions.category === 'Departure') {
      if (this.filterOptions.type === 'country') {
        const index = this.filters.departure[0].values.indexOf(this.filterOptions.value);
        if (index >= 0) {
          this.filters.departure[0].values.splice(index, 1);
        }
      }
      if (this.filterOptions.type === 'state') {
        const index = this.filters.departure[1].values.indexOf(this.filterOptions.value);
        if (index >= 0) {
          this.filters.departure[1].values.splice(index, 1);
        }
      }
      if (this.filterOptions.type === 'city') {
        const index = this.filters.departure[2].values.indexOf(this.filterOptions.value);
        if (index >= 0) {
          this.filters.departure[2].values.splice(index, 1);
        }
      }
    }

    // Destination
    if (this.filterOptions.category === 'Destination') {
      if (this.filterOptions.type === 'country') {
        const index = this.filters.destination[0].values.indexOf(this.filterOptions.value);
        if (index >= 0) {
          this.filters.destination[0].values.splice(index, 1);
        }
      }
      if (this.filterOptions.type === 'state') {
        const index = this.filters.destination[1].values.indexOf(this.filterOptions.value);
        if (index >= 0) {
          this.filters.destination[1].values.splice(index, 1);
        }
      }
      if (this.filterOptions.type === 'city') {
        const index = this.filters.destination[2].values.indexOf(this.filterOptions.value);
        if (index >= 0) {
          this.filters.destination[2].values.splice(index, 1);
        }
      }
    }

    // Policy
    if (this.filterOptions.category === 'Policy') {
      for (let i = 0; i < this.filters.policy.length; i++) {
        if (this.filters.policy[i] === this.filterOptions.value) {
          this.filters.policy.splice(i, 1);
        }
      }
    }

      // Division
      if (this.filterOptions.category === 'Division') {
        for (let i = 0; i < this.filters.division.length; i++) {
          const divString = this.filters.division[i].divisionName;
          if ( divString === this.filterOptions.value) {
            this.filters.division.splice(i, 1);
          }
        }
      }

    // Effective Transfer Date Range
    if (this.filterOptions.category === 'Date Range') {
      this.filters.transferStartDate = null;
      this.filters.transferEndDate = null;
    }

    // Total Cost Range
    if (this.filterOptions.category === 'Cost Range') {
      this.filters.minTotalCost = null;
      this.filters.maxTotalCost = null;
    }

    if (this.filterOptions.category === 'favorites') {
      this.filters.favorites = null;
    }

    const filterOptionsPreference: UserPreference = {
      Key: 'aggregation_filter',
      Value: this.filters,
      Persist: false,
    };
    this.userPreferenceService.setPreference(filterOptionsPreference);
    this.displayChips(this.filters);

    this.childObj.pageEvent.pageIndex = 0;
    const aggregationFetchParameter: AggregationFetchparameter = {
      itemsPerPage: this.childObj.paginator.pageSize,
      pageNumber: this.childObj.pageEvent.pageIndex
    };
    if (this.searchValue && (this.searchValue.length > 2)) {
      aggregationFetchParameter.searchText = this.searchValue;
    }

    if (this.sortDetails) {
      aggregationFetchParameter.sortObject = [{
                  sortColumnName : this.sortDetails.sortColumnName,
                  sortOrder : this.sortDetails.sortDirection
                }];
    }

    if (this.filters) {
      aggregationFetchParameter.filters = this.filters;
    }
    this.childObj.filterEmployeeDetails(aggregationFetchParameter);
    this.childObj.applyFilter(this.searchValue);
  }

  /** getSearchTextResults method to get results based on searchText */
  getSearchTextResults(searchValue) {
    this.isSearchTriggered = true;
    let searchText: string = searchValue;
    if (searchText.length === 11 && searchText.charAt(2) === '-' && searchText.charAt(6) === '-') {
      searchText = this.dateConversionService.convertFromDisplayDate(searchText);
    }
    if ((searchText.length > 2) || (!this.isSearchCleared && searchText === '')) {
      if (searchText.length > 2) {
        this.isSearchCleared = false;
      } else {
        this.isSearchCleared = true;
      }
      if (this.aggregateDashboardBy === 'employeeList') {
        const preference: UserPreference = {
          Key: 'filter_text',
          Value: searchText,
          Persist: false
        };
        this.userPreferenceService.setPreference(preference);
        this.childObj.pageEvent.pageIndex = 0;
        const aggregationFetchParameter: AggregationFetchparameter = {
          itemsPerPage: this.childObj.paginator.pageSize,
          pageNumber: this.childObj.pageEvent.pageIndex,
          searchText: searchText
        };
        if (this.sortDetails) {
          aggregationFetchParameter.sortObject = [{
                  sortColumnName : this.sortDetails.sortColumnName,
                  sortOrder : this.sortDetails.sortDirection
                }];
        }
        if (this.filters) {
          aggregationFetchParameter.filters = this.filters;
        }
        this.childObj.filterEmployeeDetails(aggregationFetchParameter);
        this.childObj.applyFilter(searchText);
      } else {
        const preference: UserPreference = {
          Key: 'filter_text',
          Value: searchValue,
          Persist: false
        };
        this.userPreferenceService.setPreference(preference);
        this.aggreChildObj.pageEvent.pageIndex = 0;
        const aggregationFetchParameter: AggregationFetchparameter = {
          itemsPerPage: this.aggreChildObj.paginator.pageSize,
          pageNumber: this.aggreChildObj.pageEvent.pageIndex,
          searchText: searchValue
        };
        this.aggreChildObj.filterAggregationDetails(aggregationFetchParameter);
        this.aggreChildObj.applyFilter(searchValue);
      }
    }
  }

  /** clearSearchTextResults to clear the text entered for search */
  clearSearchTextResults() {
    if (this.aggregateDashboardBy === 'employeeList') {
      this.childObj.pageEvent.pageIndex = 0;
      this.searchValue = '';
      const preference: UserPreference = {
        Key: 'filter_text',
        Value: this.searchValue,
        Persist: false
      };
      this.userPreferenceService.setPreference(preference);
      const aggregationFetchParameter: AggregationFetchparameter = {
        itemsPerPage: this.childObj.paginator.pageSize,
        pageNumber: this.childObj.pageEvent.pageIndex,
        searchText: this.searchValue
      };
      if (this.sortDetails) {
        aggregationFetchParameter.sortObject = [{
                  sortColumnName : this.sortDetails.sortColumnName,
                  sortOrder : this.sortDetails.sortDirection
                }];
      }
      if (this.filters) {
        aggregationFetchParameter.filters = this.filters;
      }
      this.childObj.filterEmployeeDetails(aggregationFetchParameter);
      this.childObj.applyFilter(this.searchValue);
      this.isSearchTriggered = false;
    } else {
      this.aggreChildObj.pageEvent.pageIndex = 0;
      this.searchValue = '';
      const preference: UserPreference = {
        Key: 'filter_text',
        Value: this.searchValue,
        Persist: false
      };
      this.userPreferenceService.setPreference(preference);
      const aggregationFetchParameter: AggregationFetchparameter = {
        itemsPerPage: this.aggreChildObj.paginator.pageSize,
        pageNumber: this.aggreChildObj.pageEvent.pageIndex,
        searchText: this.searchValue
      };
      this.aggreChildObj.filterAggregationDetails(aggregationFetchParameter);
      this.aggreChildObj.applyFilter(this.searchValue);
    }
  }

  /** ClearCriteria method to clear all selected criteria (Filters and SearchText) */
  clearCriteria() {
    this.childObj.pageEvent.pageIndex = 0;
    this.searchValue = '';
    this.filters = {
      'policy': [],
      'movePhase': [],
      'fileStatusDesc': [],
      'departure': [],
      'destination': [],
      'transferStartDate': null,
      'transferEndDate': null,
      'minTotalCost': null,
      'maxTotalCost': null,
      'division': [],
      'favorites': null,

    };
    // this.filters = null;
    const preference: UserPreference = {
      Key: 'aggregation_filter',
      Value: this.filters,
      Persist: false
    };


    this.userPreferenceService.setPreference(preference);
    const filterTextPreference: UserPreference = {
      Key: 'filter_text',
      Value: this.searchValue,
      Persist: false
    };
    this.userPreferenceService.setPreference(filterTextPreference);

    const pageEventPreference: UserPreference = {
      Key: 'page_event',
      Value: this.childObj.pageEvent,
      Persist: false,
    };
    this.userPreferenceService.setPreference(pageEventPreference);
    // Below remove code later
    // const sortOptionsPreference: UserPreference = {
    //   Key: 'sort_options',
    //   Value: [],
    //   Persist: false,
    // };
    // this.userPreferenceService.setPreference(sortOptionsPreference);
    this.filterDetailsListItems = [];
    const filtersPreference: UserPreference = {
      Key: 'chips_data',
      Value: this.filterDetailsListItems,
      Persist: false
    };
    this.userPreferenceService.setPreference(filtersPreference);
    const aggregationFetchParameter: AggregationFetchparameter = {
      itemsPerPage: this.childObj.paginator.pageSize,
      pageNumber: this.childObj.pageEvent.pageIndex,
      searchText: this.searchValue
    };
    if (this.sortDetails) {
      aggregationFetchParameter.sortObject = [{
                  sortColumnName : this.sortDetails.sortColumnName,
                  sortOrder : this.sortDetails.sortDirection
                }];
    }
    this.childObj.filterEmployeeDetails(aggregationFetchParameter);
  }

  /** addMonths method to calculate and get six months before from today */
  addMonths(date: any, months: any) {
    date.setMonth(date.getMonth() + months);
    return date;
  }

  /** Init method to make Service call, to get the saved user preferences for the aggregate view*/
  ngOnInit() {
    this.userPreferenceService.getPreference('filter_text', false).subscribe((response) => {
      if (response) {
        this.searchValue = response;
      }
    });
    // Uncomment this code once we have the User Config API to call
     this.userConfigSub = this.userConfigService.getUserConfigDetails().subscribe(res => {
        if (res) {
                const userConfigPreference: UserPreference = {
                    Key: 'user_config',
                    Value: res,
                    Persist: false
                  };
                  this.userPreferenceService.setPreference(userConfigPreference);
                  this.userPreferenceService.getPreference('user_config', false)
      .subscribe((response) => {
        if (response) {
          response.forEach((item) => {
            if (item.preferenceconfig.name == 'EmployeeListViewColumnDisplay') {
              if ('preferencesetting' in item && item.preferencesetting.value.length > 0) {
                this.displayedColumns = item.preferencesetting.value;
              } else {
                this.displayedColumns = item.preferenceconfig.default;
              }
              this.filterIcon();
            }
          });
         }
      });
                }
    //   this.aggregateDashboardBy = this.userPreferences.aggregateDashboardBy;
    this.userPreferences = { // Temporary code until API available
      aggregateDashboardBy: 'employeeList'
    };
    this.contextFlag = JSON.parse(sessionStorage.getItem('UserContext')).multiCurrencyFlag;
    this.userPreferenceService.getPreference('aggregation_dashboard', true).subscribe((response) => {
      if (response) {
        this.aggregateDashboardBy = response;
        // Uncomment this code once we have this API available
        // this.userConfigService.setUserConfigDetails(this.userPreferences)
        // .pipe(take(1))
        // .subscribe(value => {
        //   this.saveResult = value;
        // });
      }
    });
    // if (this.aggregateDashboardBy == 'employeeList'){
      // this.childObj.setConfiguration();
    // }
    if (!this.powerBIHiddenClientList.includes(JSON.parse(sessionStorage.getItem('UserContext')).clientNo)) {
      this.featureFlagService.getFeatureFlag('movepro360-enable-power-bi').toPromise().then((val) => {
        this.showPowerBI = (val.toString() === 'true');
      });
      const configs: PowerBiVisualConfig[] | PowerBiReportConfig[] = this.powerBiService.getConfig();
      this.configs$ = new Observable<PowerBiVisualConfig[] | PowerBiReportConfig[]>(obs => {
        obs.next(configs);
      });
    } else {
      console.log('powerBI disabled for this client');
      this.showPowerBI = false;
    }

    if (document.location.hostname !== 'localhost') {
      this.userContextService.getUserContext().toPromise().then((context) => {
        if (context.multiCurrencyFlag) {
          this.isMultiCurrencyFlag = context.multiCurrencyFlag;
        }
      });
    }
    this.userPreferenceService.getPreference('aggregation_filter', false).subscribe((response) => {
      let defaultFilter: any;
      if (response) {
        this.filters = response;
      } else {
        let startDate = new Date();
        startDate = this.addMonths(startDate, -6);
        const startDateString = this.dateConversionService.convertToYyyyMmDd(startDate);
        defaultFilter = {
          'policy': [],
          'movePhase': [],
          'fileStatusDesc': [],
          'departure': [],
          'destination': [],
          'transferStartDate': startDateString,
          'transferEndDate': null,
          'minTotalCost': null,
          'maxTotalCost': null,
          'division': [],
          'favorites': null,
        };
        this.filters = defaultFilter;
        this.displayChips(defaultFilter);
      }
    });
  });
  }

  /** setActive method is emitted from aggregation component on click on aggregation value
   * to set the aggregation fetch parameters based on aggregation value
   * To input the res to employee details component
   */
  setActive([type]) {
    this.aggregateDashboardBy = 'employeeList';
    const dashboardPreference: UserPreference = {
      Key: 'aggregation_dashboard',
      Value: this.aggregateDashboardBy,
      Persist: true
    };
    this.userPreferenceService.setPreference(dashboardPreference);
    const aggreType: string = type[0];
    const aggreValue: any = type[1];
    if (aggreValue && aggreType === 'destinationCountry') {
      this.filters = {
        policy: [],
        movePhase: [],
        fileStatusDesc: [],
        departure: [],
        transferStartDate: null,
        transferEndDate: null,
        minTotalCost: null,
        maxTotalCost: null,
        favorites: null,
        destination: [{
          type: 'dest',
          name: 'country',
          label: 'Countries',
          values: [aggreValue]
        },
        {
          type: 'dest',
          name: 'state',
          label: 'States',
          values: []
        },
        {
          type: 'dest',
          name: 'city',
          label: 'Cities',
          values: []
        }]
      };
      this.filterDetailsListItems = [{ category: 'Destination', type: 'country', value: aggreValue }];
    } else if (aggreValue && aggreType === 'departureCountry') {
      this.filters = {
        policy: [],
        movePhase: [],
        fileStatusDesc: [],
        destination: [],
        transferStartDate: null,
        transferEndDate: null,
        minTotalCost: null,
        maxTotalCost: null,
        favorites: null,
        departure: [{
          type: 'dept',
          name: 'country',
          label: 'Countries',
          values: [aggreValue]
        },
        {
          type: 'dept',
          name: 'state',
          label: 'States',
          values: []
        },
        {
          type: 'dept',
          name: 'city',
          label: 'Cities',
          values: []
        }]
      };
      this.filterDetailsListItems = [{ category: 'Departure', type: 'country', value: aggreValue }];
    } else if (aggreValue && aggreType === 'policyName') {
      this.filters = {
        destination: [],
        movePhase: [],
        fileStatusDesc: [],
        departure: [],
        transferStartDate: null,
        transferEndDate: null,
        minTotalCost: null,
        maxTotalCost: null,
        favorites: null,
        policy: [aggreValue]
      };
      this.filterDetailsListItems = [{ category: 'Policy', value: aggreValue }];
    } else if (aggreValue && aggreType === 'divisionName') {
      this.filters = {
        destination: [],
        movePhase: [],
        fileStatusDesc: [],
        departure: [],
        transferStartDate: null,
        transferEndDate: null,
        minTotalCost: null,
        maxTotalCost: null,
        division: [{ divisionNumber: aggreValue.divisionNumber, divisionName : aggreValue.divisionName }],
        favorites: null
      };
      this.filterDetailsListItems = [{ category: 'Division', id: aggreValue.divisionNumber, value: aggreValue.divisionName }];
    }
    const preference: UserPreference = {
      Key: 'aggregation_filter',
      Value: this.filters,
      Persist: false
    };
    const filtersPreference: UserPreference = {
      Key: 'chips_data',
      Value: this.filterDetailsListItems,
      Persist: false
    };
    let favoritesList;
    this.userPreferenceService.setPreference(filtersPreference);
    this.userPreferenceService.setPreference(preference);
    this.userPreferenceService.getPreference('favorites_list', false)
      .subscribe((response) => {
        favoritesList = response;
      });
    const aggregationFetchParameter = {
      filters: this.filters,
      itemsPerPage: 25,
      pageNumber: 0,
      favorites: favoritesList
    };
    this.childObj.filterEmployeeDetails(aggregationFetchParameter);
  }

  /** Component Angular destructor lifecycle hook */
  ngOnDestroy(): void {
    if (this.userConfigSub) {
      this.userConfigSub.unsubscribe();
    }
  }
}
