import { stringify } from '@angular/compiler/src/util';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { UserType, UserTypeService } from '../../../../../administration/services/user-type.service';
import { ProgramManagementService } from '../../../../../administration/services/program-management.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-benefit-setting-dialog',
  templateUrl: './benefit-setting-dialog.component.html',
  styleUrls: ['./benefit-setting-dialog.component.scss']
})

export class BenefitSettingDialogComponent implements OnInit {
  newlegacy: any;
  uniqueValues: any;
  productList = [];
  filteredProductOptions: Observable<any>;
  domesticProductList = [];
  internationalProductName: string;
  domesticProduct: string;
  domesticSubproduct: string;
  productSettingsForm: FormGroup;
  selectedClientId: string;
  showMore = false;
  InternationalsubProduct: Array<any>;
  domesticsubProduct: Array<any>;
  internationalFilterData: any;
  domesticFilterData: any;
  /* manage programadmin capabilities*/
  userCapabilities: number;

  constructor(public programManagementService: ProgramManagementService,
    private userTypeService: UserTypeService,
    @Inject(MAT_DIALOG_DATA) public data,
    private readonly formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<BenefitSettingDialogComponent>) {
    this.selectedClientId = data.clientId;
    this.generateProductForm();
  }

  ngOnInit() {
    this.userTypeService.capabilities$.subscribe(ele => {
      ele ? this.userCapabilities = ele.findIndex(element => element.name == "Benefits Builder Program Administration Portal" && element.operation == "write") : null;
    });

    this.programManagementService.getNewLegacy().subscribe(res => {
      this.newlegacy = res.values;
      const k = 'productName';
      this.uniqueValues = [...new Map(this.newlegacy.map(item =>
        [item[k], item])).values()];
      let productName = "";
      let subProduct: any[];
      let productList = { "productName": productName, "subProductName": subProduct }
      this.uniqueValues.forEach(element => {
        this.productList.push(this.getSubProduct(this.newlegacy, element.productName));
        this.domesticProductList.push(this.getSubProduct(this.newlegacy, element.productName));
      });
      if (this.data.internationalProductName) {
        this.InternationalsubProduct = this.productList.find(con => con.productName == this.data.internationalProductName).subProductName;
      }
      if (this.data.usDomesticProductName) {
        let data = this.domesticProductList.find(con => con.productName == this.data.usDomesticProductName);
        if (data) {
          this.domesticsubProduct = data.subProductName;
        }
      }
      this.internationalFilterData = [...this.productList];
      this.domesticFilterData = [...this.domesticProductList];
    })
  }

  /**
    * Checks for Value or will return 'empty'
    * @param value any
    */
  isNullCheck(obj: Object, key: string) {
    try {
      return ((obj[key] || obj[key] == false) && obj[key] !== null) ? obj[key] : '';
    } catch (error) {
      return '';
    }
  }

  changeInternationalProduct(productName: any) {
    this.InternationalsubProduct = this.productList.find(con => con.productName == productName).subProductName;
    this.productSettingsForm.controls.intSubproductName.setValue("");
  }

  changeDomesticProduct(productName: any) {
    this.domesticsubProduct = this.productList.find(con => con.productName == productName).subProductName
    this.productSettingsForm.controls.domesticSubproductName.setValue("");
  }

  getSubProduct(data, productName) {
    if(productName !=""){
    let subProduct = [];
    let products = data.filter(i => i.productName == productName)
    products.forEach(element => {
      element.subProductName ? subProduct.push(element.subProductName) : null;
    });
    return { "productName": productName, "subProductName": subProduct }
    }
  }

  generateProductForm() {
    const check = this.isNullCheck;
    this.productSettingsForm = this.formBuilder.group({
      intProductName: [this.data ? check(this.data, 'internationalProductName') : ''],
      intSubproductName: [this.data ? check(this.data, 'internationalSubProductName') : ''],
      domesticProductName: [this.data ? check(this.data, 'usDomesticProductName') : ''],
      domesticSubproductName: [this.data ? check(this.data, 'usDomesticSubProductName') : ''],
      internationalToggle: [this.data && check(this.data, 'internationlRevision') !== '' ?
        this.data.internationlRevision : false],
      domesticToggle: [this.data && check(this.data, 'usDomesticRevision') !== '' ?
        this.data.usDomesticRevision : false],
      intProductNameSearch : [''],
      domesticProductNameSearch: [''] 
    });
  }

  onSaveClick() {
    const productSettingsFormValue = this.productSettingsForm.value;
    const req = {
      "productSubDetails": {
        "internationalProduct": {
          "productName": this.productSettingsForm.controls.intProductName.value,
          "subProductName": this.productSettingsForm.controls.intSubproductName.value,
          "requiresRevision": this.productSettingsForm.controls.internationalToggle.value
        },
        "usDomesticProduct": {
          "productName": this.productSettingsForm.controls.domesticProductName.value,
          "subProductName": this.productSettingsForm.controls.domesticSubproductName.value,
          "requiresRevision": this.productSettingsForm.controls.domesticToggle.value
        }
      }
    };
    this.dialogRef.close({
      data: req
    })
  }

  close() {
    this.dialogRef.close();
  }

  clearIntProductName() {
    this.internationalProductName = '';
  }

  checkIsValid() {
    if (((this.productSettingsForm.controls.intProductName.value != '') ||
      (this.productSettingsForm.controls.domesticProductName.value != ''))) {
      return true;
    }
    return false;
  }

  onInternationalClear() {
    this.productSettingsForm.controls.intProductName.setValue('');
    this.productSettingsForm.controls.intSubproductName.setValue('');
  }

  onDomesticClear() {
    this.productSettingsForm.controls.domesticProductName.setValue('');
    this.productSettingsForm.controls.domesticSubproductName.setValue('');
  }
 
  internationalUpdateFilter(event) {
    if ((event.target.value).length >= 3) {
      const val = event.target.value.toLowerCase();
      const temp = this.internationalFilterData.filter((e) => {
        return e.productName.toLowerCase().indexOf(val) !== -1 || !val
      })
      this.productList = temp
    }
  }

  domesticUpdateFilter(event) {
    if ((event.target.value).length >= 3) {
      const val = event.target.value.toLowerCase();
      const temp = this.domesticFilterData.filter((e) => {
        return e.productName.toLowerCase().indexOf(val) !== -1 || !val
      })
      this.domesticProductList = temp
    }
  }

  internationalClearSearch() {
    this.productSettingsForm.controls.intProductNameSearch.reset();
    this.productList = this.internationalFilterData;
  }
  
  domesticClearSearch() {
    this.productSettingsForm.controls.domesticProductNameSearch.reset();
    this.domesticProductList = this.domesticFilterData;
  }

}
