import { Injectable } from '@angular/core';
import { BaseClientService } from './../../../core/services/base-client.service';
import { RemoteLoggingService } from './../../../core/services/remote-logging.service';
import { map, catchError } from 'rxjs/operators';
import { of, Observable, BehaviorSubject } from 'rxjs';

export type UserType = 'application-owner' | 'file-setup-specialist' | 'application-reliability-analyst' | 'client-contact' |'client-account-manager' | 'client-contact-initiator' | 'program-admin' | 'product-mgmt-admin' | 'consultant' | 'relocation-accounting-analyst' | 'team-client-service-manager' | 'cartus-ccc-qa' | 'application-technical-owner';

export interface UserRole {
  associatedParty?: string;
  clientPartyId: string;
  _id: string;
  roleName: UserType | any;
  capabilities: Array<{
    name: string;
    operation: string;
    permission: string;
    description: string
    level: string
  }>;
  clientRoles:Array<{
    benefitsBuilder?: boolean;
    traditionalAuthorization?: boolean;
    costProjection?: boolean;
  }>
  friendlyName: string;
  bypassAssociation: string;
}

export interface RoleCapabilitiesRes {
  capabilities: Array<capabilities>;
  loginUserRoleCapabilities: Array<UserRole>;
}
export interface capabilities {
  name: string;
  operation: string;
  permission: string;
  level: string;
}

@Injectable({
  providedIn: 'root'
})
export class UserTypeService {
  clientPartyId: string;
  public capabilities = new BehaviorSubject < Array < capabilities > > (null);
  capabilities$: Observable<any> = this.capabilities.asObservable();
  // public capabilities : Array<capabilities>;

  constructor(private baseClientService: BaseClientService, private logSvc: RemoteLoggingService) { }

  getUserRoles(): Observable<RoleCapabilitiesRes> {
    return this.baseClientService
      .getOne<any>(`v1/admin/roles-capabilities`)
      .pipe(
        map(r => r.body),
        catchError(err => {
        this.logSvc.logError(err);
        const empty = null;
          return of(empty);
        })
      );
  }

  get userCapabilities() {
    return atob(JSON.parse(sessionStorage.getItem('car-user-cap') as any)) as any;
  }

  set userCapabilities(data: UserRole) {
    sessionStorage.setItem('car-user-cap', btoa(JSON.stringify(data as any)));
  }

  get userType(): UserType {
    return sessionStorage.getItem('car-user-type') as any;
  }

  set userType(userType: UserType) {
    sessionStorage.setItem('car-user-type', userType);
  }

}
