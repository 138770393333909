import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TransfereeRoutingModule } from './transferee-routing.module';
import { DWarningDialogComponent, TransfereeDetailComponent } from './transferee-detail/transferee-detail.component';
import { SensitiveInfoMaskComponent } from './components/sensitive-info-mask/sensitive-info-mask.component';
import {MatCardModule} from '@angular/material/card';
import { FlexModule, FlexLayoutModule } from '@angular/flex-layout';
import { MatExpansionModule } from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import { MatAutocompleteModule, MatButtonModule, MatCheckboxModule, MatChipsModule, MatDatepickerModule, MatDividerModule, MatInputModule, MatNativeDateModule, MatRadioModule, MatSlideToggleModule, MatSortModule, MatTableModule } from '@angular/material';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatSelectModule} from '@angular/material/select';
import { AvatarModule } from './../../../../core/components/avatar/avatar.module';
import { SharedModule } from './../../../../core/shared.module';
import {TWarningDialogComponent} from './transferee-detail/transferee-detail.component'
import { FamilyInfoComponent, WarningDialogComponent } from './components/family-info/family-info.component';
import { AddFamilyInfoDialogComponent } from './components/add-family-info-dialog/add-family-info-dialog.component';
import { GetPreferredPhonePipe } from './pipes/get-preferred-phone.pipe';
import {MatDialogModule} from '@angular/material';
import { ScheduledPolicyCallModalComponent } from './components/scheduled-policy-call-modal/scheduled-policy-call-modal.component';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { UnconfirmBenefitsModalComponent } from './components/unconfirm-benefits-modal/unconfirm-benefits-modal.component';
import { UnlockSubmittedHighValueGoodsModalComponent } from './components/unlock-submitted-high-value-goods-modal/unlock-submitted-high-value-goods-modal.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {SharedTransfereeProgramModule} from './../shared/shared.module';
import { SharedTraditionalOrderModule } from '../../../../shared/sharedTraditionalOrder.module';
import { UpdateDepatureDestinationComponent } from './update-depature-destination/update-depature-destination.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { WorkDetailPopupComponent } from './work-detail-popup/work-detail-popup.component';
import { CustomTitlecasePipe } from './pipes/custom-titlecase.pipe'
import { BookingDetailsPopupComponent } from './booking-details-popup/booking-details-popup/booking-details-popup.component'
import { PointRecalculationModalComponent } from './components/point-recalculation-modal/point-recalculation-modal.component';
import { PointsRecalculationHistoryModalComponent } from './components/points-recalculation-history-modal/points-recalculation-history-modal.component';
import { SendinviteInitialcontactCallModalComponent } from './components/sendinvite-initialcontact-call-modal/sendinvite-initialcontact-call-modal.component';
import { CronofyAvailablilityPickerModule } from '../cronofy-availablility-picker/cronofy-availablility-picker/cronofy-availablility-picker.module';

@NgModule({
  declarations: [
    TransfereeDetailComponent,
    SensitiveInfoMaskComponent,
    FamilyInfoComponent,
    AddFamilyInfoDialogComponent,
    GetPreferredPhonePipe,
    WarningDialogComponent,
    TWarningDialogComponent,
    DWarningDialogComponent,
    UnlockSubmittedHighValueGoodsModalComponent,
    ScheduledPolicyCallModalComponent,
    UnconfirmBenefitsModalComponent,
    UpdateDepatureDestinationComponent,
    WorkDetailPopupComponent,
    BookingDetailsPopupComponent,
    CustomTitlecasePipe,
    PointRecalculationModalComponent,
    PointsRecalculationHistoryModalComponent,
    SendinviteInitialcontactCallModalComponent
  ],
  imports: [
    CommonModule,
    NgxSpinnerModule,
    MatAutocompleteModule,
    MatGoogleMapsAutocompleteModule,
    TransfereeRoutingModule,
    FormsModule,
    MatCardModule,
    SharedModule,
    SharedTransfereeProgramModule,
    FlexModule,
    FlexLayoutModule,
    AvatarModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatMenuModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatIconModule,
    MatRadioModule,
    MatDatepickerModule,
    MatTooltipModule,
    NgxMaterialTimepickerModule,
    MatDialogModule,
    MatChipsModule,
    SharedTraditionalOrderModule,
    MatDividerModule,
    MatTableModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatSlideToggleModule,
    MatTableModule,
    MatSortModule,
    CronofyAvailablilityPickerModule
  ],
  exports: [
    TransfereeDetailComponent
  ],
  entryComponents: [AddFamilyInfoDialogComponent,UpdateDepatureDestinationComponent, WarningDialogComponent, TWarningDialogComponent, ScheduledPolicyCallModalComponent, UnlockSubmittedHighValueGoodsModalComponent,PointRecalculationModalComponent, SendinviteInitialcontactCallModalComponent,
    WorkDetailPopupComponent, BookingDetailsPopupComponent,DWarningDialogComponent, PointsRecalculationHistoryModalComponent ]
})
export class TransfereeModule { }
