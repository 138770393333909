import {
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  OnDestroy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, MatSortHeaderIntl } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AggregationViewService } from '../../../../core/services/aggregation-view.service';
import { UserPreferencesService } from '../../../../core/services/user-preferences.service';
import { UserConfigService } from '../../../../core/services/user-config.service';
import { UserPreference } from '../../../../core/models/user-preference.model';
import { Subscription } from 'rxjs';
import { AggregationView } from '../../../../core/models/aggregation-view.model';
import { AggregationFetchparameter } from '../../../../core/models/aggregation-fetch-parameter.model';
import { AggregationFilter } from '../../../../core/models/aggregation-filter.model';
import { AggregationData } from '../../../../core/models/aggregation-data.model';
import { NumbersService } from '../../../../core/services/numbers.service';
import { CdkDragStart, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
/** Destination Country Aggregation component */
@Component({
  selector: 'app-aggregation-view',
  templateUrl: './aggregation-view.component.html',
  styleUrls: ['./aggregation-view.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AggregationViewComponent implements OnInit, OnDestroy {
  /** aggrgateView variable to store the response data */
  aggregateView: Subscription;

  /** output the setActive method to Dashboard component (parent component) */
  @Output() setActive = new EventEmitter();

  /** aggregatePolicyView variable to store the response data */
  aggregatePolicyView: Subscription;

  dragColumnName: String = '';

  /** columns variable array to store the column fields and headers */
  columns: any[] = [
    { field: 'aggregationName' },
    { field: 'activeEmployees', header: 'Active Employees' },
    { field: 'currentYtdCosts', header: 'Current YTD Costs' },
    { field: 'pastYtdCosts', header: 'Past YTD Costs' },
    { field: 'changeOfTotalCost', header: '%Change of Total Cost' },
    { field: 'moveAtRiskCount', header: 'Move Risk Status' },
    { field: 'moveOnTrackCount', header: 'Move On Risk Status' },
    { field: 'currentYtdCurr', header: 'Current YTD Curr' },
    { field: 'pastYtdCurr', header: 'Past YTD Curr' },
  ];

  /** restore order column mapping */
  orderColumns: any[];

  /** displayedColumns variable array to store the displayed columns */
  displayedColumns: string[] = [];

  /** previousDragIndex to grab index of table column dragged */
  previousDragIndex: number;

  /** aggregationType as input from parent component */
  @Input() aggregationType: string;

  /** aggregationTypeDesc is full aggregate name */
  @Input() aggregationTypeDesc: string;

  /** pageEvent variable for handling page events */
  pageEvent: PageEvent;

  // tslint:disable-next-line: max-line-length
  /** dataSource variable to store the response converted to mat table datasource, declared as type any since sort and paginator is of type MatSort and MatPaginator */
  dataSource: MatTableDataSource<any>;

  /** firstHeaderColumn to bind First coulmn header*/
  firstHeaderColumn: string;

  /** To sort the mat table columns */
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  /** searchKeyWord inputed from the parent dashboard component */
  @Input() searchKeyword;

  /** To paginate in a mat table */
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  /**store the filter value */
  filterText = '';

  /** store preferred currency */
  preferredCurr = '';

  /** initialSortColumn to hold the column's field property for inital sorting. */
  initialSortColumn: Object = 'aggregationName';
  /** InitialSortDirection to hold the direction of inital sorting: 'asc' | 'desc' */
  initialSortDirection: Object = 'desc';

  /** Base constructor method
   * @param aggregationViewService AggregationViewService injection
   */
  constructor(
    private readonly aggregationViewService: AggregationViewService,
    private matSortService: MatSortHeaderIntl,
    private readonly userPreferenceService: UserPreferencesService,
    private readonly userConfigService: UserConfigService,
    private readonly numberService: NumbersService
  ) {
    this.pageEvent = new PageEvent();
  }

  /** Init method to make Service call, to convert response to mat table data and to initialize sort, pagination */
  ngOnInit() {
    const paginatorIntl = this.paginator._intl;
    paginatorIntl.nextPageLabel = 'Next';
    paginatorIntl.previousPageLabel = 'Previous';
    this.setConfiguration();
    const setEmployeeData: AggregationFetchparameter = this.setEmployeeDataFetchParam();
    this.filterAggregationDetails(setEmployeeData);
  }

  /** setEmployeeDataFetchParam method to initialize object */
  setEmployeeDataFetchParam() {
    this.pageEvent.pageIndex = 0;
    this.paginator.pageSize = 25;
    const ret: AggregationFetchparameter = {
      itemsPerPage: 25,
      pageNumber: 0,
    };
    return ret;
  }
  /** filter aggregation method to get response and to apply filter to the variables */
  filterAggregationDetails(
    aggregationFetchParameter: AggregationFetchparameter
  ) {
    aggregationFetchParameter.pageNumber =
      aggregationFetchParameter.pageNumber + 1;
    aggregationFetchParameter.itemsPerPage = 100000; // Sane limit
    aggregationFetchParameter.preferredCurr = this.preferredCurr;
    if (this.aggregationType === 'policyName') {
      this.firstHeaderColumn = 'Policy Names';
      this.aggregatePolicyView = this.aggregationViewService
        .getAggregateView(this.aggregationType, aggregationFetchParameter)
        .subscribe((res: AggregationView) => {
          this.aggregateFilter(res);
        });
    } else if (this.aggregationType === 'divisionName') {
      this.firstHeaderColumn = 'Division';
      this.aggregateView = this.aggregationViewService
        .getAggregateView(this.aggregationType, aggregationFetchParameter)
        .subscribe((res: AggregationView) => {
          this.aggregateFilter(res);
        });
    } else {
      this.firstHeaderColumn = 'Country';
      this.aggregateView = this.aggregationViewService
        .getAggregateView(this.aggregationType, aggregationFetchParameter)
        .subscribe((res: AggregationView) => {
          this.aggregateFilter(res);
        });
    }
    this.matSortService.sortButtonLabel = (id: string) => {
      if (id === 'aggregationName') {
        id = this.firstHeaderColumn;
      }
      return 'Change sorting for ' + id;
    };
  }

  /** aggregateFilter method to reuse codes for both location and policy options */
  aggregateFilter(res: AggregationView) {
    if (res) {
      if (res.data && res.data.length > 0) {
        res.data.forEach((employee) => {
          if (
            employee.pastYtdCosts &&
            employee.pastYtdCurr &&
            employee.pastYtdCosts !== null
          ) {
            employee.formattedPastYTD = this.numberService.formatForCurrency(
              employee.pastYtdCosts,
              employee.pastYtdCurr
            );
          } else {
            employee.formattedPastYTD = this.formatAmount(
              employee.pastYtdCosts
            );
          }
          if (
            employee.currentYtdCosts &&
            employee.currentYtdCurr &&
            employee.currentYtdCosts !== null
          ) {
            employee.formattedCurrentYTD = this.numberService.formatForCurrency(
              employee.currentYtdCosts,
              employee.currentYtdCurr
            );
          } else {
            employee.formattedCurrentYTD = this.formatAmount(
              employee.currentYtdCosts
            );
          }
          if (
            employee.changeOfTotalCost === null ||
            employee.changeOfTotalCost === undefined
          ) {
            employee.changeOfTotalCost = this.formatAmount(
              employee.changeOfTotalCost
            );
          }
        });
      }
      this.dataSource = new MatTableDataSource<AggregationData>(res.data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'changeOfTotalCost': {
            return item['changeOfTotalCost'] !== 'N/A'
              ? item['changeOfTotalCost']
              : Number.MIN_SAFE_INTEGER;
          }
          default: {
            return item[property];
          }
        }
      };
      this.dataSource.sort = this.sort;
      this.sort.disableClear = true; // Disable unsorted
      setTimeout(() => {
        this.paginator.length = res.count;
        this.paginator.pageIndex = this.pageEvent.pageIndex;
        this.paginator.pageSize = this.pageEvent.pageSize;
      });
      this.dataSource.filterPredicate = function (data: AggregationData, filter: string): boolean {
        return data.aggregationName.toLowerCase().includes(filter) ||
          data.activeEmployees.toString().includes(filter) ||
          data.currentYtdCosts.toString().includes(filter) ||
          data.currentYtdCurr.toLowerCase().includes(filter) ||
          data.pastYtdCosts.toString().includes(filter) ||
          data.pastYtdCurr.toLowerCase().includes(filter) ||
          data.changeOfTotalCost.toString().includes(filter);
      };
    }
  }
  /** applyFilter to filter data within the employee details table */
  applyFilter(filterValue: string) {
    this.filterText = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  formatAmount(value) {
    if (value === '' || value === null || value === undefined) {
      return 'N/A';
    }
    return value;
  }

  /** To Destroy the subscribed method */
  ngOnDestroy(): void {
    if (this.aggregateView) {
      this.aggregateView.unsubscribe();
    }
    if (this.aggregatePolicyView) {
      this.aggregatePolicyView.unsubscribe();
    }
  }

  /** moveRiskData method to return moveRisk object with viewType property */
  moveRiskData(
    moveAtRiskCount: number,
    moveOnTrackCount: number,
    moveRiskCompleteCount: number,
    moveRiskNACount: number
  ): any {
    return {
      viewType: 'aggregate',
      moveAtRiskCount: moveAtRiskCount,
      moveOnTrackCount: moveOnTrackCount,
      moveRiskCompleteCount: moveRiskCompleteCount,
      moveRiskNACount: moveRiskNACount,
    };
  }

  /** Rearranges columns array and calls the setDisplayedColumnscolumns function */
  dragDrop(event: CdkDropList<string[]>, index: number) {
    moveItemInArray(this.orderColumns, this.previousDragIndex, index);
    this.dragColumnName = '';
    this.setDisplayedColumns();
  }

  /** Captures previous index of dragged item */
  dragStarted(event: CdkDragStart, index: number) {
    const colName = event.source.element.nativeElement.innerText;
    const column = this.columns.find( col => col.header === colName );
    this.dragColumnName = column.sortName;
    this.previousDragIndex = index;
  }
  /** Rebuilds the displayedColumns array using the columns array order */
  setDisplayedColumns() {
    const orderCol: string[] = [];
    this.orderColumns.forEach((column, index) => {
      column.index = index;
      if (
        column.field !== 'currentYtdCurr' &&
        column.field !== 'pastYtdCurr' &&
        column.field !== 'moveOnTrackCount'
      ) {
        orderCol.push(column.field);
      }
    });
    this.updateOrderedColumn(orderCol);
  }

  /** to set user preference configuration for employee list */
  setConfiguration() {
    let orderColumn: string[] = [];
    this.displayedColumns = [];
    this.userPreferenceService
      .getPreference('user_config', false)
      .subscribe((response) => {
        if (response) {
          response.forEach((item) => {
            if (item.preferenceconfig.name == 'AggregatedViewColumnOrder') {
              if (
                'preferencesetting' in item &&
                item.preferencesetting.value.length > 0
              ) {
                orderColumn = item.preferencesetting.value;
              } else {
                orderColumn = item.preferenceconfig.default;
              }
            }
            
            if (item.preferenceconfig.name == "preferredCurrency") {
              if (
                'preferencesetting' in item &&
                item.preferencesetting.value
              ) {
              item.preferencesetting.value ? this.preferredCurr = item.preferencesetting.value : null;
              } 
            }  
            });
          this.orderColumns = [];
          orderColumn.forEach((item) => {
            if (
              item !== 'currentYtdCurr' &&
              item !== 'pastYtdCurr' &&
              item !== 'moveOnTrackCount'
            ) {
              const column = this.columns.find((col) => col.field == item);
              this.orderColumns.push({ field: item, header: column.header });
              this.displayedColumns.push(item);
            }
          });
        }
      });
  }

  updateOrderedColumn(displayCol) {
    const reqObj = {
      name: 'AggregatedViewColumnOrder',
      value: displayCol,
    };
    this.displayedColumns = displayCol;
    this.userConfigService.updatePreference(reqObj).subscribe((userConfig) => {
      if (userConfig) {
        const preference: UserPreference = {
          Key: 'user_config',
          Value: userConfig,
          Persist: false,
        };
        this.userPreferenceService.setPreference(preference);
        this.setConfiguration();
      }
    });
  }

  /** To Clear the initalSort variables */
  clearInitialSort(): void {
    this.initialSortColumn = null;
    this.initialSortDirection = null;
  }
}
