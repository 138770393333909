import { NgModule } from '@angular/core';
// import { NumberOnlyDirective } from './directives/number-only.directive';
import { MatIconModule } from '@angular/material';

@NgModule({
    // declarations: [ NumberOnlyDirective ],
    imports: [ MatIconModule ], 
    // exports: [ NumberOnlyDirective ]
  })
  
  export class SharedModule {}