import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Client } from '../../../core/models/client.model';
import { UserSessionService } from '../../../core/services/user-session.service';
import { CustomClientModalComponent } from '../custom-client-modal/custom-client-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserTypeService } from '../../administration/services/user-type.service';
import { Subscription } from 'rxjs';

/** client component to display the list of clients for a account manager */
@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ClientsComponent implements OnInit, OnDestroy {
  /** To paginate in a mat table */
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  /** To sort the mat table columns */
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  /** clientList value passed from the parent component*/
  @Input() clientList: Client[];
  /** seletcedClient value passed to the parent component*/
  @Output() selectedClient = new EventEmitter();
  @Output() onActionBtnSelect = new EventEmitter();
  /** showLogout to pass the showLogout to parent component*/
  @Output() showLogout: EventEmitter<any> = new EventEmitter();
  /** displayed coulms to display the columns in the mat table */
  displayedColumns: string[] = ['clientName', 'clientNo', 'action'];
  /**dropdown display for view */
  viewListValues = [];
  /** dataSource variable to store the response converted to mat table datasource */
  dataSource: MatTableDataSource<Client>;
  /** searchValue to pass the searched value on the icon click method */
  searchValue: string;
  /**store the filter value */
  filterText = '';

  showError = false;

  isSearchCleared = true;

  customDataList: number;

  @Input() userType;

  manageCustomDataCapability: string;
  capabilitySub: Subscription;

  /** Base constructor method
   * @param userSessionService UserSessionService injection
   */
  constructor(private userSessionService: UserSessionService,
    public dialog: MatDialog,
    private userTypeService: UserTypeService
    ) { }

  /** Init method to call the methods on page load */
  ngOnInit() {
    this.getCapabilities();

    if (this.clientList) {
      this.setCustomDatacount();
      this.getDataSource();
    }
    setTimeout(() => {
      this.showLogout.emit(true);
    });
  }

  /** Method to get capabilities */
  getCapabilities() {
    this.capabilitySub = this.userTypeService.capabilities$.subscribe(ele => {
      if (ele) {
        ele.forEach(element => {
          if(element.name == "Manage Custom Data Elements" && element.permission == "allow") {
            element.operation == 'read' ? this.manageCustomDataCapability = 'read' :
            element.operation == 'write' ? this.manageCustomDataCapability = 'write' : null;
          }
          else if (element.name === 'Client Details Administration' && element.operation == 'write' && element.permission == "allow") {
            this.viewListValues.push('Configure Settings')
          }
        });
      }
    });
    
    if(this.manageCustomDataCapability === 'write' || this.manageCustomDataCapability === 'read') {
      this.displayedColumns.splice(1,0,'customData')
    }
  }

  /** adding customDataCount byfilter count by logic 
   * activationDate < today's date and expirationDate > today's date */
  setCustomDatacount() {
    const todayDate = new Date(new Date().toDateString());
    this.clientList.forEach(data => {
      let count = 0;
      if (data.configurableFieldsList) {
        data.configurableFieldsList.forEach(customData => {
          if (customData.expirationDate) {
            if (new Date(new Date(customData.activationDate).toDateString()) <= todayDate && new Date(new Date(customData.expirationDate).toDateString()) > todayDate) {
              count++;
            }
          } else {
            if (new Date(new Date(customData.activationDate).toDateString()) <= todayDate) {
              count++;
            }
          }
        });
        data["customDataCount"] = count;
      }
      count = 0;
    });
  }

  /** getDataSource method to load the table data source, paginator and sort */
  getDataSource() {
    this.dataSource = new MatTableDataSource<Client>(this.clientList);
    this.dataSource.paginator = this.paginator;
    this.sort.disableClear = true; // Disable unsorted
    this.dataSource.sortingDataAccessor = (data, attribute) => data[attribute];
    const sortState: Sort = { active: 'clientName', direction: 'asc' };
    this.sort.active = sortState.active;
    this.sort.direction = sortState.direction;
    this.dataSource.sort = this.sort;
  }

  /** Get the client selected from client list grid */
  selectClient(client) {
    sessionStorage.setItem('clientNo', client.clientNo);
    sessionStorage.setItem('clientName', client.clientName);
    this.selectedClient.emit(client);
    this.showLogout.emit(false);
  }

  onCall(element) {
    if (this.userType === 'client-account-manager' || this.userType === 'application-reliability-analyst') {
      const dialogRef = this.dialog.open(
        CustomClientModalComponent,
        {
          data: element, autoFocus: false, height: '80vh', width: '800px', disableClose: true
        });
      dialogRef.afterClosed().subscribe((data) => {
        if (data) {
          this.clientList[this.clientList.findIndex(ele => ele.clientPartyId === data.clientPartyId)].configurableFieldsList = data.configurableFieldsList
          this.setCustomDatacount();
        }
      });

    }
  }

  /** applySearch method to get the filtered results based on entered value */
  applySearch(filterValue: string) {
    filterValue = filterValue.trim();
    this.searchValue = filterValue;
    if (filterValue.length > 2 || (!this.isSearchCleared && filterValue === '')) {
      if (filterValue.length > 2) {
        this.isSearchCleared = false;
      } else {
        this.isSearchCleared = true;
      }
      this.showError = false;
      this.filterText = filterValue.trim();
      filterValue = filterValue.toLowerCase();
      this.dataSource.filter = filterValue;
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    } else {
      this.showError = true;
      this.getDataSource();
    }
  }

  toggleError() {
    this.showError = false;
  }

  /** clear method to clear the search results entered */
  clearSearchTextResults() {
    this.searchValue = '';
    this.filterText = '';
    this.getDataSource();
  }

  /**navigate to client screen on click of action view dropdown*/
  getToClientScreen(type, client) {
    sessionStorage.setItem('clientNo', client.clientNo);
    sessionStorage.setItem('clientName', client.clientName);
    this.onActionBtnSelect.emit(client);
  }
  ngOnDestroy(): void {
    this.capabilitySub ? this.capabilitySub.unsubscribe() : null;
  }
}
