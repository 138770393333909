import { EventEmitter, Injectable } from '@angular/core';
import { BaseClientService } from './../../../core/services/base-client.service';
import { map, catchError } from 'rxjs/operators';
import { of, Observable, BehaviorSubject } from 'rxjs';
import { RemoteLoggingService } from './../../../core/services/remote-logging.service';
import { HttpParams } from '@angular/common/http';

export interface GetClientOptions {
  division?: boolean;
  sortBy ?: string;
  sortDirection ?: string;
  searchText ?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ClientService {
  clientList = new BehaviorSubject<Array<any>>(null);

  //Subject for tab index
  tabIndex = new BehaviorSubject(null);
  tabIndex$ = this.tabIndex.asObservable();
  bbRole: Array<String> = [];
  ssoRegEx: any = {};
  constructor(private baseClientService: BaseClientService, private logSvc: RemoteLoggingService) { }

  getClients(options: GetClientOptions = null): Observable<{ clients: Array<any>, count: number }> {

    let httpParams: HttpParams = new HttpParams();

    // localhost:4100/v1/admin/client?sortDirection=ASC&sortBy=location&searchText=searchText
    if (options) {
      if (options.sortBy) { httpParams = httpParams.append('sortBy', options.sortBy); }
      if (options.sortDirection) { httpParams = httpParams.append('sortDirection', options.sortDirection); }
      if (options.searchText) { httpParams = httpParams.append('searchText', options.searchText); }
    }

    return this.baseClientService
      .getOne<any>(`${ options ? 'v1/admin/client?' + httpParams.toString() : 'v1/admin/client' }`)
      .pipe(
        map(r => r.body),
        catchError(err => {
        this.logSvc.logError(err);
        const empty = null;
          return of(empty);
        })
      );
  }

  getBbRole(clientPartyId: string): Observable<any> {
    return this.baseClientService
    .getOne<any>(`v1/admin/roles-capabilities?partyId=${clientPartyId}`)
    .pipe(
      map(r => r.body),
      catchError(err => {
        this.logSvc.logError(err);
        const empty = null;
          return of(empty);
      })
    );
  }
}
