import { Component, OnInit, Inject, Injector, ViewChild, Output, NgZone, ElementRef, ViewContainerRef, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl, ControlContainer, FormControl, ValidationErrors, FormArray, FormControlName, ValidatorFn, Form } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatAutocompleteTrigger } from '@angular/material';
import { Subscription, Observable } from 'rxjs';
import { debounceTime, isEmpty } from 'rxjs/operators';
import { AdminClientContacts } from '../../../../../core/models/admin-client-contacts.model';
import { phoneDetails, phoneTypes } from '../../../../../core/models/phone.model';
import { emailDetails, emailTypes } from '../../../../../core/models/email.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import {map, startWith} from 'rxjs/operators';
import PlaceResult = google.maps.places.PlaceResult;
import * as moment from 'moment';
import { Country } from '../../../../../core/models/country.model';
import { AdditionalDetail, candidateProfile } from '../../../../../core/models/candidate.model';
import { UserType, UserTypeService } from '../../../services/user-type.service';
import { ConfigurableField, ConfigurableFieldService, fieldMapping } from '../../../services/configurable-field.service';
import { LocationsService } from '../../../services/locations.service';
import { ClientContactsService } from '../../../services/client-contacts.service';
import { UserContextService } from '../../../../../core/services/user-context.service';
import { WarningDialogComponent } from '../../add-edit-transferee/add-edit-transferee.component';
import { CandidateProfileService } from '../../../services/candidate-profile.service';
import { ProgramManagementService } from '../../../services/program-management.service';
import { AddEditSharedService } from '../../../services/add-edit-shared.service';
import { ClientService } from '../../../services/client.service';
import { shortAddress } from '../../../../../core/models/address.model';
import { Locations } from '../../../../../core/models/locations.model';
import { Router } from '@angular/router';
/** response for sendInviteToCandidate service call */
export const sendInviteResponse = `Authorization Initiated Successfully`;
type ResetStates = { departureStateList: Array<any>, destinationStateList: Array<any> };
type updatedKeyInterface = { group: 'departureAddr' | 'destinationAddr', type: 'country' | 'city' | 'state' | 'streetLine1' | 'streets' | 'cities' | 'states' };

@Component({
  selector: 'app-error-review',
  templateUrl: './error-review.component.html',
  styleUrls: ['./error-review.component.scss']
})
export class ErrorReviewComponent implements OnInit {
  /**Form group name */
  addCandidateForm: FormGroup;
  /**Countries retrieved from service */
  countryList: Array<Country>;
  countryNameList: any[];
  /**Currency retrieved from service */
  currencyList: Array<Country> = [];
  /**Subscription to observe location form */
  locationFormObserver: Subscription;
  /**to disable close button when snackbar is opened */
  // formDisable = false;
  /** Title of form being displayed */
  title: string;
  /** User name title values */
  titleValues: string[] = ['Dr.', 'Mr.', 'Mrs.', 'Ms.', 'Miss'];
  /** For job start date manipulation */
  jobStartDate: any;
  /** List of cities to be populated in departure city list */
  departureCityList: Array<any> = [];
  /** List of cities to be populated in destination city list */
  destinationCityList: Array<any> = [];
  /** List of cities to be populated in departure state list */
  departureStateList: Array<any> = [];
  /** List of cities to be populated in destination state list */
  destinationStateList: Array<any> = [];
  /** Stores value in address form */
  addressFormValue: any;
  /** Country code of selected departure country */
  departureCountryCode: string;
  /** Country code of selected destination country */
  destinationCountryCode: string;
  /** To set departure country to read only when country is empty */
  hasDepartureCountry: boolean;
  /** To set destination country to read only when country is empty */
  hasDestinationCountry: boolean;
  /** Object to store candidate profile details */
  candidateProfile: candidateProfile = {} as candidateProfile;

  dismissWarningMsg: string = 'Dismissing will remove this transferee record ?';
  editCandidateProfile: candidateProfile = {} as candidateProfile;
  /** to distinguish transferee as traditional or benefit builder */
  isTraditional: boolean= false;
  /** Stored initial form value during load to check for changes */
  initialFormValue: any = {} as any;
  /** Store Division array  */
  divisionValues: any[];
  /** store policy array */
  policyValues: any[];
  /** store empoyee type */
  employeeTypeValue: any[];
  /** store executiveOfficer value */
  executiveOfficerVal: string;
  /** Store program array  */
  programValues: any[];
  /**edit point flag */
  editPoint: boolean;
  /**points */
  points: number;
  /**amount for MMUser*/
  amount: number;
  /**budgetCurrency for MMUser*/
  budgetCurrency: string;
  hasDepartureStreet: boolean;
  hasDepartureCity: boolean;
  hasDepartureState: boolean;
  hasDestinationStreet: boolean;
  hasDestinationCity: boolean;
  hasDestinationState: boolean;
  departureCountrycodes = [];
  departCountryCode = '';
  destiCountryCode = '';
  userRole: UserType;
  transferee: any;
  filteredOptions: Observable<Country[]>;
  filteredCountryOptions: Observable<Country[]>;
  filteredDestCountryOptions: Observable<Country[]>;
  filteredClientContactDetails: Observable<any[]>;
  filteredCurrency: Observable<Country[]>;
  filteredCurrencyCode: Observable<Country[]>;
  filteredDefaultHostCountry: Observable<Country[]>;
  filteredDefaultHomeCountry: Observable<Country[]>;
  filteredSingleSelect: any [] = [];

  isNewHire: boolean = true;
  showSSOApiErr: any;
  client: any;

  /**errors */
  errors: any = [];

  @ViewChild('autoCompleteCountryInput', { read: MatAutocompleteTrigger, static: true }) autoCompleteCountry: MatAutocompleteTrigger;
  @ViewChild('autoCompleteInput', { read: MatAutocompleteTrigger, static: true }) autoComplete: MatAutocompleteTrigger;
  @ViewChild('autoCompleteDestCountryInput', { read: MatAutocompleteTrigger, static: true }) autoCompleteDestCountry: MatAutocompleteTrigger;
  @ViewChild('autoCompleteCci', { read: MatAutocompleteTrigger, static: true }) autoCompleteCci: MatAutocompleteTrigger;
  @ViewChild('departStreet', {static: false})
  public departStreetElementRef: ElementRef;
  cciDetails: any[];
  currency: any[];
  /**client party id for the corrosponding transferee */
  clientPartyId: string;
  /**configurable fields for the transferee */
  configurableFields: ConfigurableField[];
  multipleOriginalDropDowns: any = [];
  additionalDetailsFormGroup:FormGroup;
  /**File Priority values for traditional transferee */
  filePriorityValues = ['Exec VIP', 'Normal', 'Premier Executive', 'Rush', 'VIP'];
  isUS_SelectedCheck: boolean = false; // to check either of depart/dest country is US
  isUS_Move: boolean = false; // to check for job start date of traditional transferee(both should be US)
  /**Delivery Team Model for traditional transferee fsu flow */
  deliveryTeamModelValues: any[];
  timeZoneValues: any[];
  initialContactByValues: any[];
  contractNameValues: any[];
  contractOriginalList: any[];
  contractList: any[]
  moveTypeValues;
  billiingCurrency: any[];
  /** List of phone Types for selection */
  phoneTypes: Array<Array<string>> = [
    [...phoneTypes]
  ];
  /** List of email Types for selection */
  emailTypes: Array<Array<string>> = [
    [...emailTypes]
  ];
  ssoEmailErrorMsg: string = 'An SSO user should have minimum of one Business Email associated';
  disableSaveDraftForSSO: boolean = false;
  /** List of relationships to be populated */
  relationshipValues = ['Civil Partner', 'Fiancee', 'Partner', 'Significant Other', 'Spouse', 'Child', 'Dependent',
    'Grandparent', 'Parent', 'Sibling'
  ];
  age: number[];
  familyDelete: number = 0;
  userTransfereeSSOCapabilities: string;
  /** Stores Resend capability */
  resendInviteCapability: string;
  capabilitySubscription: Subscription;
  data: any;
  submissionFailureData: any;
  /**
   * Initializes form elements
   * @param formBuilder - property for form elements
   * @param locationService - location service
   * @param dialogRef - instance of dialog
   * @param data - data from list of transferees
   * @param spinner - inject spinner
   * @param injector - inject injector
   * @param candidateProfileService - inject service to add candidate details
   * @param dialog - dialog to be opened on dismiss
   * @param toastrService - inject toastrService
   *
   */
  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly locationService: LocationsService,
    private readonly clientContactsService: ClientContactsService,
    private userContextService: UserContextService,
    public dialogRef: MatDialogRef<ErrorReviewComponent>,
    private readonly spinner: NgxSpinnerService,
    private injector: Injector,
    private candidateProfileService: CandidateProfileService,
    private programManagementService: ProgramManagementService,
    public dialog: MatDialog,
    private readonly toastrService: ToastrService,
    private userTypeService: UserTypeService,
    public live: LiveAnnouncer,
    private configurableFieldService : ConfigurableFieldService,
    private addEditSharedService: AddEditSharedService,
    private clientService: ClientService,
    public readonly router: Router,
  ) {
    dialogRef.disableClose = true;
  }


  ngOnInit() {
    this.candidateProfileService.submissionFailureListSubject$.subscribe(data => {
      if(data) {
        this.data = data;
        this.submissionFailureData = JSON.parse(JSON.stringify(this.data))
      }
    });
    this.client = this.data.client;
    this.spinner.show();
    this.userRole = this.userTypeService.userType;
    this.editCandidateProfile = this.data.candidate;
    this.isTraditional = this.data.isTraditional;
    this.isNewHire = (this.data.candidate.ssoIdentifier || this.data.hasOwnProperty('associateTransferee')) ? false: true;
    this.editPoint = false;
    this.addCandidateForm = this.populateForm(this.editCandidateProfile);
    this.addCandidateForm.markAllAsTouched();
    this.addCandidateForm.setValidators(this.checkSSOValidation());
    if (this.addCandidateForm.get('email')) {
      const {
        emailTypes
      } = this.addEditSharedService.
        initEmailList(
          (this.addCandidateForm.controls.email as FormGroup).value
          , this.emailTypes);
      this.emailTypes = emailTypes;
    }
    if (this.addCandidateForm.get('phone')) {
      const {
        phoneTypes
      } = this.addEditSharedService.
        initPhoneList(
          (this.addCandidateForm.controls.phone as FormGroup).value
          , this.phoneTypes)
      this.phoneTypes = phoneTypes;
    }
    if( this.userRole === 'client-contact-initiator') {
      this.candidateProfileService.getTradOptionalValues('EmployeeType').subscribe(res => {
        this.employeeTypeValue = res.values;
      });
    }
    if( this.userRole === 'file-setup-specialist') {
      this.candidateProfileService.getTradOptionalValues('EmployeeType&key=InitialContactBy&key=TraditionalAuthTimeZone&key=DeliveryTeamModel').subscribe(res => {
        if(res) {
          if(res.EmployeeType){ this.employeeTypeValue = res.EmployeeType.values; }
          if(res.InitialContactBy) { this.initialContactByValues = res.InitialContactBy.values; }
          if(res.TraditionalAuthTimeZone) { this.timeZoneValues = res.TraditionalAuthTimeZone.values; }
          if(res.DeliveryTeamModel) { this.deliveryTeamModelValues = res.DeliveryTeamModel.values }
        }
      })
    }
    
    this.locationSearchInit();
    this.initialFormValue = this.addCandidateForm.value; // set initial value
    this.locationService.countryList.subscribe(countryList => {
      if (countryList.length > 0) {
        countryList.sort((a, b) => a.countryName.localeCompare(b.countryName));
        this.countryList = countryList;
        const currList = [];
        countryList.forEach((curr)=> {
          curr.currency ? currList.push(curr): null;
        });
        currList.sort((a, b) =>  a.currency &&  b.currency ? a.currency.code.localeCompare(b.currency.code) : null);
        this.currencyList = currList.filter((e, i) => currList.findIndex(a => a.currency && e.currency ? a.currency.code === e.currency.code : null) === i);
        this.editCandidateProfile && this.editCandidateProfile.authorizationDetails && this.editCandidateProfile.authorizationDetails.currency?
        this.addCandidateForm.get('authorizationDetails.currency').setValue(this.editCandidateProfile.authorizationDetails.currency):{};
        this.phoneCodeValueChange();
        this.filteredCountryOptions = this.addCandidateForm.get('addressForm.departureAddr.country').valueChanges
        .pipe(
        startWith(''),
        map(value => this._filter(value))
        );
        this.filteredDestCountryOptions = this.addCandidateForm.get('addressForm.destinationAddr.country').valueChanges
        .pipe(
        startWith(''),
        map(value => this._filter(value))
        );
        this.filteredDefaultHomeCountry = this.addCandidateForm.get('optionalDetailsTraditionals.defaultHomeCountry').valueChanges
            .pipe(
              startWith(''),
              map(value => this._filter(value))
            );
        this.filteredDefaultHostCountry = this.addCandidateForm.get('optionalDetailsTraditionals.defaultHostCountry').valueChanges
            .pipe(
              startWith(''),
              map(value => this._filter(value))
            );
        if (this.addCandidateForm.value.addressForm.departureAddr.country) {
          this.departureCountryCode = this.countryList.find(country => (this.addCandidateForm.value.addressForm.departureAddr.country).
          includes(country.countryName)).countryiso2CharCode;
          this.pushToDepartureCountryCodes(this.departureCountryCode, this.editCandidateProfile);
        }
        if (this.initialFormValue.addressForm.destinationAddr.country) {
          this.destinationCountryCode = this.countryList.find(country => (this.initialFormValue.addressForm.destinationAddr.country).
          includes(country.countryName)).countryiso2CharCode;
          this.pushToDestinationCountryCodes(this.destinationCountryCode, this.editCandidateProfile);
        }
      }
    });
    this.filteredClientContactDetails = this.addCandidateForm.get('authorizationDetails.cciDetails').valueChanges
        .pipe(
        startWith(''),
        map(value => this.clientContact_filter(value))
        );
    this.addressFormValue = this.addCandidateForm.value['addressForm'];
    if (this.data.candidate && this.userRole !== 'client-contact-initiator' && this.userRole !== 'file-setup-specialist') {
      this.candidateProfileService.getTransferee(this.data.candidate.orderRequestId).subscribe((res) => {
        // this.transferee = res;
        this.divisionValues = [{
          'cc_division_id': this.data.candidate.divisionPartyId,
          'cc_division_name': res.candidateDetails.divisionName,
          'cc_division_number': res.candidateDetails.divisionNumber
        }];
      });
      if (this.data.candidate.programDetails) {
        this.programValues = [{
          'programName': this.data.candidate.programDetails.programName
        }];
      }
      if (this.data.candidate.assignedPolicy) {
        this.policyValues = [this.data.candidate.assignedPolicy];
      }
      if (this.addCandidateForm.get('optionalDetailsTraditionals.employeeType').value) {
        this.employeeTypeValue = [this.addCandidateForm.get('optionalDetailsTraditionals.employeeType').value];
      }
    } else {
      if (this.userRole === 'file-setup-specialist') {
        if (this.isTraditional) {
          this.candidateProfileService.getContractList(this.client.clientId).subscribe(res => {
            if (res) {
              this.contractListProcess(res);
            }
          });
        }
        this.programManagementService.getClientDivisions(this.client.clientId).subscribe(res => {
          this.divisionValues = res.divisions;
        });
      } else {
        this.candidateProfileService.getDivision('')
          .subscribe(res => {
            this.divisionValues = res.cc_divisions;
          });
      }
      this.programManagementService.getClientPolicies(this.client.clientId).subscribe(res => {
        this.policyValues = res.policies;
      })
    }

    if (this.editCandidateProfile && this.editCandidateProfile.additionalDetails && this.editCandidateProfile.additionalDetails.length > 0 ) {

      this.editCandidateProfile.additionalDetails.forEach((ele) => {
        if (ele.type === "Date" && ele.value) {
          ele.value = this.setDateWithOffset(ele.value);
        }
      });
      this.configurableFields = this.editCandidateProfile.additionalDetails;
      this.genereateConfigurableFieldsForm();
      if (['Invitation Sent','Authorization Initiated'].includes(this.editCandidateProfile.moveStatus)) {
        Object.keys(this.additionalDetailsFormGroup.controls).forEach(group => this.additionalDetailsFormGroup.controls[group].disable());
      }

    } else if (this.editCandidateProfile.additionalDetails === undefined) {

      this.configurableFieldService.getConfigurableFields(this.client.clientId, false, false).subscribe(data => {
        if (data && data.length > 0 ) {
          this.configurableFields = data;
          this.genereateConfigurableFieldsForm();
        }
      });
    }

  }
  
  contractListProcess(res){
    this.contractOriginalList = [...res];
    this.contractList = [...res];
    res.forEach((ele, index) => {
      ele['contractDetails'] = {
        'baseContract': ele.baseContract,
        'contractId': ele.contractId,
        'contractName': ele.contractDescription,
      }
      this.contractOriginalList[index] = ele;
      this.contractList[index] = { 'contractDetails': ele.contractDetails };
    });
    if (this.editCandidateProfile && this.editCandidateProfile.authorizationDetails && this.editCandidateProfile.authorizationDetails.contractDetails && this.editCandidateProfile.authorizationDetails.contractDetails.contractName) {
      const contractNameValue = this.addCandidateForm.get('authorizationDetails.contractDetails').value.contractName;
      let data = this.contractOriginalList.find(ele => ele.contractDescription === contractNameValue);
      data && data.hasOwnProperty('moveTypes') ? this.moveTypeValues = data.moveTypes : this.moveTypeValues = [];
      data && data.hasOwnProperty('billingCurrencies') ? this.billiingCurrency = data.billingCurrencies : this.billiingCurrency = [];
      if(this.billiingCurrency && this.countryList) {
        this.billiingCurrency.forEach((billCur, index) => {
          let currencyValue = this.currencyList.find(cur => cur && cur.currency && cur.currency.code === billCur);
          this.billiingCurrency[index] = currencyValue;
        })
        this.billingCurrencyValueChange();
      }
    }
  }

  genereateConfigurableFieldsForm() {
    let group = {}
    this.additionalDetailsFormGroup = this.formBuilder.group({
      configField: this.formBuilder.array([])
    })
    this.configurableFields.forEach(input_template => {
      if ((input_template.valueList && input_template.type !== 'Multiple Select') || input_template.type === 'Country' ) {
        group[input_template.displayName] = new FormControl('', [Validators.required, this.depValueListMatch.bind(this,input_template.displayName)]);
      }
      else if(input_template.type === 'Currency'){
        group[input_template.displayName] =  new FormGroup({
          currencyCode: new FormControl('', [Validators.required]),
          currencyAmount: new FormControl('', [Validators.required, Validators.max(9999999999999999)]),
        });

        this.locationService.countryList.subscribe(countryList => {
          let observable: any;
          if(countryList.length > 0) {
            const currList = [];
            countryList.forEach((curr) => {
              curr.currency? currList.push(curr): null;
            });
            currList.sort((a, b) =>  a.currency &&  b.currency ? a.currency.code.localeCompare(b.currency.code) : null);
            this.currencyList = currList.filter((e, i) => currList.findIndex(a => a.currency && e.currency ? a.currency.code === e.currency.code : null) === i);
            observable = group[input_template.displayName].get('currencyCode').valueChanges
            .pipe(
              startWith(''),
              map(value => this.singleSelect_filter(value,this.currencyList.map(ele => ele.currency.code)))
            );
            this.filteredSingleSelect.push({ [input_template.displayName]: observable });
          }
        });
      }
      else {
      group[input_template.displayName] = new FormControl('', [Validators.required]);
      }
      this.additionalDetailsFormGroup = new FormGroup(group);
      if (input_template.type === 'Currency' && input_template.value) {
        this.additionalDetailsFormGroup.get([input_template.displayName, 'currencyCode']).setValue(input_template.value.currencyCode);
        this.additionalDetailsFormGroup.get([input_template.displayName, 'currencyAmount']).setValue(input_template.value.currencyAmount);
      } else
      if (input_template.value) {
        this.additionalDetailsFormGroup.get(input_template.displayName).setValue(input_template.value);
      } else {
        input_template['value'] = fieldMapping.find(ele => ele.DataItemTypDescr === input_template.type).defaultValue;
        this.additionalDetailsFormGroup.get(input_template.displayName).setValue(input_template.value);
      }

      if (['Country', 'Single Select', 'Multiple Select'].includes(input_template.type)) {
        let observable: any;
        if (input_template.type === 'Country') {
          if (!(this.countryList && this.countryList.length)) {
            this.locationService.countryList.subscribe(countryList => {
              if (countryList.length > 0) {
                countryList.sort((a, b) => a.countryName.localeCompare(b.countryName));
                this.countryNameList = countryList.map(ele=>ele.countryName);
              }
            });
          } else {
            this.countryNameList = this.countryList.map(ele=>ele.countryName);
          }

          observable = this.additionalDetailsFormGroup.get(input_template.displayName).valueChanges
          .pipe(
            startWith(''),
            map(value => this.singleSelect_filter(value,this.countryNameList))
          );
          this.filteredSingleSelect.push({ [input_template.displayName]: observable });
        } else {
          observable = this.additionalDetailsFormGroup.get(input_template.displayName).valueChanges
            .pipe(
              startWith(''),
              map(value => this.singleSelect_filter(value, input_template.valueList))
            );
          this.filteredSingleSelect.push({ [input_template.displayName]: observable });
        }
      }

      if (input_template.type === 'Multiple Select') {
        this.multipleOriginalDropDowns.push(Object.assign({}, { [input_template.displayName]: input_template.valueList }));
      }

    });
  }

  private singleSelect_filter(value,valueList): any[] {
    if(value) {
      const filteredValueList = valueList.filter(Option => Option.toLocaleLowerCase().includes(value.toLocaleLowerCase()));
      return filteredValueList;
    } else {
      return valueList;
    }
  }

  displaySelectFn(value): string {
    return value;
  }
  setDateWithOffset(date) {
    let newDate = new Date(date);
    newDate.setMinutes(newDate.getMinutes() + newDate.getTimezoneOffset());
    return newDate;
     }

  getSelection(event: any,displayName:string) {
    if (event.isUserInput) {
      if (event.source.selected) {
        this.additionalDetailsFormGroup.get(displayName).setValue([...this.additionalDetailsFormGroup.get(displayName).value,event.source.value]);
      } else {
        const index = this.additionalDetailsFormGroup.get(displayName).value.findIndex(
          element => element == event.source.value
        );
        this.additionalDetailsFormGroup.get(displayName).value.splice(index, 1);
      }
    }
  }

  openedChange(isOpen: boolean,displayName:string) {
    if (!isOpen) this.filterValues("",displayName);
  }

  filterValues(filter: string,displayName:string) {
    this.configurableFields.find(ele=>ele.displayName === displayName).valueList = this.multipleOriginalDropDowns.find(ele=>Object.keys(ele).includes(displayName))[displayName].filter(x =>
      x.toLowerCase().includes(filter.toLowerCase())
    );
  }

  private _getFilterObservableWrtDisplayName(displayName) {
    return this.filteredSingleSelect.findIndex(ele => Object.keys(ele).includes(displayName))!== -1 ?
      this.filteredSingleSelect.find(ele => Object.keys(ele).includes(displayName))[displayName] : new Observable();
  }

  private isAdditionalValid() {
    let controlNamesWithList:any = Object.entries(this.additionalDetailsFormGroup.value).filter(ele => {
      if (['Single Select', 'Country'].includes(this.configurableFields.find(curr => curr.displayName === ele[0]).type)) {
        return true;
      }
    });
    controlNamesWithList = controlNamesWithList.map(ele=> ele[0]);
    let isAnyControlInvalid = controlNamesWithList.map(curr => {
      if (!this.additionalDetailsFormGroup.controls[curr].valid) {
        if (this.additionalDetailsFormGroup.controls[curr].value === '') {
          return true;
        }
      }

      return this.additionalDetailsFormGroup.controls[curr].valid;
    }).includes(false);
    if(!isAnyControlInvalid){
      return true;
    }
    return false;
  }
  /** save draft btn disabled when only either of currency code or amount is entered  */
  private isCurrencyValid() {
    let controlNamesWithCurrencyList: any = Object.entries(this.additionalDetailsFormGroup.value).filter(ele => {
      if(['Currency'].includes(this.configurableFields.find(curr => curr.displayName === ele[0]).type)) {
        return true;
      }
    });
    controlNamesWithCurrencyList = controlNamesWithCurrencyList.map(ele => ele[1]);
    let isCurrencyValid = controlNamesWithCurrencyList.map(curr => {
      if((curr.currencyAmount === null && curr.currencyCode !== '') || (curr.currencyAmount !== null && curr.currencyCode === '')) {
        return false;
      }
    }).includes(false);
    if(!isCurrencyValid) {
      return true
    }
    return false;
  }

  /**
   * To validate if the values provided in additional detail section which are of type 'Country' and "Single Select"
   * @param displayName displayName of field
   * @param control formControl - field parameter to validate against respective list
   */
   private depValueListMatch(displayName: any,control:any): ValidationErrors | null {
    const selection: any = control.value;
    let valueList: any;
    if (this.configurableFields.find(ele => ele.displayName === displayName).type === 'Country') {
      if (!(this.countryList && this.countryList.length)) {
        this.locationService.countryList.subscribe(countryList => {
          if (countryList.length > 0) {
            countryList.sort((a, b) => a.countryName.localeCompare(b.countryName));
            this.countryNameList = countryList.map(ele => ele.countryName);
          }
        });
      } else {
        this.countryNameList = this.countryList.map(ele => ele.countryName);
      }
      valueList = this.countryNameList;
    } else {
      valueList = this.configurableFields.find(ele => ele.displayName === displayName).valueList;
    }

   if (valueList && valueList.length > 0 && selection) {
     if ((valueList.filter(val => val.toLowerCase() == selection.toLowerCase())).length < 1) {
       return {
         requireMatch: true
       };
     }
   }
   return null;
 }

  private _filterField(value): Country[] {
    if (value) {
      const filterValue = typeof value === 'number' ? value : value.toLowerCase();
      const countryList = this.countryList.filter(option => option.countryName.toLowerCase().indexOf(filterValue) === 0);
      return countryList;
    } else {
      return this.countryList;
    }
  }

  private _filter(value): Country[] {
    if (value) {
    const filterValue = typeof value === 'number' ? value : value.toLowerCase();
    const countryList = this.countryList.filter(option => option.countryName.toLowerCase().indexOf(filterValue) === 0);
    return countryList;
  } else {
      return this.countryList;
    }
  }

  private clientContact_filter(value): AdminClientContacts[] {
    if (value && value.name) {
    const filterValue = typeof value === 'number' ? value : value.name.toLowerCase();
    const ccList = this.cciDetails.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    return ccList;
  } else {
      return this.cciDetails;
    }
  }

  currency_filter(value): Country[] {
    if(value) {
      const filterValue = typeof value === 'number' ? value :
                          value.code? value.code.toLowerCase(): value;
      const currencyList = this.billiingCurrency? this.billiingCurrency.filter(Option => Option.currency.code.toLocaleLowerCase().indexOf(filterValue) === 0): [];
      return currencyList;
    } else {
      return this.billiingCurrency;
    }
  }

  displayFn(country: any): string {
    return country ? '+ ' + country : '';
  }

  displayClientContactFn(cc): string {
    return cc && cc.name ? cc.name : '';
  }

  displayCurrencyFn(curr): string {
    return curr && curr.code? curr.code + '-' + curr.description: '';
  }

  displayCurrencyCodeFn(code): string {
    return code? code: '';
  }

  compareContractData(data1: any, data2: any) {
    return (data1.contractName === data2.contractName);
  }

   pushToDepartureCountryCodes(countryCode, editData = null) {
    this.departCountryCode = countryCode ? countryCode.toLowerCase() : this.departCountryCode;
    if (countryCode) {
      const isRequiredvalidation = ['US', 'CA'].includes(countryCode) ? true : false;
      this.streets.removeAt(0);
      this.AddDepartureStreets(editData);
      this.cities.removeAt(0);
      this.AddDeparatureCities(editData);
      this.states.removeAt(0);
      this.AddDepartureStates(editData, isRequiredvalidation);
      this.postalCodes.removeAt(0);
      this.AddDeparaturePostalCodes(editData,isRequiredvalidation);
    }
   }

   pushToDestinationCountryCodes(countryCode, editData = null) {
     this.destiCountryCode =  countryCode ? countryCode.toLowerCase() : this.destiCountryCode;
     if (countryCode) {
      const isRequiredvalidation = ['US', 'CA'].includes(countryCode) ? true : false;
      this.destiStreets.removeAt(0);
      this.AddDestinationStreets(editData);
      this.destiCities.removeAt(0);
      this.AddDestinationCities(editData);
      this.destiStates.removeAt(0);
      this.AddDestinationStates(editData, isRequiredvalidation);
      this.destiPostalCodes.removeAt(0);
      this.AddDestinationPostalCodes(editData, false);
     }
   }


   onAutocompleteSelected(result: PlaceResult) {
    this.updateDepartureAddresValue(result, 'street');
  }

  updateDepartureAddresValue(result: PlaceResult, fieldTriggered: string) {
    let streetLine = '';
    const addressForm: FormGroup = (this.addCandidateForm.controls.addressForm as FormGroup);
    let isCity = false;

    result.address_components.forEach(element => {
      if (element.types[0] === 'postal_code') {
        this.postalCodes.setValue([element.long_name]);
      } else if (element.types[0] === 'administrative_area_level_1' &&
       (fieldTriggered === 'city'  || fieldTriggered === 'street' || fieldTriggered === 'state' || fieldTriggered === 'postalcode')) {
        this.states.setValue([element.long_name]);
        this.hasDepartureState = true;
      } else if ((element.types[0] === 'locality' || element.types[0] === 'sublocality_level_1' || element.types[0] === 'postal_town') && (fieldTriggered === 'city' || fieldTriggered === 'street' || fieldTriggered === 'postalcode') ) {
        this.cities.setValue([element.long_name]);
        isCity = true;
        this.hasDepartureCity = true;
      } else {
        if (!isCity && fieldTriggered === 'street') {
          streetLine = streetLine.length > 0 ?
          `${streetLine}, ${element.long_name}` : element.long_name;
          this.hasDepartureStreet = true;
        }
      }
    });
    this.streets.setValue([streetLine]);

  }
  onLocationSelected(location: Location) {

  }

  onDepartureCityAutocompleteSelected(result: PlaceResult) {
    this.updateDepartureAddresValue(result, 'city');
  }
  onDepartureStateAutocompleteSelected(result: PlaceResult) {
    this.updateDepartureAddresValue(result, 'state');
  }

  onDeparturePostalCodeAutocompleteSelected(result: PlaceResult) {
    this.updateDepartureAddresValue(result, 'postalcode');
  }

  resetDestinationAddress() {
    const addressForm: FormGroup = (this.addCandidateForm.controls.addressForm as FormGroup);
    (addressForm.controls.destinationAddr as FormGroup).controls.postalCode.reset();
    (addressForm.controls.destinationAddr as FormGroup).controls.state.reset();
    (addressForm.controls.destinationAddr as FormGroup).controls.city.reset();
    (addressForm.controls.destinationAddr as FormGroup).controls.streetLine1.reset();

  }
  updateDestinationAddresValue(result: PlaceResult, fieldTriggered: string) {
    //this.resetDestinationAddress();
    let streetLine = '';
    const addressForm: FormGroup = (this.addCandidateForm.controls.addressForm as FormGroup);
    let isCity = false;

    result.address_components.forEach(element => {
      if (element.types[0] === 'postal_code') {
        this.destiPostalCodes.setValue([element.long_name]);
      } else if (element.types[0] === 'administrative_area_level_1' &&
       (fieldTriggered === 'city'  || fieldTriggered === 'street' || fieldTriggered === 'state' || fieldTriggered === 'postalcode')) {
        this.destiStates.setValue([element.long_name]);
        this.hasDestinationState = true;
      } else if ((element.types[0] === 'locality' || element.types[0] === 'sublocality_level_1' || element.types[0] === 'postal_town') && (fieldTriggered === 'city' || fieldTriggered === 'street' || fieldTriggered === 'postalcode') ) {
        this.destiCities.setValue([element.long_name]);
        isCity = true;
        this.hasDestinationCity = true;
      } else {
        if (!isCity && fieldTriggered === 'street') {
          streetLine = streetLine.length > 0 ?
          `${streetLine}, ${element.long_name}` : element.long_name;
          this.hasDestinationStreet = true;
        }
      }
    });

 this.destiStreets.setValue([streetLine]);
  }
  onDestinationStreetAutocompleteSelected(result: PlaceResult) {
    this.updateDestinationAddresValue(result, 'street');
  }
  onDestinationCityAutocompleteSelected(result: PlaceResult) {
    this.updateDestinationAddresValue(result, 'city');
  }
  onDestinationStateAutocompleteSelected(result: PlaceResult) {
    this.updateDestinationAddresValue(result, 'state');
  }

  onDestinationPostalCodeAutocompleteSelected(result: PlaceResult) {
    this.updateDestinationAddresValue(result, 'postalcode');
  }
  /**
   * Will instantiate the form and populate values to form with existing data
   * @param editData contains existing transferee information
   */
   populateForm(editData): FormGroup {
    const check = this.isNullCheck;
    editData && check(editData, 'divisionPartyId') && (this.userRole === 'client-contact-initiator' || this.userRole === 'file-setup-specialist') ? this.getProgram(editData.divisionPartyId) : null;
    editData && editData.programDetails && check(editData.programDetails, 'points') ? this.points = editData.programDetails.points : null;
    editData && editData.programDetails && check(editData.programDetails.programBudget, 'totalAmount') ? this.amount = editData.programDetails.programBudget.totalAmount : null;
    editData && editData.programDetails && check(editData.programDetails.programBudget, 'currency') ? this.budgetCurrency = editData.programDetails.programBudget.currency : null;

    const addForm: FormGroup = this.formBuilder.group({
      nameDetails: this.formBuilder.group({
        title: [
          editData ? check(editData.nameDetails, 'title') : ''],
        firstName: [editData ? check(editData.nameDetails, 'firstName') : '', [Validators.required,
          Validators.minLength(1), Validators.maxLength(50)]],
        lastName: [editData ? check(editData.nameDetails, 'lastName') : '', [Validators.required,
          Validators.minLength(2), Validators.maxLength(50)]]
      }),
      ssoIdentifier: this.formBuilder.control(editData && editData.ssoIdentifier ?  check(editData, 'ssoIdentifier'): null, this.data.ssoRegEx.ssoRegex && !this.isNewHire ? [Validators.pattern(atob(this.data.ssoRegEx.ssoRegex)), Validators.required] : null ),
      email: new FormArray(
        this._generateEmailGroup(editData.emailDetailsList)
      ),
      phone: new FormArray(
        this._generatePhoneGroup(editData.phoneDetailsList)
      ),
     addressForm: this.formBuilder.group({
        departureAddr: this.formBuilder.group({
          country: [editData ? check(editData.departureAddr, 'country') : '', [Validators.required]],
          cities:  new FormArray([
            new FormControl(editData ? check(editData.departureAddr, 'city') : '',
            this.userRole === 'client-contact-initiator' || this.userRole === 'file-setup-specialist' ?
             [Validators.required, this.depCityMatch.bind(this)] : null)
          ]),
          streets:  new FormArray([
            new FormControl(editData ? check(editData.departureAddr, 'streetLine1') : '')
          ]) ,
          states: new FormArray([
            new FormControl(editData ? check(editData.departureAddr, 'state') : '', [Validators.minLength(3)])
          ]),
          postalCodes: new FormArray([
            new FormControl(editData ? check(editData.departureAddr, 'postalCode') : '',
            [Validators.minLength(1), Validators.maxLength(15)])
          ]),
        }),
        destinationAddr: this.formBuilder.group({
          country: [editData ? check(editData.destinationAddr, 'country') : '', [Validators.required]],
          destiStreets : new FormArray([
            new FormControl( editData ? check(editData.destinationAddr, 'streetLine1') : '')
          ]),
          destiCities: new FormArray([
            new FormControl(editData ? check(editData.destinationAddr, 'city') : '',
            this.userRole === 'client-contact-initiator' || this.userRole === 'file-setup-specialist' ?
             [Validators.required, this.destCityMatch.bind(this)] : null)
          ]),
           destiStates: new FormArray([
             new FormControl(editData ? check(editData.destinationAddr, 'state') : '', [Validators.minLength(3)])
           ]),
           destiPostalCodes: new FormArray([
             new FormControl(editData ? check(editData.destinationAddr, 'postalCode') : '',
             [Validators.minLength(1), Validators.maxLength(15)])
           ])
        })
      }),
      divisionPartyId: this.formBuilder.control( editData && editData.divisionPartyId? check(editData, 'divisionPartyId') : '', this.userRole === 'file-setup-specialist' || this.userRole === 'client-contact-initiator'? [Validators.required]:null),
      assignedPolicy: this.formBuilder.control( editData && editData.assignedPolicy? check(editData, 'assignedPolicy'): '', [Validators.required]),
      executiveOfficer: this.formBuilder.control( editData && editData.executiveOfficer?  check(editData, 'executiveOfficer'): '', [Validators.required]),
      programDetails: this.formBuilder.group(this.validateProgramDetails(editData)),

      /** mandatory fields only for  traditional transferee--fsu */
      deliveryTeamModel: this.formBuilder.control( editData && editData.deliveryTeamModel? check(editData, 'deliveryTeamModel'): '', (this.userRole === 'file-setup-specialist' && this.isTraditional)? [Validators.required]:null),
      cliAuthSubTime: this.formBuilder.control( editData && editData.cliAuthSubTime? check(editData, 'cliAuthSubTime'): '', (this.userRole === 'file-setup-specialist' && this.isTraditional)? [Validators.required]: null),
      cliAuthSubDt: this.formBuilder.control( editData && editData.cliAuthSubDt? check(editData, 'cliAuthSubDt'): '', (this.userRole === 'file-setup-specialist' && this.isTraditional)? [Validators.required]:null),
      authReadytoProcessDt: this.formBuilder.control( editData && editData.authReadytoProcessDt? check(editData, 'authReadytoProcessDt'): '', (this.userRole === 'file-setup-specialist' && this.isTraditional)? [Validators.required]:null),
      authReadytoProcessTime: this.formBuilder.control( editData && editData.authReadytoProcessTime? check(editData, 'authReadytoProcessTime'): '', (this.userRole === 'file-setup-specialist' && this.isTraditional)? [Validators.required]:null),
      timeZone: this.formBuilder.control( editData && editData.timeZone? check(editData, 'timeZone'): '(GMT-05:00) Eastern Standard Time' , (this.userRole === 'file-setup-specialist' && this.isTraditional)? [Validators.required]:null),
      initialContactBy: this.formBuilder.control( editData && editData.initialContactBy? check(editData, 'initialContactBy'): '', (this.userRole === 'file-setup-specialist' && this.isTraditional)? [Validators.required]:null),

      /** optional details for bb transferee */
      optionalDetails: this.formBuilder.group({
        costCenter: [ editData ? check(editData.optionalDetails, 'costCenter') : '', Validators.maxLength(50)],
        poBillingInvoice: [ editData ? check(editData.optionalDetails, 'poBillingInvoice') : '', Validators.maxLength(99)],
        employeeID: [editData ? check(editData.optionalDetails, 'employeeID') : '', Validators.maxLength(99)],
        jobStartDate: [ editData && editData.optionalDetails && editData.optionalDetails.jobStartDate ? `${check(editData.optionalDetails, 'jobStartDate')}` : ''],
        priority: [ editData ? check(editData.optionalDetails, 'priority') : 'Normal'],
        employeeType: [ editData ? check(editData.optionalDetails, 'employeeType'): ''],
      }),
      /** optional details for Traditional transferee */
      optionalDetailsTraditionals: this.formBuilder.group({
        priority: [ editData ? check(editData.optionalDetails, 'priority') : 'Normal'],
        employeeType: [editData ? check(editData.optionalDetails, 'employeeType'): ''],
        defaultHomeCountry: [editData ? check(editData.optionalDetails, 'defaultHomeCountry'): ''],
        defaultHostCountry: [editData ? check(editData.optionalDetails, 'defaultHostCountry'): ''],
        assignmentStartDate: [ editData ? check(editData.optionalDetails, 'assignmentStartDate'): ''],
        assignmentEndDate: [ editData ? check(editData.optionalDetails, 'assignmentEndDate'): ''],
        jobStartDate: [ editData && editData.optionalDetails && editData.optionalDetails.jobStartDate ? `${check(editData.optionalDetails, 'jobStartDate')}` : ''],
        employeeID: [editData ? check(editData.optionalDetails, 'employeeID') : '', Validators.maxLength(99)],
        isConfidentialFile: [ editData ? check(editData.optionalDetails, 'isConfidentialFile') : false],
      }),
      authorizationDetails: this.formBuilder.group({
        cciDetails: [null, this.userRole === 'file-setup-specialist'? [Validators.required]: null],
        contractDetails: [ editData? check(editData.authorizationDetails, 'contractDetails'): null, this.userRole === 'file-setup-specialist'? [Validators.required]: null],
        currency: [editData ? check(editData.authorizationDetails, 'currency') : null, this.userRole === 'file-setup-specialist'? [Validators.required]: null],
        moveType: this.formBuilder.control(editData && editData.moveType? check(editData, 'moveType'): '', (this.userRole === 'file-setup-specialist' && this.isTraditional)? [Validators.required]:null),
      }, this.userRole === 'file-setup-specialist' ? { validator: this.validateContractDetails } : null),
      // accompaningMember:(editData && editData.familyMembers && editData.familyMembers.length > 0 ) ? new FormArray(this._generateFamilyGroup(editData.familyMembers)) : new FormArray([]),
    });


    if (editData && editData.moveStatus && editData.moveStatus === 'Withdrawn') {
      Object.keys(addForm.controls).forEach(group => addForm.controls[group].disable());
    }

    return addForm;
  }
  
  validateContractDetails(c: FormGroup) {
    return (c.value.contractDetails === {} || c.value.contractDetails === null || c.value.currency === {} || c.value.currency === null) ? false: true;
  }
  validateProgramDetails(editData) {
    const check = this.isNullCheck;
    const assocTransferee = (this.data.associateTransferee === true ? true : false);
    if(this.data.isMMUser){

      return {
        programName: [ editData ? check(editData.programDetails, 'programName') : '', this.isTraditional === false ? [Validators.required] : null],

        totalAmount: [ editData && editData.programDetails && editData.programDetails.programBudget ? check(editData.programDetails.programBudget, 'totalAmount') : '', this.isTraditional === false  ? [Validators.pattern('^[0-9]*$'),
        Validators.min(1), Validators.required] : null],
        amountOverride: [ editData ? check(editData.programDetails, 'amountOverride') : false],
        currency: [ editData && editData.programDetails && editData.programDetails.programBudget ? check(editData.programDetails.programBudget, 'currency') : ''],
        coreOfferings: [editData && check(editData.programDetails, 'coreOfferings') ? editData.programDetails.coreOfferings : undefined]
      }
    }
    return {
      programName: [ editData ? check(editData.programDetails, 'programName') : '', this.isTraditional === false? [Validators.required]: null],
      points: [ editData ? check(editData.programDetails, 'points') : '', (this.isTraditional === false && !this.data.isGOUser)?[Validators.pattern('^[0-9]*$'),
      Validators.min(1), Validators.required]: (this.isTraditional === false && this.data.isGOUser) ?[Validators.pattern('^[0-9]*$'), Validators.required] : null],
        pointOverride: [ editData ? check(editData.programDetails, 'pointOverride') : false],
        coreOfferings: [ editData && check(editData.programDetails, 'coreOfferings') ? editData.programDetails.coreOfferings : undefined]
    };
  }
  get streets(): FormArray {
    const addressForm: FormGroup = (this.addCandidateForm.controls.addressForm as FormGroup);
    return (addressForm.controls.departureAddr as FormGroup).controls.streets as FormArray;
  }
  get cities(): FormArray {
    const addressForm: FormGroup = (this.addCandidateForm.controls.addressForm as FormGroup);
    return (addressForm.controls.departureAddr as FormGroup).controls.cities as FormArray;
  }
  get states(): FormArray {
    const addressForm: FormGroup = (this.addCandidateForm.controls.addressForm as FormGroup);
    return (addressForm.controls.departureAddr as FormGroup).controls.states as FormArray;
  }
  get postalCodes(): FormArray {
    const addressForm: FormGroup = (this.addCandidateForm.controls.addressForm as FormGroup);
    return (addressForm.controls.departureAddr as FormGroup).controls.postalCodes as FormArray;
  }

  get destiStreets(): FormArray {
    const addressForm: FormGroup = (this.addCandidateForm.controls.addressForm as FormGroup);
    return (addressForm.controls.destinationAddr as FormGroup).controls.destiStreets as FormArray;
  }
  get destiCities(): FormArray {
    const addressForm: FormGroup = (this.addCandidateForm.controls.addressForm as FormGroup);
    return (addressForm.controls.destinationAddr as FormGroup).controls.destiCities as FormArray;
  }
  get destiStates(): FormArray {
    const addressForm: FormGroup = (this.addCandidateForm.controls.addressForm as FormGroup);
    return (addressForm.controls.destinationAddr as FormGroup).controls.destiStates as FormArray;
  }
  get destiPostalCodes(): FormArray {
    const addressForm: FormGroup = (this.addCandidateForm.controls.addressForm as FormGroup);
    return (addressForm.controls.destinationAddr as FormGroup).controls.destiPostalCodes as FormArray;
  }
  AddDepartureStreets(editData = null) {
    const check = this.isNullCheck;
    this.streets.push(new FormControl(editData ? check(editData.departureAddr, 'streetLine1') : ''));

    (editData && check(editData.departureAddr, 'streetLine1')) ? this.hasDepartureStreet = true :
      this.hasDepartureStreet = false;
  }
  AddDeparatureCities(editData = null) {
    const check = this.isNullCheck;
    this.cities.push(new FormControl(editData ? check(editData.departureAddr, 'city') : '',
     this.userRole === 'client-contact-initiator' || this.userRole === 'file-setup-specialist' ?
    [Validators.required, this.depCityMatch.bind(this)] : null));
    (editData && check(editData.departureAddr, 'city')) ? this.hasDepartureCity = true :
      this.hasDepartureCity = false;
  }
  AddDepartureStates(editData = null, isRequiredValidation = false) {
    const check = this.isNullCheck;
    if (!isRequiredValidation) {
      this.states.push(new FormControl(editData ? check(editData.departureAddr, 'state') : '', [Validators.minLength(3)]));
    } else {
      this.states.push(new FormControl(editData ? check(editData.departureAddr, 'state') : '',
       [Validators.minLength(3), Validators.required]));
    }
  }
  AddDeparaturePostalCodes(editData = null , isRequiredValidation = false) {
    const check = this.isNullCheck;
    if (!isRequiredValidation) {
      this.postalCodes.push(new FormControl(editData ? check(editData.departureAddr, 'postalCode') : '',
      [Validators.minLength(1), Validators.maxLength(15)] ));
    } else {
      this.postalCodes.push(new FormControl(editData ? check(editData.departureAddr, 'postalCode') : '',
      [Validators.minLength(1), Validators.maxLength(15), Validators.required] ));
    }

  }

  AddDestinationStreets(editData = null) {
    const check = this.isNullCheck;
    this.destiStreets.push(new FormControl(editData ? check(editData.destinationAddr, 'streetLine1') : ''));
     (editData && check(editData.destinationAddr, 'streetLine1')) ? this.hasDestinationStreet = true :
      this.hasDestinationStreet = false;
  }
  AddDestinationCities(editData = null) {
    const check = this.isNullCheck;
    this.destiCities.push(new FormControl(editData ? check(editData.destinationAddr, 'city') : '', this.userRole === 'client-contact-initiator' || this.userRole === 'file-setup-specialist' ?
    [Validators.required, this.destCityMatch.bind(this)] : null));
    (editData && check(editData.destinationAddr, 'city')) ? this.hasDestinationCity = true :
    this.hasDestinationCity = false;
  }
  AddDestinationStates(editData = null, isRequiredvalidation = false) {
    const check = this.isNullCheck;
    if (!isRequiredvalidation) {
      this.destiStates.push(new FormControl(editData ? check(editData.destinationAddr, 'state') : '', [Validators.minLength(3)]));
    } else {
      this.destiStates.push(new FormControl(editData ? check(editData.destinationAddr, 'state') : '',
       [Validators.minLength(3), Validators.required]));
    }

  }
  AddDestinationPostalCodes(editData = null, isRequiredvalidation = false) {
    const check = this.isNullCheck;
    if (!isRequiredvalidation) {
      this.destiPostalCodes.push(new FormControl(editData ? check(editData.destinationAddr, 'postalCode') : '',
      [Validators.minLength(1), Validators.maxLength(15)] ));
    } else {
      this.destiPostalCodes.push(new FormControl(editData ? check(editData.destinationAddr, 'postalCode') : '',
      [Validators.minLength(1), Validators.maxLength(15), Validators.required] ));
    }
  }
  /**
   * To validate if the values provided in departure city auto complete fields are from the options provided
   * @param formControl - field parameter to validate against respective list
   */
  private depCityMatch(control: FormControl): ValidationErrors | null {
    const selection: any = control.value;
    if (this.departureCityList && this.departureCityList.length > 0 && selection) {
      // tslint:disable-next-line: triple-equals
      if ((this.departureCityList.filter(val => val.cityLongName.toLowerCase() == selection.toLowerCase())).length < 1) {
        return {
          requireMatch: true
        };
      }
    }
    return null;
  }

  /**
   * To validate if the values provided in destination city auto complete fields are from the options provided
   * @param formControl - field parameter to validate against respective list
   */
  private destCityMatch(control: FormControl): ValidationErrors | null {
    const selection: any = control.value;
    if (this.destinationCityList && this.destinationCityList.length > 0 && selection) {
      // tslint:disable-next-line: triple-equals
      if ((this.destinationCityList.filter(val => val.cityLongName.toLowerCase() == selection.toLowerCase())).length < 1) {
        return {
          requireMatch: true
        };
      }
    }
    return null;
  }

  /**
   * To validate if the values provided in departure state auto complete fields are from the options provided
   * @param formControl - field parameter to validate against respective list
   */
  private depStateMatch(control: FormControl): ValidationErrors | null {
    const selection: any = control.value;
    if (this.departureStateList && this.departureStateList.length > 0 && selection) {
      // tslint:disable-next-line: triple-equals
      if ((this.departureStateList.filter(val => val.stateLongName.toLowerCase() == selection.toLowerCase())).length < 1) {
        return {
          requireMatch: true
        };
      }
    }
    return null;
  }

  /**
   * To validate if the values provided in destination state auto complete fields are from the options provided
   * @param formControl - field parameter to validate against respective list
   */
  private destStateMatch(control: FormControl): ValidationErrors | null {
    const selection: any = control.value;
    if (this.destinationStateList && this.destinationStateList.length > 0 && selection) {
      // tslint:disable-next-line: triple-equals
      if ((this.destinationStateList.filter(val => val.stateLongName.toLowerCase() == selection.toLowerCase())).length < 1) {
        return {
          requireMatch: true
        };
      }
    }
    return null;
  }
  /**
   * Custom error messages for Firstname, lastname and Email to verify special character or empty errors
   * @param fieldName - field parameter to check for errors
   */

  getErrorMessage(formControl, fieldName, index= -1) {
    if (index === -1) {
      if(this.data.ssoRegEx.ssoRegexErrorMessage && formControl == 'email' && fieldName == 'email type' && !this.isNewHire && ((this.addCandidateForm.controls.email as FormArray).controls[0] as FormGroup).controls.usageType.invalid) {
        return this.ssoEmailErrorMsg;
      }else if (this.addCandidateForm.get(formControl).hasError('required') && this.userRole === 'client-contact-initiator') {
        return ('You must enter ' + fieldName);
      } else if(!this.isNewHire && formControl == 'ssoIdentifier' && this.data.candidate.ssoIdentifier && (this.addCandidateForm.get(formControl).hasError('pattern') || 
        this.addCandidateForm.get(formControl).invalid)) {
           this.addCandidateForm.get(formControl).markAsTouched();	
        return this.showSSOApiErr ? this.showSSOApiErr : this.data.ssoRegEx.ssoRegexErrorMessage ? this.data.ssoRegEx.ssoRegexErrorMessage : `You must enter ${formControl}`;	
      }else if(!this.isNewHire && formControl == 'ssoIdentifier' && (this.addCandidateForm.get(formControl).hasError('pattern') || this.addCandidateForm.get(formControl).hasError('required') ||
        this.addCandidateForm.get(formControl).invalid)) {
        return this.showSSOApiErr ? this.showSSOApiErr : this.data.ssoRegEx.ssoRegexErrorMessage ? this.data.ssoRegEx.ssoRegexErrorMessage : `You must enter ${formControl}`;
    } else if (this.addCandidateForm.get(formControl).hasError('pattern') || this.addCandidateForm.get(formControl).hasError('minlength') ||
        this.addCandidateForm.get(formControl).hasError('maxlength') || (this.addCandidateForm.get(formControl).hasError('requireMatch')
          && this.addCandidateForm.get(formControl).value.length < 3) || this.addCandidateForm.get(formControl).hasError('min')) {
        return ('You must enter a valid ' + fieldName);
      } else if (this.addCandidateForm.get(formControl).hasError('requireMatch') &&
      this.addCandidateForm.get(formControl).value.length >= 3) {
        return ('Your ' + fieldName + ' entry must match valid options');
      }
  } else {
    const ctrl = (this.addCandidateForm.get(formControl) as FormArray).controls[0] as FormControl;
    if (ctrl.hasError('required') && this.userRole === 'client-contact-initiator') {
      return ('You must enter ' + fieldName);
    } else if ((ctrl.hasError('pattern') || ctrl.hasError('minlength') ||
      ctrl.hasError('maxlength') || ctrl.hasError('requireMatch')
        && ctrl.value.length < 3) || ctrl.hasError('min')) {
      return ('You must enter a valid ' + fieldName);
    } else if (ctrl.hasError('requireMatch') &&
    ctrl.value.length >= 3) {
      return ('Your ' + fieldName + ' entry must match valid options');
    }
  }
    return '';
  }

  /**
   * Checks for Value or will return 'empty'
   * @param value any
   */
  isNullCheck(obj: Object, key: string) {
    try {
      // tslint:disable-next-line: triple-equals
      return ((obj[key] || obj[key] == false) && obj[key] !== null) ? obj[key] : '';
    } catch (error) {
      return '';
    }
  }
  onFocusOutDepartureCountryCode(){
    const countryObj = this.countryList.find(country => (this.addCandidateForm.value.addressForm.departureAddr.country).toLowerCase().
          includes(country.countryName.toLowerCase()));
          const countryCode = countryObj ? countryObj.countryiso2CharCode :null;
          const index = this.countryList.findIndex(
            (country) => (this.addCandidateForm.value.addressForm.departureAddr.country).toLowerCase() ===
            country.countryName.toLowerCase());
          if (index === -1) {
            ((this.addCandidateForm.controls.addressForm as FormGroup).controls.departureAddr as FormGroup).controls.country.setErrors({'incorrect': true});
          } else if (countryCode === this.departureCountryCode) {
              return;
            }
            this.hasDepartureCountry = true;
            this.departureCountryCode = countryCode;
            this.pushToDepartureCountryCodes(this.departureCountryCode);
            this.modifyLocationFormValidations(this.addCandidateForm.controls.addressForm as FormGroup,
              this.departureCountryCode, this.destinationCountryCode);
  }
  onFocusOutDestinationCountryCode(){
    const countryObj = this.countryList.find(country => (this.addCandidateForm.value.addressForm.destinationAddr.country).toLowerCase().
          includes(country.countryName.toLowerCase()));
          const countryCode = countryObj ? countryObj.countryiso2CharCode :null;
          const index = this.countryList.findIndex(
            (country) => (this.addCandidateForm.value.addressForm.destinationAddr.country).toLowerCase() ===
            country.countryName.toLowerCase());
          if (index === -1) {
            ((this.addCandidateForm.controls.addressForm as FormGroup).controls.destinationAddr as FormGroup).controls.country.setErrors({'incorrect': true});
          } else if (countryCode === this.destinationCountryCode) {
            return;
          }
            this.hasDestinationCountry = true;
            this.destinationCountryCode = countryCode;
            this.pushToDestinationCountryCodes(this.destinationCountryCode);
            this.modifyLocationFormValidations(this.addCandidateForm.controls.addressForm as FormGroup,
              this.departureCountryCode, this.destinationCountryCode);
  }
  /**
   * Initialize location search observer..
   */
  locationSearchInit() {
    // tslint:disable-next-line: triple-equals
    this.hasDepartureCountry = (this.addCandidateForm.value.addressForm.departureAddr.country != '') ? true : false;
    // tslint:disable-next-line: triple-equals
    this.hasDestinationCountry = (this.addCandidateForm.value.addressForm.destinationAddr.country != '') ? true : false;
    this.locationFormObserver = (this.addCandidateForm.controls.addressForm as FormGroup).valueChanges.pipe(
      debounceTime(300)
    ).subscribe(value => {
      // need to find the updated ke-0`3y.
      const newFormValue = { ...value };
      const updatedKey: updatedKeyInterface = this.findUpdatedKey(newFormValue, this.addressFormValue);

      if (updatedKey && updatedKey.type == 'country') {
        if (this.addCandidateForm.value.addressForm.departureAddr.country != '') {
          const countryObj = this.countryList.find(country => (this.addCandidateForm.value.addressForm.departureAddr.country).
          includes(country.countryName));
          this.departureCountryCode = countryObj ? countryObj.countryiso2CharCode :null;
          this.hasDepartureCountry = true;
          if (newFormValue.departureAddr.country !== this.addressFormValue.departureAddr.country) {
            this.pushToDepartureCountryCodes(this.departureCountryCode);
          }
        }

        if (this.addCandidateForm.value.addressForm.destinationAddr.country != '') {
          const country = this.countryList.find(country => (this.addCandidateForm.value.addressForm.destinationAddr.country).
          includes(country.countryName));
          this.destinationCountryCode = country?country.countryiso2CharCode :null;
          this.hasDestinationCountry = true;
          if (newFormValue.destinationAddr.country !== this.addressFormValue.destinationAddr.country) {
            this.pushToDestinationCountryCodes(this.destinationCountryCode);
          }
        }
        // Modify location form validation if US and CA
        this.modifyLocationFormValidations(this.addCandidateForm.controls.addressForm as FormGroup,
          this.departureCountryCode, this.destinationCountryCode);
        this.resetStates(updatedKey);
      }

      if (updatedKey && updatedKey.group === 'departureAddr') {
        if (!newFormValue.departureAddr.streets || (newFormValue.departureAddr.streets && newFormValue.departureAddr.streets.length > 0
        && newFormValue.departureAddr.streets[0] === '' && newFormValue.departureAddr.streets[0].length === 0)) {
          this.hasDepartureStreet = false;
        }

        if (!newFormValue.departureAddr.cities || (newFormValue.departureAddr.cities && newFormValue.departureAddr.cities.length > 0
          && newFormValue.departureAddr.cities[0] === '' && newFormValue.departureAddr.cities[0].length === 0)) {
          this.hasDepartureCity = false;
        }
        if (!newFormValue.departureAddr.states || ( newFormValue.departureAddr.states && newFormValue.departureAddr.states.length > 0
          && newFormValue.departureAddr.states[0] === '' && newFormValue.departureAddr.states[0].length === 0)) {
          this.hasDepartureState = false;
        }
      }

      if (updatedKey && updatedKey.group === 'destinationAddr') {
        if (!newFormValue.destinationAddr.destiStreets || (newFormValue.destinationAddr.destiStreets &&
           newFormValue.destinationAddr.destiStreets.length > 0 && newFormValue.destinationAddr.destiStreets[0] === ''
            && newFormValue.destinationAddr.destiStreets[0].length === 0)) {
            this.hasDestinationStreet = false;
          }

          if (!newFormValue.destinationAddr.destiCities ||
             (newFormValue.destinationAddr.destiCities && newFormValue.destinationAddr.destiCities.length > 0
            && newFormValue.destinationAddr.destiCities[0] === '' && newFormValue.destinationAddr.destiCities[0].length === 0)) {
            this.hasDestinationCity = false;
          }
          if (!newFormValue.destinationAddr.destiStates ||
             ( newFormValue.destinationAddr.destiStates && newFormValue.destinationAddr.destiStates.length > 0
            && newFormValue.destinationAddr.destiStates[0] === '' && newFormValue.destinationAddr.destiStates[0].length === 0)) {
            this.hasDestinationState = false;
          }
      }

      this.addressFormValue = value;

    });

  }

  /**
 * Will automatically reset the state if there are any changes to city
 * @param updatedKey contains the updated {key}
 */
  resetStates(updatedKey: updatedKeyInterface) {
    setTimeout(() => {
      const addressForm: FormGroup = (this.addCandidateForm.controls.addressForm as FormGroup);
      // tslint:disable-next-line: triple-equals
      if (updatedKey.group == 'departureAddr' && (updatedKey.type == 'city' || updatedKey.type == 'country')) {
        this.states.setValue(['']);
        this.streets.setValue(['']);
        // tslint:disable-next-line: triple-equals
        if (updatedKey.type == 'country') {
          this.cities.setValue(['']);
          this.postalCodes.setValue(['']);
          this.streets.setValue(['']);
          this.hasDepartureCity = false;
          this.hasDepartureState = false;
        }
        this.hasDepartureStreet = false;
      }

      // tslint:disable-next-line: triple-equals
      if (updatedKey.group == 'destinationAddr' && (updatedKey.type == 'city' || updatedKey.type == 'country')) {
        this.destiStates.setValue(['']);
        if (updatedKey.type == 'country') {
          this.destiCities.setValue(['']);
          this.destiPostalCodes.setValue(['']);
          this.hasDestinationCity = false;

        }
        this.hasDestinationState = false;
      }

      // Clear state list if City is updated.
      // tslint:disable-next-line: triple-equals
      if (updatedKey.group == 'departureAddr' && (updatedKey.type == 'city' || updatedKey.type == 'country')) {
        this.departureStateList = [];
      }
      // tslint:disable-next-line: triple-equals
      if (updatedKey.group == 'departureAddr' && updatedKey.type == 'country') {
        this.departureCityList = [];
      }
      // tslint:disable-next-line: triple-equals
      if (updatedKey.group == 'destinationAddr' && (updatedKey.type == 'city' || updatedKey.type == 'country')) {
        this.destinationStateList = [];
      }
      // tslint:disable-next-line: triple-equals
      if (updatedKey.group == 'destinationAddr' && updatedKey.type == 'country') {
        this.destinationCityList = [];
      }

    }, 500);
  }
  /**
     * Will determine which field has been updated in Address Form (for Address and Review Component).
     * @param newFormValue AddressForm new values
     * @param oldFormValue AddressForm old values
     */
  findUpdatedKey(newFormValue, oldFormValue): updatedKeyInterface {
    let updatedKey = null;

    Object.keys(newFormValue.departureAddr).map(key => {
      if (newFormValue.departureAddr[key] !== oldFormValue.departureAddr[key]) {
        // tslint:disable-next-line: rxjs-no-unsafe-scope
        updatedKey = key;
      }
    });

    if (updatedKey) { return { group: 'departureAddr', type: updatedKey }; }

    Object.keys(newFormValue.destinationAddr).map(key => {
      if (newFormValue.destinationAddr[key] !== oldFormValue.destinationAddr[key]) {
        // tslint:disable-next-line: rxjs-no-unsafe-scope
        updatedKey = key;
      }
    });

    if (updatedKey) { return { group: 'destinationAddr', type: updatedKey }; }

    return null;
  }

  locationSearch(addressForm: FormGroup, updatedKey: updatedKeyInterface, updatedLocationFormValue: any,
    departureCountryCode: string, destinationCountryCode: string): Observable<Locations> {

    // tslint:disable-next-line: deprecation
    return Observable.create(_observer => {

      const spinner = this.injector.get(NgxSpinnerService);
      const locationService = this.injector.get(LocationsService);

      // if empty then don't run...
      if (!updatedLocationFormValue[updatedKey.group][updatedKey.type]) { return; }

      const searchType = updatedKey.type === 'city' ? 'cities' : 'states';
      const searchText: string = updatedLocationFormValue[updatedKey.group as any][updatedKey.type];

      // Search only if search text length is greater than 3;
      if (searchText.length < 3) { return; }

      spinner.show();
      locationService.locationSearch(searchType, updatedLocationFormValue[updatedKey.group as any][updatedKey.type], {
        country: updatedKey.group === 'departureAddr' ? departureCountryCode : destinationCountryCode,
        cityLongName: updatedKey.group == 'departureAddr' ? updatedLocationFormValue.departureAddr.city :
        updatedLocationFormValue.destinationAddr.city,
        cityShortName: updatedKey.group == 'departureAddr' ? updatedLocationFormValue.departureAddr.city :
        updatedLocationFormValue.destinationAddr.city
      }).subscribe(data => {

        // Empty response..
        if (!data) {
          // tslint:disable-next-line: triple-equals
          if (updatedKey.group == 'departureAddr') {
            // tslint:disable-next-line: triple-equals
            (updatedKey.type == 'city') ?
              (addressForm.controls.departureAddr as FormGroup).controls.city.setValue('') :
              (addressForm.controls.departureAddr as FormGroup).controls.state.setValue('');
          } else { // destinationAddr
            (updatedKey.type == 'city') ?
              (addressForm.controls.destinationAddr as FormGroup).controls.city.setValue('') :
              (addressForm.controls.destinationAddr as FormGroup).controls.state.setValue('');
          }
          _observer.next(null);
          _observer.complete();
        }
        _observer.next(data);
        _observer.complete();

      }, () => {
        _observer.next(null);
        _observer.complete();

      }, () => console.log('hide spinner')
        // spinner.hide();
      );
    });
  }


  /**
   * State/Province & postal code required for locations in the United States of America and Canada only
   */
  modifyLocationFormValidations(addressForm: FormGroup, departureCountryCode: string, destinationCountryCode: string) {
    if (!['US', 'CA'].includes(departureCountryCode)) {
      this.states.controls[0].setValidators([Validators.minLength(3),
      this.depStateMatch.bind(this)]);
      this.postalCodes.controls[0].setValidators([Validators.minLength(2),
      Validators.maxLength(50)]);

    } else {
      this.states.controls[0].setValidators([Validators.required,
      this.depStateMatch.bind(this), Validators.minLength(3)]);
      this.postalCodes.controls[0].setValidators([Validators.required,
      Validators.minLength(2), Validators.maxLength(50)]);

    }
    if (!['US', 'CA'].includes(destinationCountryCode)) {
      this.destiStates.controls[0].setValidators([this.destStateMatch.bind(this),
      Validators.minLength(3)]);
      this.destiPostalCodes.controls[0].setValidators([Validators.minLength(2),
        Validators.maxLength(50)]);
    } else {
      this.destiStates.controls[0].setValidators([Validators.required,
      this.destStateMatch.bind(this), Validators.minLength(3)]);
      this.destiPostalCodes.controls[0].setValidators([Validators.minLength(2),
        Validators.maxLength(50)]);
    }
    this.states.controls[0].updateValueAndValidity();
    this.postalCodes.controls[0].updateValueAndValidity();
    this.destiStates.controls[0].updateValueAndValidity();
    this.destiPostalCodes.controls[0].updateValueAndValidity();
  }

  /**
   * On click of send invite button inside the addCandidateForm dialog window
   */
  sendInvite() {
    if (this.data.isMMUser) {
      this.amount = this.addCandidateForm.value.programDetails.totalAmount;
      this.budgetCurrency = this.addCandidateForm.value.programDetails.currency;
    } else if (!this.data.isMMUser) {
      this.points = this.addCandidateForm.value.programDetails.points;
    }
      this.saveCandidate(this.data.candidate);
  }
  /**
   *On click of Save Draft button inside the addCandidateForm dialog window
   */
  saveData() {
    this.spinner.show();
    if (this.data.isMMUser) {
      this.amount = this.addCandidateForm.value.programDetails.totalAmount;
      this.budgetCurrency = this.addCandidateForm.value.programDetails.currency;
    } else if (!this.data.isMMUser) {
      this.points = this.addCandidateForm.value.programDetails.points;
    }
      this.saveCandidate(this.populateValues(this.data.candidate));
  }

  populateValues(editData: candidateProfile): candidateProfile {
    this.candidateProfile = {} as candidateProfile;

    this.candidateProfile.clientNo = this.data.candidate.client.clientNumber;
    this.candidateProfile._id = this.data.candidate._id;

    /** Profile Details */
    this.candidateProfile.nameDetails = {
      firstName: this.addCandidateForm.value.nameDetails.firstName,
      lastName: this.addCandidateForm.value.nameDetails.lastName
    };

    (this.addCandidateForm.value.nameDetails.title) ? this.candidateProfile.nameDetails.title =
    // tslint:disable-next-line: no-unused-expression
    this.addCandidateForm.value.nameDetails.title : null;

    if(this.addCandidateForm.value.phone) {
      const phoneDetail: phoneDetails[] = [];
      this.addCandidateForm.value.phone.forEach(ele => {
        if(ele.phoneDialCode && ele.phoneNumber) {
          let phoneObj = {
            phoneDialCode: ele.phoneDialCode,
            phoneNumber: ele.phoneNumber,
          };
          if(ele.type == 'Departure Business') { phoneObj['usageType'] = "business"; phoneObj['locationType'] ="departure" }
          if(ele.type == 'Destination Business') { phoneObj['usageType'] = "business"; phoneObj['locationType'] ="destination" }
          if(ele.type == 'Departure Residence') { phoneObj['usageType'] = "personal"; phoneObj['locationType'] ="departure" }
          if(ele.type == 'Destination Residence') { phoneObj['usageType'] = "personal"; phoneObj['locationType'] ="destination" }
          if(ele.type == "Mobile") { phoneObj['primary'] = true; phoneObj['textingAvailable']= true; phoneObj['usageType'] = "personal" }

          ele._id? phoneObj['_id'] = ele._id: null;
          phoneDetail.push(phoneObj);
        }
      });
      (editData && editData.phoneDetailsList && phoneDetail.length ) ?
      this.candidateProfile.phoneDetailsList = [...phoneDetail]: null;
    }
    if (this.addCandidateForm.value.email) {
      const emailDetail: emailDetails[] =[];
      this.addCandidateForm.value.email.forEach(ele => {
        if(ele.emailAddress && ele.usageType) {
          const emailObj = {
            emailAddress: ele.emailAddress,
            usageType: ele.usageType,
          }
          if(emailObj.usageType === 'Business') {
            emailObj['primary'] = true;
            emailObj.usageType = 'business';
          }
          if(emailObj.usageType === 'Personal') {
            emailObj.usageType = 'personal';
          }
          ele._id !== null? emailObj['_id'] = ele._id: null;
          emailDetail.push(emailObj);
        }
      });
       (editData && editData.emailDetailsList && emailDetail.length ) ?
      this.candidateProfile.emailDetailsList = [...emailDetail]: null;
    }

    /**Departure  Address Details */
    const departAddress: shortAddress = {} as shortAddress;
    (this.addCandidateForm.value.addressForm.departureAddr.cities &&
       this.addCandidateForm.value.addressForm.departureAddr.cities.length>0 &&
        this.addCandidateForm.value.addressForm.departureAddr.cities[0]) ?
        departAddress.city = this.addCandidateForm.value.addressForm.departureAddr.cities[0] : null;
    (this.addCandidateForm.value.addressForm.departureAddr.country) ? departAddress.country =
    this.addCandidateForm.value.addressForm.departureAddr.country : null;
    (this.addCandidateForm.value.addressForm.departureAddr.postalCodes
       && this.addCandidateForm.value.addressForm.departureAddr.postalCodes.length > 0 &&
       this.addCandidateForm.value.addressForm.departureAddr.postalCodes[0]) ? departAddress.postalCode =
    this.addCandidateForm.value.addressForm.departureAddr.postalCodes[0] : null;
    (this.addCandidateForm.value.addressForm.departureAddr.states
      && this.addCandidateForm.value.addressForm.departureAddr.states.length > 0 &&
      this.addCandidateForm.value.addressForm.departureAddr.states[0]) ? departAddress.state =
    this.addCandidateForm.value.addressForm.departureAddr.states[0] : null;
    (this.addCandidateForm.value.addressForm.departureAddr.streets &&
      this.addCandidateForm.value.addressForm.departureAddr.streets.length > 0 &&
      this.addCandidateForm.value.addressForm.departureAddr.streets[0]) ? departAddress.streetLine1 =
    this.addCandidateForm.value.addressForm.departureAddr.streets[0] : null;
    Object.keys(departAddress).length ? this.candidateProfile.departureAddr = departAddress : null;

    /**Destination  Address Details */
    const destAddress: shortAddress = {} as shortAddress;
    (this.addCandidateForm.value.addressForm.destinationAddr.destiCities &&
      this.addCandidateForm.value.addressForm.destinationAddr.destiCities.length > 0 &&
      this.addCandidateForm.value.addressForm.destinationAddr.destiCities[0]) ? destAddress.city =
    this.addCandidateForm.value.addressForm.destinationAddr.destiCities[0] : null;
    (this.addCandidateForm.value.addressForm.destinationAddr.country) ? destAddress.country =
    this.addCandidateForm.value.addressForm.destinationAddr.country : null;
    (this.addCandidateForm.value.addressForm.destinationAddr.destiPostalCodes &&
      this.addCandidateForm.value.addressForm.destinationAddr.destiPostalCodes.length > 0 &&
      this.addCandidateForm.value.addressForm.destinationAddr.destiPostalCodes[0]) ? destAddress.postalCode =
    this.addCandidateForm.value.addressForm.destinationAddr.destiPostalCodes[0] : null;
    (this.addCandidateForm.value.addressForm.destinationAddr.destiStates &&
      this.addCandidateForm.value.addressForm.destinationAddr.destiStates.length > 0 &&
      this.addCandidateForm.value.addressForm.destinationAddr.destiStates[0]) ? destAddress.state =
    this.addCandidateForm.value.addressForm.destinationAddr.destiStates[0] : null;
    (this.addCandidateForm.value.addressForm.destinationAddr.destiStreets &&
      this.addCandidateForm.value.addressForm.destinationAddr.destiStreets.length > 0 &&
      this.addCandidateForm.value.addressForm.destinationAddr.destiStreets[0]) ? destAddress.streetLine1 =
    this.addCandidateForm.value.addressForm.destinationAddr.destiStreets[0] : null;
    Object.keys(destAddress).length ? this.candidateProfile.destinationAddr = destAddress : null;


    /** Optional Details */
    const optionalDetail = {} as any;
    optionalDetail.priority = this.addCandidateForm.value.optionalDetails.priority;
    optionalDetail.employeeType = this.addCandidateForm.value.optionalDetails.employeeType;
    optionalDetail.costCenter = this.addCandidateForm.value.optionalDetails.costCenter;
    optionalDetail.jobStartDate = moment(this.addCandidateForm.value.optionalDetails.jobStartDate).format('YYYY-MM-DD HH:mm:ss');
    !this.addCandidateForm.value.optionalDetails.jobStartDate ? optionalDetail.jobStartDate ='' : this.setDateToUTC(new Date(this.addCandidateForm.value.optionalDetails.jobStartDate));
    optionalDetail.poBillingInvoice = this.addCandidateForm.value.optionalDetails.poBillingInvoice;
    optionalDetail.employeeID = this.addCandidateForm.value.optionalDetails.employeeID;

    /** Optional Details of Traditional Transferee */
    const optionalDetailsTraditional = {} as any;
    optionalDetailsTraditional.priority = this.addCandidateForm.value.optionalDetailsTraditionals.priority;
    optionalDetailsTraditional.employeeType = this.addCandidateForm.value.optionalDetailsTraditionals.employeeType;
    optionalDetailsTraditional.employeeID = this.addCandidateForm.value.optionalDetailsTraditionals.employeeID;
    if(this.isUS_SelectedCheck) {
      optionalDetailsTraditional.defaultHomeCountry = this.addCandidateForm.value.optionalDetailsTraditionals.defaultHomeCountry;
      optionalDetailsTraditional.defaultHostCountry = this.addCandidateForm.value.optionalDetailsTraditionals.defaultHostCountry;
      optionalDetailsTraditional.assignmentStartDate = moment(this.addCandidateForm.value.optionalDetailsTraditionals.assignmentStartDate).format('YYYY-MM-DD HH:mm:ss');
      !this.addCandidateForm.value.optionalDetailsTraditionals.assignmentStartDate ? optionalDetailsTraditional.assignmentStartDate = '' : this.setDateToUTC(new Date(this.addCandidateForm.value.optionalDetailsTraditionals.assignmentStartDate));
      optionalDetailsTraditional.assignmentEndDate = moment(this.addCandidateForm.value.optionalDetailsTraditionals.assignmentEndDate).format('YYYY-MM-DD HH:mm:ss');
      !this.addCandidateForm.value.optionalDetailsTraditionals.assignmentEndDate ? optionalDetailsTraditional.assignmentEndDate = '' : this.setDateToUTC(new Date(this.addCandidateForm.value.optionalDetailsTraditionals.assignmentEndDate));
    }
    if(this.isUS_Move) {
      optionalDetailsTraditional.jobStartDate = moment(this.addCandidateForm.value.optionalDetailsTraditionals.jobStartDate).format('YYYY-MM-DD HH:mm:ss');
      !this.addCandidateForm.value.optionalDetailsTraditionals.jobStartDate ? optionalDetailsTraditional.jobStartDate ='' : this.setDateToUTC(new Date(this.addCandidateForm.value.optionalDetailsTraditionals.jobStartDate));
    }
    if(this.userRole === 'file-setup-specialist') {
      optionalDetailsTraditional.isConfidentialFile = this.addCandidateForm.value.optionalDetailsTraditionals.isConfidentialFile;
    }

    /** Program Details */
    const programDetail = {} as any;
    (this.addCandidateForm.value.programDetails.programName) ? programDetail.programName =
    this.addCandidateForm.value.programDetails.programName : null;
    (this.addCandidateForm.value.programDetails.points || this.addCandidateForm.value.programDetails.points == 0) ? programDetail.points =  Number(this.points) : null;
    if (!this.data.isMMUser) {
    programDetail.pointOverride = (this.addCandidateForm.value.programDetails && this.addCandidateForm.value.programDetails.hasOwnProperty('pointOverride')) ? 
                                  this.addCandidateForm.value.programDetails.pointOverride === "" ? false : this.addCandidateForm.value.programDetails.pointOverride :
                                  false;
    }
    this.addCandidateForm.value.programDetails.coreOfferings ? programDetail.coreOfferings =
    this.addCandidateForm.value.programDetails.coreOfferings : undefined;
    /** Program Budget candidate Details for MMUser*/
    if (this.data.isMMUser) {
      programDetail.amountOverride = this.addCandidateForm.value.programDetails.amountOverride === "" ? false : this.addCandidateForm.value.programDetails.amountOverride;
      programDetail.programBudget = {
        totalAmount: (this.addCandidateForm.value.programDetails.totalAmount) ? Number(this.amount) : null,
        currency: (this.addCandidateForm.value.programDetails.currency) ? this.budgetCurrency : null
      }
    }

    /** divisionPartyId  */
    let divPartyId: string;
    this.addCandidateForm.value.divisionPartyId[0] ? divPartyId = this.addCandidateForm.value.divisionPartyId : null;
    divPartyId ? this.candidateProfile.divisionPartyId = divPartyId: null;

    /**Traditional Or BenefitsBuilder */
    let authorizationType: string;
    /** cost projection check */
    let type: string;
    if(this.data.editTransferee) {
      authorizationType = this.data.candidate.authorizationType;
      type = this.data.candidate.type;
    }else {
      this.data.isTraditional? authorizationType = 'Traditional': authorizationType = 'BenefitsBuilder';
      this.data.isCostProjection? type = 'cost-projection': type = 'move';
    }
    authorizationType? this.candidateProfile.authorizationType = authorizationType: null;
    type? this.candidateProfile.type = type: null;

    /** assignedPolicy */
    let policy: string;
    this.addCandidateForm.value.assignedPolicy ? policy = this.addCandidateForm.value.assignedPolicy : null;
    policy? this.candidateProfile.assignedPolicy = policy: null;

    /**ssoIdentifier */
    let ssoIdentifier;
    this.addCandidateForm.value.ssoIdentifier && !this.isNewHire? ssoIdentifier = this.addCandidateForm.value.ssoIdentifier : null;
    ssoIdentifier? this.candidateProfile.ssoIdentifier = ssoIdentifier :  null; 

    /** executiveOfficer */
    let exeofficer: string;
    this.addCandidateForm.value.executiveOfficer ? exeofficer = this.addCandidateForm.value.executiveOfficer : null;
    exeofficer ? this.candidateProfile.executiveOfficer = exeofficer : null;

    if (this.isTraditional) {
      /** optional details */
      Object.keys(optionalDetailsTraditional).length ? this.candidateProfile.optionalDetails = optionalDetailsTraditional : null;

      if (this.userRole === "file-setup-specialist") {
        /**mandatory Tradional FSU Fields */
        this.addCandidateForm.value.authorizationDetails.moveType ? this.candidateProfile.moveType = this.addCandidateForm.value.authorizationDetails.moveType : null;
        this.addCandidateForm.value.timeZone ? this.candidateProfile.timeZone = this.addCandidateForm.value.timeZone : null;
        this.addCandidateForm.value.initialContactBy ? this.candidateProfile.initialContactBy = this.addCandidateForm.value.initialContactBy : null;
        this.addCandidateForm.value.deliveryTeamModel ? this.candidateProfile.deliveryTeamModel = this.addCandidateForm.value.deliveryTeamModel : null;
        this.addCandidateForm.value.cliAuthSubTime ? this.candidateProfile.cliAuthSubTime = this.addCandidateForm.value.cliAuthSubTime : null;
        this.addCandidateForm.value.cliAuthSubDt? this.candidateProfile.cliAuthSubDt = this.setDateToUTC(new Date(this.addCandidateForm.value.cliAuthSubDt)): null;
        this.addCandidateForm.value.authReadytoProcessTime ? this.candidateProfile.authReadytoProcessTime = this.addCandidateForm.value.authReadytoProcessTime : null;
        this.addCandidateForm.value.authReadytoProcessDt ? this.candidateProfile.authReadytoProcessDt = this.setDateToUTC(new Date(this.addCandidateForm.value.authReadytoProcessDt)) : null;
      }
    } else {
      this.candidateProfile.authorizationType = 'BenefitsBuilder';
      Object.keys(optionalDetail).length ? this.candidateProfile.optionalDetails = optionalDetail : null;
      Object.keys(programDetail).length ? this.candidateProfile.programDetails = programDetail : null;
    }

    /** Authorization Details */
    if (this.userRole === 'file-setup-specialist') {
      this.candidateProfile.authorizationDetails = {
        //clientId: this.client.clientId,
        cciPartyId: this.addCandidateForm.value.authorizationDetails.cciDetails ?
          this.addCandidateForm.value.authorizationDetails.cciDetails.id : '',
        currency: this.addCandidateForm.value.authorizationDetails.currency ?
          this.addCandidateForm.value.authorizationDetails.currency : {},
        contractDetails: this.addCandidateForm.value.authorizationDetails.contractDetails ?
        this.addCandidateForm.value.authorizationDetails.contractDetails : {},
      };
    }
    //if condition adding usageType = Personal
    if (this.data.associateTransferee) {
      (this.data.candidate.overWriteEmail || (!this.data.candidate.overWriteEmail && this.addCandidateForm.value.email.findIndex(ele => ele.usageType === 'Business') == 0  &&
        this.addCandidateForm.value.email.find(ele => (ele.usageType === 'Business' || ele.usageType==='Personal')).emailAddress !== this.data.candidate.emailDetailsList[0].emailAddress)) ? this.candidateProfile.overWriteEmail = true : null;

      this.checkPhoneOverWriteFlag();
      
      this.checkFullNameOverWriteFlag();

      (this.data.candidate.overWriteEmpId || (!this.data.candidate.overWriteEmpId &&
        (this.data.candidate.optionalDetails && this.data.candidate.optionalDetails.employeeID && this.data.candidate.optionalDetails.employeeID !== this.addCandidateForm.value.optionalDetails.employeeID))) ? this.candidateProfile.overWriteEmpId = true : null;
    }

    /**Additional Details(configurable Fields) */
    if (this.configurableFields && this.configurableFields.length > 0) {
      let additionalDetails: AdditionalDetail[] = Object.entries(this.additionalDetailsFormGroup.value).map(([key, value]) => ({ displayName: key, value: value })) as AdditionalDetail[];
      additionalDetails = additionalDetails.map((ele: any) => {
        let matchedField = this.configurableFields.find(curr => curr.displayName === ele.displayName);
        if (matchedField.type === 'Date' && moment(ele.value).isValid()) {                // to handle Date conversions
          ele.value = moment(ele.value).format('YYYY-MM-DD HH:mm:ss');
        }
        return ele;
      });
      additionalDetails = additionalDetails.filter(ele => {
        let matchedField = this.configurableFields.find(curr => curr.displayName === ele.displayName);
        let defaultValue:any = fieldMapping.find(curr => curr.DataItemTypDescr === matchedField.type).defaultValue;
        if(matchedField.type === 'Currency') {
          return !(ele.value.currencyAmount === defaultValue.currencyAmount || ele.value.currencyAmount === null || defaultValue.currencyAmount === undefined)
        }
        return !(ele.value === defaultValue || ele.value === null || ele.value === undefined);
      });
      additionalDetails.length && !(this.editCandidateProfile && ['Invitation Sent','Authorization Initiated'].includes(this.editCandidateProfile.moveStatus)) ? this.candidateProfile.additionalDetails = additionalDetails : null;
    }

    /**to associate move details to existing transferee */
    if(this.data.candidatePartyId) {
      this.candidateProfile.candidatePartyId = this.data.candidatePartyId;
    }

    /** Existing candidate Details */
    if (editData) {
      (editData.orderRequestId) ? this.candidateProfile.orderRequestId = editData.orderRequestId : null;
      (editData.moveStatus) ? this.candidateProfile.moveStatus = editData.moveStatus : null;
      (editData.moveStatusDate) ? this.candidateProfile.moveStatusDate = editData.moveStatusDate : null;
    }
    return this.candidateProfile;
  }

  checkPhoneOverWriteFlag() {
    if(this.data.candidate.overWritePhone) {
      return this.candidateProfile.overWritePhone = true;
    } else {
      const code = this.addCandidateForm.controls.phone.value.findIndex( code =>
        code.type == 'Mobile' && code.phoneDialCode == this.data.candidate.phoneDetailsList[0].phoneDialCode);
      const phone =this.addCandidateForm.controls.phone.value.findIndex( phone =>
        phone.type == 'Mobile' && phone.phoneNumber == this.data.candidate.phoneDetailsList[0].phoneNumber);
      return (code < 0 || phone < 0) ? this.candidateProfile.overWritePhone = true  : (code !== phone) ? this.candidateProfile.overWritePhone = true : null;
    }
  }

  checkFullNameOverWriteFlag() {
    if(this.data.candidate.overWriteName) {
      return this.candidateProfile.overWriteName = true;
    } else {
      return (!this.data.candidate.overWriteName &&
        (this.addCandidateForm.value.nameDetails.firstName !== this.data.candidate.nameDetails.firstName || this.addCandidateForm.value.nameDetails.lastName !== this.data.candidate.nameDetails.lastName)) ? this.candidateProfile.overWriteName = true : null;
    }
  }

  /**
   * Save candidate create/update
   * @param type notifies whether to add/edit
   */
  saveCandidate(data) {
    const candidateServiceSave = this.candidateProfileService.InitiateUnprocessedTransferee(this.candidateProfile);
    candidateServiceSave.subscribe(
      (response) => {
        const ssoErr = (typeof (response) == 'string' && response.includes("SSO")) ? true : false;
        this.spinner.hide();
        if (ssoErr) {
          this.showSSOApiErr = response;
          this.addCandidateForm.get('ssoIdentifier').setErrors({ 'incorrect': true })
        } else if (response && !ssoErr) {
          this.flashAndCloseDialog(sendInviteResponse, true, 'sendInvite');
        } else {
          console.log('Send invite failed');
        }

      },
      err => {
        this.spinner.hide();
        this.dialogRef.close();
      }
    );
  }

  /**
   * Invoked on click of dismiss/close
   */
  onDismiss(evt) {
    evt.preventDefault();
    if (this.addCandidateForm.dirty || JSON.stringify(this.initialFormValue) != JSON.stringify(this.addCandidateForm.value)) {
      const dialogRef = this.dialog.open(WarningDialogComponent, {
        id: 'dialog2',
        width: 'auto',
        data: {
          message: this.dismissWarningMsg
        },
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result.action === 'dismiss') {
          this.addCandidateForm.reset();
          this.dialogRef.close();
        }
      });
    } else {
      this.addCandidateForm.reset();
      this.dialogRef.close();
    }
  }

  /**
   * function to flash toastr with status message and close dialog on success
   *@param response string
   */
  flashAndCloseDialog(message: string, success, type?: string ) {
    if (success) {
      this.toastrService.info(message, null, {
        closeButton: true,
        enableHtml: true,
        disableTimeOut: false // User must explicitly dismiss error messages
      });
      this.live.announce(message);
      this.dialogRef.close({
        action: 'close'
      });
    } else {
      this.toastrService.error(message, null, {
        closeButton: true,
        enableHtml: true,
        disableTimeOut: false // User must explicitly dismiss error messages
      });
      this.dialogRef.close();
    }
  }

  getProgram(division) {
    if (this.userRole === 'file-setup-specialist') {
      this.candidateProfileService.getDivision(division).subscribe(res => {
        res.length > 0 ? this.cciDetails = res : this.cciDetails = [];
        this.filteredClientContactDetails = this.addCandidateForm.get('authorizationDetails.cciDetails').valueChanges
        .pipe(
        startWith(''),
        map(value => this.clientContact_filter(value))
        );
          if (this.editCandidateProfile && this.editCandidateProfile.authorizationDetails && this.editCandidateProfile.authorizationDetails.cciPartyId && this.addCandidateForm ) {
          this.addCandidateForm.get('authorizationDetails.cciDetails').setValue(this.cciDetails.find( cci =>
            cci.id === this.editCandidateProfile.authorizationDetails.cciPartyId
          ));
        }
          this.filteredClientContactDetails = this.addCandidateForm.get('authorizationDetails.cciDetails').valueChanges
          .pipe(
          startWith(''),
          map(value => this.clientContact_filter(value))
          );
          this.spinner.hide();
        });
        if(this.addCandidateForm) {
          (this.data.candidate && this.data.candidate.authorizationDetails) || (this.userRole === 'file-setup-specialist' && !this.data.candidate) ? this.addCandidateForm.get(['authorizationDetails', 'contractDetails']).setValue(null) : null;
        }
        (this.addCandidateForm && !this.data.isMMUser) ? this.addCandidateForm.get(['programDetails', 'points']).setValue(null): null;
        (this.addCandidateForm && this.data.isMMUser) ? this.addCandidateForm.get(['programDetails', 'totalAmount']).setValue(null): null;
        (this.addCandidateForm && this.data.isMMUser) ? this.addCandidateForm.get(['programDetails', 'currency']).setValue(''): null;
        this.addCandidateForm ? this.addCandidateForm.get(['programDetails', 'programName']).setValue('') : null;
    }
      !this.isTraditional ? this.candidateProfileService.getProgram(division)
        .subscribe(res => {
          this.programValues = res.cc_divisionPrograms;
          if (this.editCandidateProfile && this.editCandidateProfile.programDetails) {
            const program = this.programValues.filter((item) => item.programName === this.editCandidateProfile.programDetails.programName);
            this.userRole === 'file-setup-specialist' && program.length ? this.candidateProfileService.getContractListBB(program[0].programId).subscribe( res => {
              if(res){
                this.contractListProcess(res);
              }
            }): null;
            program.length ?this.programManagementService.getClientPoliciesBB(program[0].programId).subscribe( res => {
              this.policyValues = res.policies;
            }) : null
            if (this.userRole === 'file-setup-specialist' && this.editCandidateProfile.programDetails.programName && this.contractOriginalList) {
              this.contractList = this.contractOriginalList.filter(ele => ele.baseContract === program[0].contractId);
              if (this.contractList.length > 0) {
                this.contractList.forEach((ele, index) => {
                  this.contractList[index] = {'contractDetails': ele.contractDetails};

                });
              }

              if(this.editCandidateProfile.authorizationDetails.contractDetails.contractName) {
                this.billiingCurrency = [...this.contractOriginalList.find(ele => ele.contractDescription === this.editCandidateProfile.authorizationDetails.contractDetails.contractName).billingCurrencies];
                this.billiingCurrency.forEach((billCur, index) => {
                  let currencyValue = this.currencyList.find(cur => cur.currency && cur.currency.code === billCur);
                  this.billiingCurrency[index] = currencyValue;
                });
              }
            }
          }
          this.userRole !== 'file-setup-specialist' ? this.spinner.hide() : null;
        }) : null;
  }

  getContractBaseDetails(event) {
    if (event.value) {
      let data = this.contractOriginalList.find(ele => ele.contractDescription === event.value.contractName && ele.contractId === event.value.contractId);
      data && data.hasOwnProperty('moveTypes') ? this.moveTypeValues = [...data.moveTypes] : this.moveTypeValues = [];
      data && data.hasOwnProperty('billingCurrencies') ? this.billiingCurrency = [...data.billingCurrencies] : this.billiingCurrency = [];
      this.billiingCurrency.forEach((billCur, index) => {
        let currencyValue = this.currencyList.find(cur => cur.currency && (cur.currency.code === billCur || (billCur.currency && cur.currency.code === billCur.currency.code)));
        this.billiingCurrency[index] = currencyValue;
      });
      this.billingCurrencyValueChange();
      if (this.isTraditional && this.userRole === 'file-setup-specialist') {
        this.addCandidateForm.get(['authorizationDetails', 'moveType']).setValue('');
        this.addCandidateForm.get(['authorizationDetails', 'contractDetails']).setValue(event.value);
      }
      this.addCandidateForm.get(['authorizationDetails', 'currency']).setValue(null);
    }
  }

  editPointValidation() {
    // tslint:disable-next-line: triple-equals
    let message: string;
    if (this.addCandidateForm.value.programDetails.points != '' && (this.addCandidateForm.value.programDetails.points > 0 && !this.data.isMMUser ) || (this.addCandidateForm.value.programDetails.points == 0 && !this.data.isMMUser ))  {
      this.editPoint = !this.editPoint;
      if (!this.editPoint) {
        if (+this.points * 0.25 + +this.points <= +this.addCandidateForm.value.programDetails.points) {
          message = 'You have entered points exceeding 25% of Program standard';
          this.toastrService.info(message, null, {
            closeButton: true,
            enableHtml: true,
            disableTimeOut: false
          });
        } else if (+this.points - +this.points * 0.1 >= +this.addCandidateForm.value.programDetails.points) {
          message = 'You have reduced Program standard points by 10% or more';
          this.toastrService.info(message, null, {
            closeButton: true,
            enableHtml: true,
            disableTimeOut: false
          });
        }
        if (this.addCandidateForm.value.programDetails.points !== '' && this.addCandidateForm.value.programDetails.points !== this.points) {
          this.addCandidateForm.value.programDetails.pointOverride = true;
        } else {
          this.addCandidateForm.value.programDetails.pointOverride = (this.data.candidate.programDetails && this.data.candidate.programDetails.hasOwnProperty('pointOverride')) ? this.data.candidate.programDetails.pointOverride : false;
        }
      }
    } else if (this.addCandidateForm.value.programDetails.totalAmount != '' && this.addCandidateForm.value.programDetails.totalAmount > 0 && this.data.isMMUser) {
      this.editPoint = !this.editPoint;
      if (!this.editPoint) {
        if (+this.amount * 0.25 + +this.amount <= +this.addCandidateForm.value.programDetails.totalAmount) {
          message = 'You have entered amount exceeding 25% of Program standard';
          this.toastrService.info(message, null, {
            closeButton: true,
            enableHtml: true,
            disableTimeOut: false
          });
        } else if (+this.amount - +this.amount * 0.1 >= +this.addCandidateForm.value.programDetails.totalAmount) {
          message = 'You have reduced Program standard amount by 10% or more';
          this.toastrService.info(message, null, {
            closeButton: true,
            enableHtml: true,
            disableTimeOut: false
          });
        }

        if (this.addCandidateForm.value.programDetails.totalAmount !== '' && this.addCandidateForm.value.programDetails.totalAmount !== this.amount) {
          this.addCandidateForm.value.programDetails.amountOverride = true;
        } else {
          this.addCandidateForm.value.programDetails.amountOverride = this.data.candidate.programDetails.amountOverride ? this.data.candidate.programDetails.amountOverride : false;
          this.amount = this.addCandidateForm.value.programDetails.totalAmount;
        }

      }
    }
  }

  setPoints(event) {
    let program;
    program = this.programValues.find(prog => prog.programName === event.value);
    if (this.data.isMMUser) {
      this.amount = program.totalAmount;
      this.budgetCurrency = program.currency;
    } else if (!this.data.isMMUser) {
      this.points = program.points;
    }
    this.userRole === 'file-setup-specialist' ? this.candidateProfileService.getContractListBB(program.programId).subscribe( res => {
              if(res){
                this.contractListProcess(res);
              }
    }): null;
    this.programManagementService.getClientPoliciesBB(program.programId).subscribe( res => {
      this.policyValues = res.policies;
    })
    if(this.addCandidateForm){
    if (this.data.isMMUser) {
      this.addCandidateForm.get(['programDetails', 'totalAmount']).setValue(this.amount);
      this.addCandidateForm.get(['programDetails', 'currency']).setValue(this.budgetCurrency);
    } else if (!this.data.isMMUser) {
      this.addCandidateForm.get(['programDetails', 'points']).setValue(this.points);
    }
    program.coreOfferings ? this.addCandidateForm.get(['programDetails', 'coreOfferings']).setValue(program.coreOfferings) : undefined;
      if (this.userRole === 'file-setup-specialist' && this.contractOriginalList) {
        this.contractList = this.contractOriginalList.filter(ele => ele.baseContract === program.contractId);
        if (this.contractList.length > 0) {
          this.contractList.forEach((ele, index) => {
            this.contractList[index] = {'contractDetails': ele.contractDetails};

          });
        }
        this.addCandidateForm.get(['authorizationDetails', 'currency']).setValue({});
        this.addCandidateForm.get(['authorizationDetails', 'contractDetails']).setValue(null);
      }
    }
  }


  /**billing currency value change update */
  billingCurrencyValueChange() {
    this.filteredCurrency = this.addCandidateForm.get('authorizationDetails.currency').valueChanges
      .pipe(
        startWith(''),
        map(value => this.currency_filter(value))
      );
  }

  phoneCodeValueChange() {
    this.addCandidateForm.get('phone')['controls'].forEach((phCode, index) => {
      this.filteredOptions = this.addCandidateForm.get('phone')['controls'][index].controls.phoneDialCode.valueChanges
        .pipe(
          startWith(''),
          map(value => this._filter(value))
        );
    });
  }

  /**to display for traditional optional Default Home Country,Default Host Country,
   * Assignment Start Date, Assignment End Date if destination/departure country is not US
    */
   displayDefaultCountry() {
    let destCountry = this.addCandidateForm.value.addressForm.destinationAddr.country? this.addCandidateForm.value.addressForm.destinationAddr.country
                      : this.initialFormValue.addressForm.destinationAddr? this.initialFormValue.addressForm.destinationAddr.country: null;
    let deprCounntry = this.addCandidateForm.value.addressForm.departureAddr.country? this.addCandidateForm.value.addressForm.departureAddr.country
                      : this.initialFormValue.addressForm.departureAddr? this.initialFormValue.addressForm.departureAddr.country: null;
    if((destCountry && destCountry !== 'United States') || (deprCounntry && deprCounntry !== 'United States')) {
      this.isUS_SelectedCheck = true;
      return true;
    }
    this.isUS_SelectedCheck = false;
    return false;
  }

  displayJobStartDateTraditional() {
    let destCountry = this.addCandidateForm.value.addressForm.destinationAddr.country? this.addCandidateForm.value.addressForm.destinationAddr.country
                      : this.initialFormValue.addressForm.destinationAddr? this.initialFormValue.addressForm.destinationAddr.country: null;
    let deprCounntry = this.addCandidateForm.value.addressForm.departureAddr.country? this.addCandidateForm.value.addressForm.departureAddr.country
                      : this.initialFormValue.addressForm.departureAddr? this.initialFormValue.addressForm.departureAddr.country: null;
    if((destCountry && destCountry === 'United States') && (deprCounntry && deprCounntry === 'United States')) {
      this.isUS_Move = true;
      return true;
    }
    this.isUS_Move = false;
    return false;
  }

  setDateToUTC(date:Date){
    return date.toISOString().split('T')[0].concat('T00:00:00.000Z');
  }

  private _generateEmailGroup(emailDetails: Array<emailDetails>): Array<FormGroup> {
    const fb = this.injector.get(FormBuilder);
    const isNull = this.isNullCheck;
    if (emailDetails.length > 0) {
      emailDetails.forEach(mail => {
        mail.usageType === 'business' ? mail.usageType = 'Business': mail.usageType === 'personal'? mail.usageType = 'Personal': null;
      });
      const emailDetailsArray = emailDetails.map(emailDetail => {
        return fb.group({
          _id: [emailDetail._id? isNull(emailDetail, '_id'): null],
          emailAddress: [isNull(emailDetail, 'emailAddress'), this.userRole === 'client-contact-initiator' || this.userRole === 'file-setup-specialist' ? [Validators.required,
            Validators.pattern('^[A-Za-z0-9!#$%&\'*+/=?^_‘{|}~-]+(?:\\.[A-Za-z0-9!#$%&\'*+/=?^_‘{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])$')]: null],
          // tslint:disable-next-line: rxjs-no-unsafe-scope
          usageType: [emailDetail.usageType? isNull(emailDetail, 'usageType'): '', this.userRole === 'client-contact-initiator' || this.userRole === 'file-setup-specialist' ? [Validators.required]: null],
        });
      });

      return emailDetailsArray;
    }
    else {
      const emailDetailsArray: Array<FormGroup> = [
        fb.group({
        emailAddress: [null, [Validators.required, Validators.pattern('^[A-Za-z0-9!#$%&\'*+/=?^_‘{|}~-]+(?:\\.[A-Za-z0-9!#$%&\'*+/=?^_‘{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])$')]],
        usageType: [null, [Validators.required]]
        })
      ]
      return emailDetailsArray;
    }
  }

  _generatePhoneGroup(phoneDetails: Array<phoneDetails>): Array<FormGroup> {
      const fb = this.injector.get(FormBuilder);
      const isNull = this.isNullCheck;
      if (phoneDetails.length > 0) {
        phoneDetails = [...phoneDetails];
        phoneDetails.forEach((phoneDetail, index) => {
          let phoneType = null;
          (phoneDetail.textingAvailable && phoneDetail.primary) ? phoneDetail['type'] = 'Mobile' : null;

          if (!phoneDetail.textingAvailable) {
            (phoneDetail.usageType == 'business' && phoneDetail.locationType == 'departure') ? phoneDetail['type'] = 'Departure Business' : null;
            (phoneDetail.usageType == 'business' && phoneDetail.locationType == 'destination') ? phoneDetail['type'] = 'Destination Business' : null;
            (phoneDetail.usageType == 'personal' && phoneDetail.locationType == 'departure') ? phoneDetail['type'] = 'Departure Residence' : null;
            (phoneDetail.usageType == 'personal' && phoneDetail.locationType == 'destination') ? phoneDetail['type'] = 'Destination Residence' : null;
          }

          delete phoneDetail.usageType;
        });

        const phoneDetailsArray: Array<FormGroup> = phoneDetails.map(phoneDetail => {
          return fb.group({
            _id: [phoneDetail._id? isNull(phoneDetail, '_id'): ''],
            type: [isNull(phoneDetail, 'type'), [Validators.required]],
            phoneDialCode: [isNull(phoneDetail, 'phoneDialCode'), [Validators.required]],
            // tslint:disable-next-line: rxjs-no-unsafe-scope
            phoneNumber: [isNull(phoneDetail, 'phoneNumber'), [Validators.required, Validators.minLength(7),
              Validators.maxLength(18), Validators.min(1000000), Validators.max(999999999999999999),
              Validators.pattern('^[0-9]*$')]]
          });
        });
        return phoneDetailsArray;
      } else {
        // to handle when phone data is not there defaulting to mobile type ( to handle cci )
        const phoneDetailsArray: Array<FormGroup> = [
          fb.group({
            type: ['Mobile', [Validators.required]],
            phoneDialCode: ['', [Validators.required]],
            // tslint:disable-next-line: rxjs-no-unsafe-scope
            phoneNumber: ['', [Validators.required, Validators.minLength(7),
            Validators.maxLength(18), Validators.min(1000000), Validators.max(999999999999999999),
            Validators.pattern('^[0-9]*$')]]
          })
        ]
        return phoneDetailsArray;
      }
    }

  addNewRow(type: 'phone' | 'email', newIndex: number, contactForm: FormGroup) {
    this.addEditSharedService.addNewPhoneEmailRow(type, contactForm, newIndex, this.phoneTypes, this.emailTypes);
    type === 'phone'? this.phoneCodeValueChange(): (((this.addCandidateForm.controls.email as FormArray).controls[0] as FormGroup).controls.usageType).setErrors(null);
  }

  /**to get phone drop down values dynamically */
  getPhoneTypes(curIndex) {
    let phoneTypesList= [];
    let existingPhoneTypes: Array<string> = ((this.addCandidateForm.controls.phone as FormArray).
      getRawValue() as any).map((ph, index) => ph.type);

      existingPhoneTypes = existingPhoneTypes.filter((ph, index) => index !== curIndex);

      // updating emailTypes
      phoneTypesList[curIndex] = [...phoneTypes];
      phoneTypesList[curIndex] = phoneTypesList[curIndex].filter((ph, index) => !existingPhoneTypes.includes(ph));
      this.phoneTypes[curIndex] = phoneTypesList[curIndex];
  }

  /**to get email drop down values dynamically */
  getEmailTypes(curIndex) {
    (!this.isNewHire && this.data.ssoRegEx.ssoRegexErrorMessage) ? this.checkSSOValidation() : null;
    let emailTypesList = [];
    let existingEmailTypes: Array<string> = ((this.addCandidateForm.controls.email as FormArray).
      getRawValue() as any).map((email, index) => email.usageType);
    existingEmailTypes = existingEmailTypes.filter((email, index) => index !== curIndex);
    // updating emailTypes
    emailTypesList[curIndex] = [...emailTypes];
    emailTypesList[curIndex] = emailTypesList[curIndex].filter((email, index) => !existingEmailTypes.includes(email));
    this.emailTypes[curIndex] = emailTypesList[curIndex];
  }
  /**
 * Remove row from Mobile or Email
 * @param type location of the row
 * @param index index of the location
 */
  deleteRow(type: 'phone' | 'email', index: number, contactForm: FormGroup, event) {
      (type === 'phone') ?
        this.phoneTypes = this.addEditSharedService.deletePhoneEmailRow(type, index,
          contactForm, this.phoneTypes, this.emailTypes) :
          event.pointerType !== "" ? this.emailTypes = this.addEditSharedService.deletePhoneEmailRow(type, index,
          contactForm, this.phoneTypes, this.emailTypes): null;
      type === 'phone' ? this.phoneCodeValueChange() : this.checkForBusinessEmail();
  }

  setSSONewHire(check) {
    this.isNewHire = check;
    this.checkSSOValidation();
  }

  checkSSOValidation(emailVal?: string): ValidatorFn {
    if(!this.isNewHire && this.data.ssoRegEx.ssoRegexErrorMessage) {
      this.checkForBusinessEmail(emailVal? emailVal: '');
    }
    if(this.data.candidate.ssoIdentifier) {	
      this.addCandidateForm.get('ssoIdentifier').markAsTouched();	
      this.addCandidateForm.get('ssoIdentifier').markAsDirty();	
    }
    const ssoControl = this.addCandidateForm.get('ssoIdentifier');
    this.data.ssoRegEx.ssoRegex && !this.isNewHire? ssoControl.setValidators([Validators.pattern(atob(this.data.ssoRegEx.ssoRegex))]): null;
    if(this.showSSOApiErr) {
      ssoControl.setErrors({'incorrect': true})
    }
    if (!this.isNewHire && this.data.ssoRegEx.ssoRegex && (((ssoControl.value && ssoControl.value.match(atob(this.data.ssoRegEx.ssoRegex)) == null)) || ssoControl.value == null || ssoControl.value =="")
        || !(/\S/.test(ssoControl.value))) {
      ssoControl.setErrors(require);
      ssoControl.setValidators([Validators.required]);
    } else
    if(this.isNewHire) {
      this.addCandidateForm.get('ssoIdentifier').clearValidators();
      this.addCandidateForm.get('ssoIdentifier').setErrors(null);
      ((this.addCandidateForm.controls.email as FormArray).controls[0] as FormGroup).controls.usageType.setErrors(null);
    }
    return;
  }

  checkForBusinessEmail(emailVal?: string) {
    const emailValues = (this.addCandidateForm.controls.email as FormGroup).value;
    if(emailValues.length == 2) {
      this.disableSaveDraftForSSO = false;
      (((this.addCandidateForm.controls.email as FormArray).controls[0] as FormGroup).controls.usageType).setErrors(null);
      return ;
    }
    if(emailValues.findIndex(mail => mail.usageType === "Business") !== 0 || emailVal === 'Personal' && !this.isNewHire) {
      (((this.addCandidateForm.controls.email as FormArray).controls[0] as FormGroup).controls.usageType).markAsTouched();
      (((this.addCandidateForm.controls.email as FormArray).controls[0] as FormGroup).controls.usageType).setErrors({'incorrect': true});
      // return true;
      this.disableSaveDraftForSSO = true;
    } else
        this.disableSaveDraftForSSO = false;
  }

  ssoChange(value) {
    this.showSSOApiErr = null;
    this.checkSSOValidation();
  }

  toggle() {
    this.isNewHire = !this.isNewHire;
    this.checkSSOValidation();
  }

  checkEmailValueChangeForSSO(value) {
    if(this.showSSOApiErr &&this.showSSOApiErr.includes('Business email')) {
      this.showSSOApiErr = null;
      this.addCandidateForm.get('ssoIdentifier').setErrors(null);
    }
  }

  checkAuthValidation() {
    if(this.userRole === 'file-setup-specialist') {
      const cur = this.addCandidateForm.get(['authorizationDetails','currency']).value
      return cur == {} || cur == null ? true : false
    }
    return false
  }

  goToLink() {
    var newWindow = window.open('/#/original-submission','_blank');
    newWindow.localStorage.setItem('view-org', 'true');
    newWindow['data'] = this.submissionFailureData
  }
}



