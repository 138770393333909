import { Component, OnInit, Output, EventEmitter, OnDestroy, ViewEncapsulation } from '@angular/core';
import { FormControl, SelectControlValueAccessor } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map, debounceTime, switchMap, finalize } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material';
import { Subject, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { GlobalSearchService } from '../../../core/services/global-search.service';
import { Router, NavigationError, NavigationEnd, NavigationStart } from '@angular/router';
import { stringToKeyValue } from '@angular/flex-layout/extended/typings/style/style-transforms';
import {A11yModule, LiveAnnouncer} from '@angular/cdk/a11y';

export interface User {
  name: string;
  fileNumber: number;
}

@Component({
  selector: 'app-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.scss']
})
export class GlobalSearchComponent implements OnInit, OnDestroy {

  @Output()
  inputFieldFormControl: FormControl = new FormControl();
  // private valueChangesSub: Subscription;
  private userInputTimeout: number;
  private requestSub: Subscription;
  public filteredOptions;
  public responseMessage = '';
  public totalCount = 0;
  public filterText = '';
  public query = '';
  public originalText = '';

  // showMsg is to show/hide the message on UI screen
  public showMsg = false;
  public showCustomMsg = false;
  public responseMessage1 = '';
  public responseMessage2 = '';
  public responseMessage3 = '';
  // showResults is to show/hide the results if the character count is less than 2
  public showResults = false;
  myControl = new FormControl();

  constructor(private http: HttpClient,
              private globalSearchService: GlobalSearchService,
              private router: Router,
              private liveAnnouncer: LiveAnnouncer) {
  }

  getSearchResults(query) {
    /* GLOBAL SEARCH IS ENABLED for 500ms */
      if (this.userInputTimeout) {
        window.clearTimeout(this.userInputTimeout);
      }
      this.userInputTimeout = window.setTimeout(() => {
        this.generateSuggestions(query);
      }, 500);
  }

  ngOnInit() {
    this.myControl.valueChanges.subscribe(
      (value) => {
        this.getSearchResults(value);
      }
    );
    this.clearSearch();
  }

  ngOnDestroy() {
    // clearing the values once user moves away from the page.
  }

  isNullOrWhitespace(strToCheck) {
    const whitespaceChars = '\s';
    return (strToCheck === null || whitespaceChars.indexOf(strToCheck) !== -1);
  }
  public generateSuggestions(q: string) {
    this.filteredOptions = [];
    this.query = q;
    q = q.trim();
    if (q.length === 0 || q === null || q === '') {
          this.showMsg = true;
          this.showCustomMsg = false;
          this.showResults = false;
          this.responseMessage = 'Please enter at least 2 characters';
    } else if (q.length <= 1) {
          this.showMsg = true;
          this.responseMessage = 'Please enter at least 2 characters';
          this.showCustomMsg = false;
          this.showResults = false;
    } else if (q.length > 1) {
      if (this.requestSub) {
        this.requestSub.unsubscribe();
      }
      this.requestSub = this.globalSearchService.getResults(q).subscribe(res => {

        this.totalCount = res.totalCount;

        if (this.totalCount === 0) {
          this.showMsg = true;
          this.showCustomMsg = false;
          this.showResults = false;
          this.filteredOptions = [];
          this.responseMessage = 'There are no matching results, please try again';
        } else if (this.totalCount > 0 && this.totalCount < 101) {
          this.filteredOptions = res.data;
          this.responseMessage = res.message;
          this.showResults = true;
          this.showMsg = false;
          this.showCustomMsg = false;
        } else if (this.totalCount > 100) {
          this.filteredOptions = res.data;
          this.responseMessage = res.message;
          this.showResults = true;
          this.showCustomMsg = true;
          this.showMsg = false;

          const overCount = this.totalCount - 100;

          this.responseMessage1 = 'This has returned too many matching results, please refine your search';
          this.responseMessage2 = 'Below we are showing the first 100 results';
          this.responseMessage3 =  '(' + overCount + ' additional matches are not being shown)';
        }
        });
      }
    }
    onFocus(q: string) {
      q = q.trim();
      if (q.length === 0 || q === null) {
        this.responseMessage = 'Please enter at least 2 characters';
        this.showMsg = true;
        this.showResults = false;
        this.showCustomMsg = false;
        this.liveAnnouncer.announce(this.responseMessage);
      }
    }
    onSelectionChanged(event) {
      this.clearSearch();
      this.filteredOptions = [];
      this.query = '';
      this.router.navigate(['/empInfo/' + event.option.value]);
    }

    clearSearch() {
      this.filterText = '';
      this.query = '';
      this.showMsg = true;
      this.showResults = false;
      this.responseMessage = 'Please enter at least 2 characters';
      this.showCustomMsg = false;
      this.filteredOptions = [];
      this.myControl.setValue('');
    }

    /** announceMessage to use the liveAnnouncer to read the message(s) for screen readers triggered by autocomplete (open) */
    announceMessage(evt): void {
      if (this.showMsg) {
        this.liveAnnouncer.announce(this.responseMessage);
      }
      if (this.showCustomMsg) {
        this.liveAnnouncer.announce(this.responseMessage1 + this.responseMessage2 + this.responseMessage3);
      }
    }
}

