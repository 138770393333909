import { NgModule, InjectionToken } from '@angular/core';
import { Routes, RouterModule, ActivatedRouteSnapshot } from '@angular/router';
import { ContactUsComponent } from './public/components/contact-us/contact-us.component';
import { ExternRouteComponent } from './public/components/extern-route/extern-route.component';
// TODO: import { AuthGuardService } from './core/services/security/auth-guard.service';
import { PageNotFoundComponent } from './public/components/page-not-found/page-not-found.component';
/** external URL provider injector */
const externalUrlProvider = new InjectionToken('externalUrlRedirectResolver');

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'dashboard',
    loadChildren: './public/dashboard/dashboard.module#DashboardModule'
    // canActivate: [AuthGuardService]
  },
  {
    path: 'provider',
    loadChildren: './public/provider/provider.module#ProviderModule'
  },
  {
    path: 'terms-privacy',
    loadChildren: './public/terms-privacy/terms-privacy.module#TermsPrivacyModule'
  },
  {
    path: 'administration',
    loadChildren: './public/administration/administration.module#AdministrationModule'
  },
  {
    path: 'externalRedirect',
    resolve: {
      url: externalUrlProvider
    },
    component: ExternRouteComponent
  },
  {
    path: 'contact-us',
    component: ContactUsComponent
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    data: { navId: 101, title: 'File Not Found', showGlobalTemplate: false }
  }
  // TODO: Page not found
  // {
  //   path: '**',
  //   loadChildren: './public/page-not-found/page-not-found.module#PageNotFoundModule',
  //   canActivate: [AuthGuardService]
  // }
];

@NgModule({
  providers: [
    {
      provide: externalUrlProvider,
      useValue: (route: ActivatedRouteSnapshot) => {
        const externalURL = route.paramMap.get('externalUrl');
        window.location.replace(externalURL);
      }
    },
  ],
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
