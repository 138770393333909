import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CandidateProfileService } from '../../../../services/candidate-profile.service'

@Component({
  selector: 'app-unlock-submitted-high-value-goods-modal',
  templateUrl: './unlock-submitted-high-value-goods-modal.component.html',
  styleUrls: ['./unlock-submitted-high-value-goods-modal.component.scss'],
})
export class UnlockSubmittedHighValueGoodsModalComponent implements OnInit {

  constructor(
    public spinner: NgxSpinnerService,
    public toastr: ToastrService,
    public candidateProfileService: CandidateProfileService,
    public dialogRef: MatDialogRef<UnlockSubmittedHighValueGoodsModalComponent>,
		@Inject(MAT_DIALOG_DATA) public data,
	) { }

  prodId;

  ngOnInit() {
  }

  /**
	* To to close dialogbox
	*/
	close() {
		this.dialogRef.close();
  }
  
  /**
	* To unlock form
	*/
  unlockForm() {
    this.spinner.show();
    this.prodId = this.data.benefit.productId;
    this.candidateProfileService.unlockSubmittedHighValueGoods(this.prodId).subscribe(res => {
        if (res.code === 'HVG_UNLOCK_UPDATE_SUCCESS') {
          this.spinner.hide();
          this.dialogRef.close(res);
          this.toastr.info('High value goods successfully unlocked');
        } else {
          this.spinner.hide();
          this.toastr.error('Error while unlocking high value goods');
        }
    });

  }
}
