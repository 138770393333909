import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MatTableDataSource, MAT_DIALOG_DATA,MatDialog } from '@angular/material';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Country } from '../../../../../../../src/app/core/models/country.model'
import { CandidateProfileService } from '../../../services/candidate-profile.service';
import { ClientService } from '../../../services/client.service';
import { LocationsService } from '../../../services/locations.service';
import { DuplicateRecordsDeletePopupComponent } from '../duplicate-records-delete-popup/duplicate-records-delete-popup.component';

@Component({
  selector: 'app-duplicate-check',
  templateUrl: './duplicate-check.component.html',
  styleUrls: ['./duplicate-check.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DuplicateCheckComponent implements OnInit {
  @Output() nextStep = new EventEmitter<any>();
  dupCheckForm: FormGroup;
  /**Countries retrieved from service */
  countryList: Array<Country> = [];
  /** List of filtered options by autocomplete phone code field */
  filteredOptions: Observable<Country[]>;
  titleValues: string[] = ['Dr.', 'Mr.', 'Mrs.', 'Ms.', 'Miss'];

  displayedColumns: string[] = ['fullName', 'email', 'departureAddr', 'destinationAddr', 'add'];
  dataSource = new MatTableDataSource();

  showTable: boolean = false;
  title: string;
  fName: string;
  lName: string;
  Email: any;
  PhoneCode: any;
  PhoneNumber: number;

  /**data to pass to review comp */
  isMMUser: boolean = false;
  isGOUser: boolean = false;
  ssoRegEx: any = { isSSO: false};

  constructor(
    public dialog:MatDialog,
    private readonly formBuilder: FormBuilder,
    private readonly locationService: LocationsService,
    public dialogRef: MatDialogRef<DuplicateCheckComponent>,
    private candidateProfileService: CandidateProfileService,
    @Inject (MAT_DIALOG_DATA) public data: any,
    private clientService: ClientService,
  ) { }

  ngOnInit() {
    this.fName = this.data.candidate.nameDetails.firstName ? this.data.candidate.nameDetails.firstName : '';
    this.lName = this.data.candidate.nameDetails.lastName ? this.data.candidate.nameDetails.lastName : '';
    this.Email = this.data.candidate.emailDetailsList[0].emailAddress ? this.data.candidate.emailDetailsList[0].emailAddress : '';
    this.PhoneCode = this.data.candidate.phoneDetailsList[0].phoneDialCode ? this.data.candidate.phoneDetailsList[0].phoneDialCode : '';
    this.PhoneNumber = this.data.candidate.phoneDetailsList[0].phoneNumber? this.data.candidate.phoneDetailsList[0].phoneNumber: '';

    this.dupCheckForm = this.formBuilder.group({
      firstName: [this.fName, [Validators.required,]],
      lastName: [this.lName, [Validators.required,]],
      EmployeeName: [],
      email: [this.Email, [Validators.required, Validators.email, Validators.pattern('^[A-Za-z0-9!#$%&\'*+/=?^_‘{|}~-]+(?:\\.[A-Za-z0-9!#$%&\'*+/=?^_‘{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])$')]],
      title: [this.data.candidate.nameDetails.title ? this.data.candidate.nameDetails.title : ''],
      phoneDialingCode: [this.PhoneCode, [Validators.required, Validators.pattern('^[0-9]*$')]],
      phoneNumber: [this.PhoneNumber, [Validators.required, Validators.maxLength(18), Validators.minLength(7),
      Validators.pattern('^[0-9]*$'), Validators.max(999999999999999999)]],
    });

    this.locationService.countryList.subscribe(countryList => {
      this.filteredOptions = this.dupCheckForm.get('phoneDialingCode').valueChanges
        .pipe(
          startWith(''),
          map(value => this._filter(value))
        );
      if (countryList.length > 0) {
        countryList.sort((a, b) => a.countryName.localeCompare(b.countryName));
        this.countryList = countryList;
      }
    });
    this.checkForDuplicates();
    this.checkRole()
  }

  checkRole() {
    this.clientService.getBbRole(this.data.candidate.client.clientId).subscribe(res => {
      if (res) {
        res.loginUserRoleCapabilities.length ?
          res.loginUserRoleCapabilities.forEach(element => {
            if (element.clientRoles[0].managedMove ) {
              this.isMMUser = true;
            } 
            if (element.clientRoles[0].pointsMatrix) {
              this.isGOUser = true;
            }         
            if(element.clientRoles[0].sso)  
              this.ssoRegEx = {
                isSSO : true,
                ssoRegex : element.clientRoles[0].ssoRegex,
                ssoRegexErrorMessage : element.clientRoles[0].ssoRegexErrorMessage
              }    
          }) : null;
      }
    });
  }

  private _filter(value): Country[] {
    if (value) {
      const filterValue = typeof value === 'number' ? value : value.toLowerCase();
      const countryList = this.countryList.length ? this.countryList.filter(option => option.countryName.toLowerCase().indexOf(filterValue) === 0) : [];
      return countryList;
    } else {
      return this.countryList;
    }
  }

  displayFn(country: any): string {
    return country ? '+ ' + country : '';
  }

  onDismiss(evt) {
    evt.preventDefault();
    this.dialogRef.close(null)
  }

  checkForDuplicates() {
    this.dataSource.data = [];
    this.dupCheckForm.disable();
    let reqObj = this.createObj()
    this.candidateProfileService.getCandidateCheck(reqObj).subscribe(data => {
      this.dataSource.data = data;
      if(this.dataSource.data.length) {
         this.showTable = true;
      }
    });
  }

  onCreate(isNew, candidate?) {
    let reqObj = {};
    reqObj['validationMessages'] = this.data.candidate.validationMessages
    reqObj['ssoRegEx'] = this.ssoRegEx;
    reqObj['isMMUser'] = this.isMMUser;
    reqObj['isGOUser'] = this.isGOUser;
    reqObj['isTraditional'] = this.data.candidate.authorizationType == 'BenefitsBuilder' ? false : true;
    reqObj['authorizationDate'] = this.data.candidate.authorizationDate;
    reqObj['client'] = this.data.candidate.client
    if (isNew) {
      reqObj['candidate'] = this.data.candidate
      this.candidateProfileService.submissionFailureListSubject.next(reqObj);
    } else {
      reqObj['associateTransferee'] = true;
      reqObj['candidate'] = this.data.candidate;
      reqObj['candidate']['nameDetails'] = {
        title: candidate.title,
        firstName: candidate.name[0],
        lastName: candidate.name[1]
      }
      reqObj['candidate']['emailDetailsList'][0] = {
        emailAddress: candidate.emailDetailsList[0].emailAddress,
        primary:  candidate.emailDetailsList[0].primary,
        usageType:  candidate.emailDetailsList[0].usageType
      }
      reqObj['candidate']['phoneDetailsList'][0] = {
        phoneDialCode: candidate.phoneDetailsList[0].phoneDialCode,
        phoneNumber: candidate.phoneDetailsList[0].phoneNumber,
        primary: candidate.phoneDetailsList[0].primary,
        textingAvailable: candidate.phoneDetailsList[0].textingAvailable,
        usageType: candidate.phoneDetailsList[0].usageType
      }
      this.candidateProfileService.submissionFailureListSubject.next(reqObj);
    }
    this.nextStep.emit();
  }

  createObj() {
    return {
      name: {
        title: this.dupCheckForm.get('title').value,
        firstName: this.dupCheckForm.get('firstName').value,
        lastName: this.dupCheckForm.get('lastName').value
      },
      contact: {
        phoneDialingCode: '+' + this.dupCheckForm.get('phoneDialingCode').value,
        phoneNumber: this.dupCheckForm.get('phoneNumber').value,
        email: this.dupCheckForm.get('email').value
      },
      clientId: this.data.candidate.clientId
    }
  }
  // changes for popup window
openDialog(message){
  const dialogRef = this.dialog.open(DuplicateRecordsDeletePopupComponent,{
    
    data:{
      id:this.data.candidate._id,
      firstName:this.data.candidate.nameDetails.firstName,
      lastName:this.data.candidate.nameDetails.lastName
    }
  });

  dialogRef.afterClosed().subscribe(response => {
    if (response.action === 'dismisWindow') {
      this.dialog.closeAll();
    }
  });
}
}



