import { Injectable } from '@angular/core';
import { BaseClientService } from './base-client.service';
import { RemoteLoggingService } from './remote-logging.service';
import { Observable, of, empty } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { EmployeeDetailsView } from './../models/employee-details-view.model';
import { AggregationFetchparameter } from '../models/aggregation-fetch-parameter.model';
import { EmployeeInfo } from '../models/employee-info.model';
import { HttpParams } from '@angular/common/http';

/** EmployeeDetailsService to fetch the data from api and display in a table */
@Injectable({
  providedIn: 'root'
})
export class EmployeeDetailsService {
  /** Base constructor method
   * @param baseClient BaseClientService injection
   * @param logSvc RemoteLoggingService injection
   */
  constructor(
    protected baseClient: BaseClientService,
    private readonly logSvc: RemoteLoggingService
  ) { }

  /** getEmployeeDetails method to get the reponse from the api using base client service */
  getEmployeeDetails(aggregationFetchparameter: AggregationFetchparameter): Observable<EmployeeDetailsView> {
    let filters: any;

    if ('filters' in aggregationFetchparameter && aggregationFetchparameter.filters.favorites == null) {
      aggregationFetchparameter.filters.favorites = '';
    }

    if (aggregationFetchparameter.filters) {
      filters = (aggregationFetchparameter.filters);
    }
    let text = '';
    if (aggregationFetchparameter.searchText) {
      text = aggregationFetchparameter.searchText;
    }

    // let sortColumnName = 'lastName';
    // let sortOrder = 'asc';

    if (!('sortObject' in aggregationFetchparameter) || aggregationFetchparameter.sortObject.length == 0) {
      aggregationFetchparameter.sortObject = [
      { sortColumnName: 'lastName', sortOrder: 'asc' },
      { sortColumnName: 'firstName', sortOrder: 'asc' },
      { sortColumnName: 'fileNumber', sortOrder: 'asc' }
    ];
    }

    const body = {
      itemsPerPage: aggregationFetchparameter.itemsPerPage,
      pageNumber: aggregationFetchparameter.pageNumber,
      // sortColumnName: aggregationFetchparameter.sortObject[0].sortColumnName,
      // sortOrder: aggregationFetchparameter.sortObject[0].sortOrder,
      sortObject: aggregationFetchparameter.sortObject,
      searchText: text,
      filters: filters,
      favorites: aggregationFetchparameter.favorites,
      currencyAbbr: aggregationFetchparameter.preferredCurr
    };
    aggregationFetchparameter.preferredCurr ? body.currencyAbbr = aggregationFetchparameter.preferredCurr : delete body.currencyAbbr;
    return this.baseClient.post<EmployeeDetailsView>(`v1/employeeDetails`, body, 'get employee details').pipe(
      map(r => r.body),
      catchError((err, source) => {
        const empty: EmployeeDetailsView = null;
        this.logSvc.logError(err);
        return of(empty);
      })
    );
  }

  /** get the employee information baased on the file number */
  getEmployeeInfo(fileNumber: number, favUser: number): Observable<EmployeeInfo> {
    return this.baseClient.getOne<EmployeeInfo>(`v1/empInfo/${fileNumber}?favUser=${favUser}&context=benefits-builder`, 'getting user').pipe(
      map(r => r.body),
      catchError((err, source) => {
        const empty: EmployeeInfo = null;
        this.logSvc.logError(err);
        return of(empty);
      })
    );
  }


  /** update favorite based on the file number */
  updateFavorite(favoriteParam: any): Observable<any> {
    return this.baseClient.put<any>(`v1/favorites`, favoriteParam, 'update favorite').pipe(
      map(r => r.body),
      catchError((err, source) => {
        this.logSvc.logError(err);
        // tslint:disable-next-line: deprecation
        return of(empty);
      })
    );
  }

  /** get favorite List */
  getFavoriteList(): Observable<any> {
    return this.baseClient.getOne<any>(`v1/favoritesList`, 'get favorite list').pipe(
      map(r => r.body),
      catchError((err, source) => {
        this.logSvc.logError(err);
        // tslint:disable-next-line: deprecation
        return of(empty);
      })
    );
  }
}

