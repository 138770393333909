import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatExpansionModule, MatCardModule, MatIconModule, MatFormFieldModule,
        MatButtonModule, MatInputModule, MatMenuModule, MatCheckboxModule, MatChipsModule, MatSlideToggleModule,MatAutocompleteModule } from '@angular/material';
import { FlexModule } from '@angular/flex-layout';
import { DetailComponent } from './detail/detail.component';
import { ReactiveFormsModule,FormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SharedModule } from './../../../../core/shared.module';
import { EditBenefitDialogComponent } from './components/edit-benefit-dialog/edit-benefit-dialog.component';
import {MatSelectModule} from '@angular/material/select';
import { SortbyPipe } from '../../pipes/sortby.pipe';
import { PointsLogicModalComponent } from './components/points-logic-modal/points-logic-modal.component';
import { BenefitSettingDialogComponent } from './components/benefit-setting-dialog/benefit-setting-dialog.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { CdkDropListScrollContainerDirective } from './../../../../core/directives/drop-list-scroll-container.directive'
import { SharedTraditionalOrderModule } from '../../../../../app/shared/sharedTraditionalOrder.module';
@NgModule({
  declarations: [DetailComponent, EditBenefitDialogComponent,SortbyPipe, BenefitSettingDialogComponent, CdkDropListScrollContainerDirective],
  imports: [
    SharedTraditionalOrderModule,
    CommonModule,
    MatCardModule,
    MatExpansionModule,
    FlexModule,
    MatIconModule,
    MatFormFieldModule,
    FormsModule,
    MatButtonModule,
    MatInputModule,
    MatCheckboxModule,
    MatMenuModule,
    MatSnackBarModule,
    SharedModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatChipsModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    DragDropModule
  ],
  exports: [DetailComponent],
  schemas: [ NO_ERRORS_SCHEMA ],
  entryComponents:[
    EditBenefitDialogComponent,
    PointsLogicModalComponent,
    BenefitSettingDialogComponent
  ],
  bootstrap: [PointsLogicModalComponent],
})
export class ProgramModule { }
