import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatAutocompleteTrigger, MatTableDataSource } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CandidateProfileService } from './../../../../services/candidate-profile.service';
export interface noMatchedData {
  details: string;
  priorValue: string;
  searchCriteria: string;
}
@Component({
  selector: 'app-point-recalculation-modal',
  templateUrl: './point-recalculation-modal.component.html',
  styleUrls: ['./point-recalculation-modal.component.scss']
})
  

export class PointRecalculationModalComponent implements OnInit {
  displayedColumns: string[] = ['details', 'recordValue', 'searchCriteria'];
  displaycol1value: String[] = ['Offer extended Date','Job Level','Family Type','Destination country','Departure country']
  dataSource:any = new MatTableDataSource();
  isChecked:boolean= false;
  checkedList: any[];
  datas : any;

  constructor(
    public dialogRef: MatDialogRef<PointRecalculationModalComponent>,
    private candidateProfileService: CandidateProfileService,
    private readonly spinner: NgxSpinnerService,
    private readonly toastrService: ToastrService,
    public live: LiveAnnouncer,
     @Inject(MAT_DIALOG_DATA) public data: any
  ) { }
  totalNumberOfRelocatingMembers
  ngOnInit() {
      this.datas = this.data;
      let displayData: noMatchedData[] = [];
      this.displaycol1value.forEach(element => {
        if (element == 'Departure country') {
          if (this.datas.pointsValue.priorValueInfo.departureCountry || this.datas.pointsValue.newValueInfo.departureCountry) {
            displayData.push({
              details: 'Departure country',
              priorValue: this.datas.pointsValue.priorValueInfo.departureCountry ? this.datas.pointsValue.priorValueInfo.departureCountry : 'None',
              searchCriteria: this.datas.pointsValue.newValueInfo.departureCountry
            })
          }
        } else if (element == 'Destination country') {
          if (this.datas.pointsValue.priorValueInfo.destinationCountry || this.datas.pointsValue.newValueInfo.destinationCountry) {
            displayData.push({
              details: 'Destination country',
              priorValue: this.datas.pointsValue.priorValueInfo.destinationCountry ? this.datas.pointsValue.priorValueInfo.destinationCountry : 'None',
              searchCriteria: this.datas.pointsValue.newValueInfo.destinationCountry
            })
          }
        } else if (element == 'Offer extended Date') {
          if (this.datas.pointsValue.priorValueInfo.offerExtendedDate || this.datas.pointsValue.newValueInfo.offerExtendedDate) {
            displayData.push({
              details: 'Offer extended Date',
              priorValue: this.datas.pointsValue.priorValueInfo.offerExtendedDate ? this.datas.pointsValue.priorValueInfo.offerExtendedDate.split('T')[0] : 'None',
              searchCriteria: this.datas.pointsValue.newValueInfo.offerExtendedDate.split('T')[0]
            })
          }
        } else if (element == 'Job Level') {
          if (this.datas.pointsValue.priorValueInfo.jobLevel || this.datas.pointsValue.newValueInfo.jobLevel) {
            displayData.push({
              details: 'Job Level',
              priorValue: this.datas.pointsValue.priorValueInfo.jobLevel  ? this.datas.pointsValue.priorValueInfo.jobLevel : 'None',
              searchCriteria: this.datas.pointsValue.newValueInfo.jobLevel
            })
          }
        } else if (element == 'Family Type') {
          if (this.datas.pointsValue.priorValueInfo.familyType || this.datas.pointsValue.newValueInfo.familyType) {
            displayData.push({
              details: 'Family Type',
              priorValue: this.datas.pointsValue.priorValueInfo.familyType ? this.datas.pointsValue.priorValueInfo.familyType : 'None',
              searchCriteria: this.datas.pointsValue.newValueInfo.familyType
            })
          }
        }
        this.dataSource = new MatTableDataSource(displayData);
        this.spinner.hide();
    });
   }

   onDismiss(evt) {
    evt.preventDefault();
    this.dialogRef.close(null)
  }

  onConfirm() {
    this.spinner.show();
    this.saveCandidate((this.data));

  }

  saveCandidate(data) {
    let value = {
      "newValueInfo": data.pointsValue.newValueInfo,
      "totalPoints": data.pointsValue.totalPoints,
      "orderId" : data.orderID
  }
    this.candidateProfileService.updatePointsRecalculation(value).subscribe(
      (response) => {
        this.spinner.hide();
        if (response) {
          let message = "Recalculated Points Updated";
          this.flashAndCloseDialog(message, true);
        }
      },
      err => {
        this.spinner.hide();
      }
    );
  }
  flashAndCloseDialog(message: string, success) {
    if (success) {
      this.toastrService.info(message, null, {
        closeButton: true,
        enableHtml: true,
        disableTimeOut: false // User must explicitly dismiss error messages
      });
      this.live.announce(message);
      this.dialogRef.close(this.data);
    } else {
      this.toastrService.error(message, null, {
        closeButton: true,
        enableHtml: true,
        disableTimeOut: false // User must explicitly dismiss error messages
      });
    }
  }
 }
