import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { ProgramManagementService } from '../../services/program-management.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-deactivate-program-modal',
  templateUrl: './deactivate-program-modal.component.html',
  styleUrls: ['./deactivate-program-modal.component.scss']
})
export class DeactivateProgramModalComponent implements OnInit {
	constructor(
		public dialog: MatDialog,
		public dialogRef: MatDialogRef<DeactivateProgramModalComponent>,
		private programService: ProgramManagementService,
		private spinner: NgxSpinnerService,
		private toastrService: ToastrService,
		@Inject(MAT_DIALOG_DATA) public data,
	) {}

  ngOnInit() {}

  buttonAction(label) {
 	if (label === 'Deactivate') {
		this.deactivate();
	} else if (label === 'Delete') {
		this.deleteProgram();
	}
  }

	/**
	* Get Deactivate program
	*/
	deactivate() {
		this.spinner.show();
		const deactivateProgramRequest = { 'deactivateProgram': this.data.programIdList };
		this.programService.deactivateProgram(deactivateProgramRequest).subscribe(
			(response) => {
				if (response) {
					if (response.code === "PROGRAM_DEACTIVATE_SUCCESS") {
						this.dialogRef.close(Object.assign({}, {
							isSuccessful: true,
							deactivatedProgramData: this.data,
							data:response.data
						}));
					} else {
						this.dialogRef.close(Object.assign({}, {
							isSuccessful: false,
						}));
					}
				}
				this.spinner.hide();
			},
			err => {
				this.spinner.hide();
				this.displayToastr("Error occured while Deactivation");
			});
	}

	/**
	* To to close dialogbox
	*/
	close() {
		this.dialogRef.close([]);
	}

	/**
   * To display informative messages on screen
   * @param message message to be displayed
   */
	displayToastr(message) {
		this.toastrService.info(message, null, {
			closeButton: true,
			enableHtml: true,
			disableTimeOut: false,
		});
	}

	deleteProgram() {
		this.spinner.show();
		const deleteProgram = { 'deleteProgram': this.data.programIdList };
		this.programService.deleteProgram(deleteProgram).subscribe(
			(response) => {
				if (response) {
					if (response.deletionResponse.successList.length > 0) {
						this.dialogRef.close(Object.assign({}, {
							isSuccessful: true,
							data:response.deletionResponse.successList
						}));
					} else {
						this.dialogRef.close(Object.assign({}, {
							isSuccessful: false,
						}));
					}
				}
				this.spinner.hide();
			},
			err => {
				this.spinner.hide();
				this.displayToastr("Error occured while Deleting program");
			});
	}
}
