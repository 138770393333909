import { Component, OnInit, Input } from '@angular/core';
import { CategoryService } from '../../services/category.service';
import { ClientContactsService } from '../../services/client-contacts.service';
import { ProgramManagementService, Benefit, ProgramRequest, ProgramBenefitUpdateRequest } from '../../services/program-management.service';

@Component({
  selector: 'app-transferee-offerings-list',
  templateUrl: './transferee-offerings-list.component.html',
  styleUrls: ['./transferee-offerings-list.component.scss']
})
export class TransfereeOfferingsListComponent implements OnInit {

 /** the transferee offerings to be displayed */
 @Input() benefitsList: Array<{ group: string, items: Array<Benefit> }>;
  public BenefitColorMapping = [];
 
 show: Array<Array<boolean>> = [];
 showChar = 200;
  component: { benefits: { reference: string; productSpecificationReference: string; displayName: string; description: string; category: string; points: number; editTitle: boolean; editDescription: boolean; }[]; };
  isMMUser: boolean;
  constructor(
    private readonly programService: ProgramManagementService,
    private categoryService: CategoryService,
    private clientContactsService: ClientContactsService) { 
      this.isMMUser = this.clientContactsService.isMMUser;
    }

  ngOnInit() {
    this.BenefitColorMapping = this.categoryService.BenefitColorMapping;
    this.benefitsList ? this.benefitsList.forEach((benefitGroup,i) => {
      if(!this.show[i]){
        this.show[i] = [];
      }
      for(let j=0; j< benefitGroup.items.length ; j++) {
        
        this.show[i].push(false);
        
      }
    }) : null;
  }

  assignBenefitColor(benefit) {
    return this.BenefitColorMapping[benefit];
  }

  keyUpSeeMore(event: KeyboardEvent,i , index) {
    if (event.key === ' ' || event.key === 'Enter') {
      event.preventDefault();
      this.show[i][index]=!this.show[i][index];
    }
  }

}
