import { Component, OnInit, ViewChild, Input, Output, EventEmitter, ViewEncapsulation, OnDestroy, AfterViewInit } from '@angular/core';
import { MatSnackBar, PageEvent, SortDirection, MatDialog, MatDialogRef } from '@angular/material';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UserSessionService } from '../../../core/services/user-session.service';
import { Subscription } from 'rxjs';
import { Client } from '../../../core/models/client.model';
import { ClientContactsView } from '../../../core/models/client-contacts-view.model';
import { ClientContacts } from '../../../core/models/client-contacts.model';
import { UserTypeService, UserType } from '../../administration/services/user-type.service';
import { ClientContactDetailsComponent } from '../client-contact-details/client-contact-details.component';
import { ClientService } from '../../administration/services/client.service';
/** This component displays list of clientContacts to select. */

@Component({
  selector: 'app-client-contacts',
  templateUrl: './client-contacts.component.html',
  styleUrls: ['./client-contacts.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ClientContactsComponent implements OnInit, AfterViewInit, OnDestroy {
  /** To paginate in a mat table */
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  /** To sort the mat table columns */
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  /** displayedColumns to display in the mat-table*/
  displayedColumns: string[] = ['fullName'];
  /** clientContactData to store the data from API*/
  clientContactData: ClientContacts[] = [];
  /** dataSource variable to store the response converted to mat table datasource */
  dataSource: MatTableDataSource<ClientContacts>;
  /** clientContactsSub subscription variable*/
  clientContactsSub: Subscription;
  /** client value passed from the parent component*/
  @Input() client: Client;
  /** backToClients to send a flag, to getClients from the parent component*/
  @Output() backToClients = new EventEmitter();
  /** clientContactSelected to pass the selectedClientContact to parent component*/
  @Output() clientContactSelected = new EventEmitter();
  /** showLogout to pass the showLogout to parent component*/
  @Output() showLogout: EventEmitter<any> = new EventEmitter();

  /** Output the sortOptions */
  @Output() sortList = new EventEmitter<{
    sortBy: string,
    sortDirection: string
  }>();

  /** sortDirection to hold the asc or desc direction value */
  sortDirection: 'ASC' | 'DESC' | null;
  
  /** sortColumnName to hold the clicked column name for sorting */
  sortColumnName: 'status' | null;
  
  /** searchValue to pass the searched value on the icon click method */
  searchValue: string;
  /**store the filter value */
  filterText = '';

  isSearchCleared = true;

  showError = false;

  /** initialSortColumn to hold the column name for inital sorting. */
  initialSortColumn = 'fullName';

  /** initialSortDirection to hold the column name for inital sorting. */
  initialSortDirection = 'asc';

  /** Base constructor method
   * @param userSessionService UserSessionService injection
   */

  /** user role */
  userRole: UserType;
  /**sso data */
  sso: any;
  ssoRegex: any;
  ssoRegexErrorMessage: any;

  constructor(
    public dialog: MatDialog,
    private userSessionService: UserSessionService,
    private ClientService: ClientService,
    private userTypeService: UserTypeService,) { 
      this.userRole = this.userTypeService.userType;
    }

  /** Init method to call the methods on page load */
  ngOnInit() {
    const clientPartyId = this.client.clientPartyId;
    this.getClientContacts(clientPartyId);
    this.getRoleData();
    
  }

  getRoleData() {
    this.ClientService.getBbRole(this.client.clientPartyId).subscribe(res => {
      if(res && res.loginUserRoleCapabilities[0].clientRoles[0].sso) {
        this.sso = res.loginUserRoleCapabilities[0].clientRoles[0].sso
        this.ssoRegex = res.loginUserRoleCapabilities[0].clientRoles[0].ssoRegex
        this.ssoRegexErrorMessage = res.loginUserRoleCapabilities[0].clientRoles[0].ssoRegexErrorMessage
      }
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.dataSource.sortingDataAccessor = (item, property) => {
        if(property === 'fullName'){
            return item['firstName'];
        }
      };
      const sortState: Sort = { active: 'fullName', direction: 'asc' };
      this.sort.active = sortState.active;
      this.sort.direction = sortState.direction;
      this.dataSource.sort = this.sort;
    });
  }

  /** Get Datasource */
  getDataSource() {
    this.dataSource = new MatTableDataSource<ClientContacts>(this.clientContactData);
    this.dataSource.paginator = this.paginator;
    if (this.sort.active && this.sort.start) {
      this.initialSortColumn = this.sort.active;
      this.initialSortDirection = this.sort.start;
    }
    this.sort.disableClear = true;
    this.dataSource.sort = this.sort;
  }

  /** getClients method to go back to select clients page */
  getClients() {
    this.backToClients.emit();
  }

  /** selectClientContact method to pass the selected client to parent component */
  selectClientContact(clientContact) {
    this.clientContactSelected.emit(clientContact);
    this.showLogout.emit(false);
  }

  /** applySearch method to get the filtered results based on entered value */
  applySearch(filterValue: string) {
    filterValue = filterValue.trim();
    this.searchValue = filterValue;
    if (filterValue.length > 2 || (!this.isSearchCleared && filterValue === '')) {
      if (filterValue.length > 2) {
        this.isSearchCleared = false;
      } else {
        this.isSearchCleared = true;
      }
      this.showError = false;
      this.filterText = filterValue.trim();
      filterValue = filterValue.toLowerCase();
      this.dataSource.filter = filterValue;
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    } else {
      this.showError = true;
      this.getDataSource();
    }
  }

  toggleError() {
    this.showError = false;
  }

  /** clearSearchTextResults to clear the search results */
  clearSearchTextResults() {
    this.searchValue = '';
    this.filterText = '';
    this.getDataSource();
  }

  /** To unsubscribe the subcribed method */
  ngOnDestroy(): void {
    if (this.clientContactsSub) {
      this.clientContactsSub.unsubscribe();
    }
  }

  /** To Clear the initalSort variables */
  clearInitialSort(): void {
    this.initialSortColumn = null;
    this.initialSortDirection = null;
  }

  /**
   * Sort table based on Column name.
   * @param columnName Column name to be sorted.
   */
  applySort(columnName: string) {
    this.initSort(columnName);
    this.sortList.emit({
      sortBy: columnName,
      sortDirection: this.sortDirection
    })
  }

  /**
   * Init Sort ..
   * @param columnName Column name to be sorted.
   */
  initSort(columnName: string): void {
    if (!this.sortDirection && !this.sortColumnName) {
      this.sortDirection = 'ASC';
      this.sortColumnName = columnName as any;
      return;
    }
    this.sortDirection == 'ASC' ? this.sortDirection = 'DESC' : this.sortDirection = 'ASC';
    this.sortColumnName = columnName as any;
  }

  /**
   * To get clientContacts
   * @param clientPartyId client party id
   */
  getClientContacts(clientPartyId){
    this.clientContactsSub = this.userSessionService.getClientContacts(clientPartyId).subscribe((clientContacts: ClientContactsView) => {
      this.clientContactData = clientContacts.clientContacts;
      this.clientContactData.forEach(element => {
        element.fullName = element.lastName + ', ' + (element.firstName);
      });
      this.getDataSource();
    });
    
    setTimeout(() => {
      this.showLogout.emit(true);
    });
    if (this.userRole === 'client-account-manager' || this.userRole === 'application-reliability-analyst') {
      this.displayedColumns.includes('status') ? null  : this.displayedColumns.push('status');
    }
  }

  /**
   * To open client contact details in editable modal
   * @param client selected client contact details
   */
  showEditDialog(client: any): void {
    const ssoData = {
      sso : this.sso,
      ssoRegex: this.ssoRegex,
      ssoRegexErrorMessage: this.ssoRegexErrorMessage
    }
    const dialogRef = this.dialog.open(ClientContactDetailsComponent, {
      panelClass: 'dialog-full-width',
      data: {
        client: client,
        company: this.client.clientName,
        ssoData : ssoData
      }
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.getClientContacts(this.client.clientPartyId);
      }
    })

  }


}
