import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MatStepper } from '@angular/material';

@Component({
  selector: 'app-submission-failure',
  templateUrl: './submission-failure.component.html',
  styleUrls: ['./submission-failure.component.scss']
})
export class SubmissionFailureComponent implements OnInit {
  @ViewChild('stepper', { static: false }) stepper: MatStepper;
  isReviewComp: boolean = false;
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<SubmissionFailureComponent>
  ) { }

  ngOnInit() {
  }

  onDismiss(evt) {
    evt.preventDefault();
    this.dialogRef.close(null)
  }

  CompleteStep() {
    this.stepper.next();
    this.isReviewComp = true;
  }

}
