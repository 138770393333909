import { Component, OnInit, Input, ViewChild, Output, EventEmitter, HostListener, SimpleChanges, OnChanges } from '@angular/core';
import { OverlayRef } from 'ngx-toastr';
import { candidateProfile } from './../../../../core/models/candidate.model';
import { MatTableDataSource, PageEvent, SortDirection, MatSort, MatPaginator, MatDialog, MatSortHeaderIntl } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { DateConversionService } from './../../../../core/services/date-conversion.service';
import { KEY_CODE } from './../../../../public/dashboard/components/employee-details/employee-details.component';
import { AddEditTransfereeComponent } from '../add-edit-transferee/add-edit-transferee.component';
import { AdminClient } from './../../../../core/models/admin-client.model';
import { ConsultantOnlyModalComponent } from '../consultant-only-modal/consultant-only-modal.component';
import { CustomClientModalComponent } from '../../../components/custom-client-modal/custom-client-modal.component';
import { ClientContactsService } from '../../services/client-contacts.service';
import { ClientService } from '../../services/client.service';
import { FamilyInfoService } from '../../services/family-info.service';
import { UserTypeService } from '../../services/user-type.service';


@Component({
  selector: 'app-client-list',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.scss']
})
export class ClientListComponent implements OnInit, OnChanges {

  /** overlayRef to hold overlay config */
  overlayRef: OverlayRef;

  @Input() clientList: Array<AdminClient> = [];

  /** displayedColumns variable array to store the displayed columns */
  // displayedColumns: string[] = ['clientEntityName', 'location', 'totalClientContacts', 'totalCandidates', 'totalActivePrograms']; <!--commentted as part of BB-4000 ticket-->
  displayedColumns: string[] = ['clientEntityName', 'location', 'totalCandidates'];

  /** dataSource variable to store the response converted to mat table datasource */
  dataSource: MatTableDataSource<any>;

  /** selection variable to store the collection of selections made in mat table */
  selection: SelectionModel<any>;

  /** page event variable to handle the page events */
  pageEvent: PageEvent;

  /**Direction varialbe to get sort direction */
  direction: SortDirection;

  /** To sort the mat table columns */
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  /** To paginate in a mat table */
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  /**store the filter value */
  filterText = '';

  /** To get the search text entered in table search */
  @Input() searchKeyword;

  /** To get the boolean value to display no results message */
  @Input() clearResults;

  /*Get the role of the client */
  @Input() userRole;

  /** Output the getClearCriteriaChange boolean value */
  @Output() getClearCriteriaChange = new EventEmitter<boolean>();

  @Output() openSelectedList = new EventEmitter<{ client: any, type: string }>();

  /** To get the boolean value on whether the search box has been triggered for results.
   * This determines whether to display the highlighted element in the Milestone column and hide
   * the non-highlighted element */
  @Input() searchTriggered;

  /** sortDirection to hold the asc or desc direction value */
  sortDirection: 'ASC' | 'DESC' | null;

  /** sortColumnName to hold the clicked column name for sorting */
  sortColumnName: 'clientEntityName' | 'location' | null;

  /** sortOptions to hold the sort column name and sort direction */
  sortOptions = {};

   /* manage programadmin capabilities*/
   userCapabilities: number;

  /** Output the sortOptions */
  @Output() sortList = new EventEmitter<{
    sortBy: string,
    sortDirection: string
  }>();

  @Output() refreshList = new EventEmitter<boolean>();
  customDataList:number;
  constructor(
    public dialog: MatDialog,
    private readonly matSortService: MatSortHeaderIntl,
    private clientService: ClientService,
    public readonly dateConversionService: DateConversionService,
    private clientContactsService: ClientContactsService,
    private userTypeService:UserTypeService
    ) {
    this.dataSource = new MatTableDataSource<any>(this.clientList);
    this.selection = new SelectionModel<any>(true, []);
    this.pageEvent = new PageEvent();
    this.pageEvent.pageIndex = 0;
    this.pageEvent.pageSize = 75;
    // tslint:disable-next-line: arrow-return-shorthand
    this.matSortService.sortButtonLabel = (id: string) => { return `"Change sorting for " ${id}`; };
  }


  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    // tslint:disable-next-line: deprecation
    if (event.keyCode === KEY_CODE.RIGHT_ARROW) {
      this.paginator.nextPage();
    }
    // tslint:disable-next-line: deprecation
    if (event.keyCode === KEY_CODE.LEFT_ARROW) {
      this.paginator.previousPage();
    }
  }

  ngOnInit() {
    const paginatorIntl = this.paginator._intl;
    paginatorIntl.nextPageLabel = 'Next';
    paginatorIntl.previousPageLabel = 'Previous';

    this.paginator.pageSize = this.pageEvent.pageSize;
    this.dataSource.paginator = this.paginator;
    this.userTypeService.capabilities$.subscribe(ele => {
      ele ? this.userCapabilities = ele.findIndex(element => element.name == "Benefits Builder Program Administration Portal" && (element.operation == "write" || element.operation == "read" )) : null;
    });
    if(this.userCapabilities > -1){
      this.displayedColumns.push( 'totalActivePrograms')
    }
    setTimeout(() => {
      this.paginator.length = this.clientList.length;
      this.paginator.pageIndex = this.pageEvent.pageIndex;
    }, 10);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.clientList && changes.clientList.currentValue) {
      this.dataSource.data = changes.clientList.currentValue;
      // this.paginator.pageSize = changes.clientList.currentValue.length;
      this.dataSource.paginator = this.paginator;
      this.paginator.length = changes.clientList.currentValue.length;
    }
  }

  selectClient(client: AdminClient, type: 'clientName' | 'clientContact' | 'candidates' | 'programs') {
    this.checkForClientRoles(client, type);
    sessionStorage.setItem('clientId', client.clientId);
    history.pushState(null, null, '/#/administration/client-detail')
  }

  checkForClientRoles(client: AdminClient, type: 'clientName' | 'clientContact' | 'candidates' | 'programs') {
    this.clientContactsService.isMMUser = false;
    this.clientContactsService.isGOUser = false;
    this.clientService.bbRole = [];
    this.clientService.getBbRole(client.clientId).subscribe(res => {
      if (res) {
        res.loginUserRoleCapabilities.length ?
          res.loginUserRoleCapabilities.forEach(element => {
            if (element.clientRoles[0].managedMove ) {
              this.clientContactsService.isMMUser = true;
              sessionStorage.setItem('car-ses-mmu', JSON.stringify(true));
            }
            if (element.clientRoles[0].pointsMatrix) {
              this.clientContactsService.isGOUser = true;
              sessionStorage.setItem('car-ses-goa', JSON.stringify(true));
            }
            if (element.clientRoles[0].benefitsBuilder ) {
              this.clientService.bbRole.push('benefitsBuilder');
            }
            if (element.clientRoles[0].traditionalAuthorization ) {
              this.clientService.bbRole.push('traditionalAuthorization');
            }
            if (element.clientRoles[0].costProjection ) {
              this.clientService.bbRole.push('costProjection');
            }
            if(element.clientRoles[0].sso){
              this.clientService.bbRole.push('sso');
              this.clientService.ssoRegEx = {
                'ssoRegex': element.clientRoles[0].ssoRegex,
                'ssoRegexErrorMessage':element.clientRoles[0].ssoRegexErrorMessage
              } }
          }) : null;
          this.openSelectedList.emit({ client, type });
      }
    }, err => {
      console.log(err);
      this.openSelectedList.emit({ client, type });
      });
  }


  /**
   * Sort table based on Column name.
   * @param columnName Column name to be sorted.
   */
  applySort(columnName: string) {
    this.initSort(columnName);
    this.sortList.emit({
      sortBy: columnName,
      sortDirection: this.sortDirection
    });
  }
  onCall(element){
    this.dialog.open(CustomClientModalComponent,{data:element,autoFocus:false,height:'100%',width:'800px',disableClose:true})
  }
  /**
   * Init Sort ..
   * @param columnName Column name to be sorted.
   */
  initSort(columnName: string): void {
    if (!this.sortDirection && !this.sortColumnName) {
      this.sortDirection = 'ASC';
      this.sortColumnName = columnName as any;
      return;
    }
    // tslint:disable-next-line: triple-equals
    this.sortDirection == 'ASC' ? this.sortDirection = 'DESC' : this.sortDirection = 'ASC';
    this.sortColumnName = columnName as any;
  }
}
