import { Component, Input, OnInit, ViewChild, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { MatTableDataSource, MatSort, MatSortHeaderIntl, SortDirection,MatPaginator, PageEvent, MatDialog } from '@angular/material';
import { element } from 'protractor';
import { CandidateProfileService } from '../../services/candidate-profile.service';
import { UserTypeService } from '../../services/user-type.service';
import { SubmissionFailureComponent } from '../submission-failure/submission-failure.component';
import { FormGroup } from '@angular/forms';

export interface UnpTransferee {
  transferee: string;
  client: string;
  departure: string;
  destination: string;
  date: any;
  originalsubmission: string;
  status: string
}

export interface Options {
  sortBy ?: string;
  sortDirection ?: string;
  searchText?: string,
  filters ?: any,
  itemsPerPage?: number,
  pageNumber?: number
}

@Component({
  selector: 'app-unprocessed-list',
  templateUrl: './unprocessed-list.component.html',
  styleUrls: ['./unprocessed-list.component.scss']
})
export class UnprocessedListComponent implements OnInit, OnChanges {
  displayedColumns: string[] = ['transferee', 'client', 'departure', 'destination', 'date', 'original submission', 'status'];
    dataSource = new MatTableDataSource();
    displayUnprocessedData: UnpTransferee[] = [];

    /** To sort the mat table columns */
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  /** Pagination of Unprocessed transferee */
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  /**Direction varialbe to get sort direction */
  direction: SortDirection;

   /** sortDirection to hold the asc or desc direction value */
   sortDirection: 'ASC' | 'DESC' | null;

   /** sortColumnName to hold the clicked column name for sorting */
  sortColumnName: 'transferee' | 'client' | 'departure' | 'destination' | 'date' | 'status' | null;

  /** Options to hold the sort column name | sort direction | pagination */
  options: Options = {};

  @Input() searchValue: string;

  /** page event variable to handle the page events */
  pageEvent: PageEvent;
  searchKeyword: string;
  manageAuthorizationFailures: string;
  originalData: any;

  constructor(
         private candidateService: CandidateProfileService,
         private readonly matSortService: MatSortHeaderIntl,
         private dialog: MatDialog,
         private userTypeService: UserTypeService
      ) {
        this.matSortService.sortButtonLabel = (id: string) => { return `"Change sorting for " ${id}`; };
        this.pageEvent = new PageEvent();
        this.pageEvent.pageIndex = 0;
        this.pageEvent.pageSize = 75;
        this.options.pageNumber = this.pageEvent.pageIndex;
        this.options.itemsPerPage = this.pageEvent.pageSize;
      }

  ngOnChanges(changes: SimpleChanges): void {
    this.searchKeyword = this.searchValue;
  }
      
  ngOnInit() {
    this.userTypeService.capabilities$.subscribe(ele => {
      ele ? this.checkCapabilities(ele) : null;
    });

    this.dataSource.sort = this.sort;
    if(this.paginator) {
      const paginatorIntl = this.paginator._intl;
      paginatorIntl.nextPageLabel = 'Next';
      paginatorIntl.previousPageLabel = 'Previous';
      this.paginator.pageSize = this.pageEvent.pageSize;
      this.dataSource.paginator = this.paginator;
    }
    this.searchCheck();
    this.checkFilters();
    this.subscribeToUnpocessList();
    localStorage.removeItem('view-org')
  }
  searchCheck() {
    this.candidateService.searchText.subscribe(
      (searchText) => {
        this.searchKeyword = searchText;
        this.options['searchText'] = searchText;
        this.subscribeToUnpocessList(this.options);
      }
    )
  }

  /**
   * Sort table based on Column name.
   * @param columnName Column name to be sorted.
   */
   applySort(columnName: string) {
    this.initSort(columnName);
    this.options.sortBy = columnName;
    this.options.sortDirection = this.sortDirection;
    this.subscribeToUnpocessList(this.options);
  }
  /**
   * Init Sort ..
   * @param columnName Column name to be sorted.
   */
   initSort(columnName: string): void {
    if (!this.sortDirection && !this.sortColumnName) {
      this.sortDirection = 'ASC';
      this.sortColumnName = columnName as any;
      return;
    }
    // tslint:disable-next-line: triple-equals
    this.sortDirection == 'ASC' ? this.sortDirection = 'DESC' : this.sortDirection = 'ASC';
    this.sortColumnName = columnName as any;
  }

  checkCapabilities (capabilitiesList: any []) {
    capabilitiesList.forEach(capability => {
      if(capability && capability.permission === 'allow') {
        if(capability.name ==="MP - Manage Authorization Failures") {
          capability.operation == 'read' ?  this.manageAuthorizationFailures = 'read' :
          capability.operation == 'write' ? this.manageAuthorizationFailures = 'write': null;
        }
        }
       });
    }


  getUnprocessData(data) {
    this.dataSource.data = [];
    this.dataSource = new MatTableDataSource(data.candidates);
  }

  subscribeToUnpocessList(options?) {
    if (!options) {
      options = {
        itemsPerPage: this.pageEvent.pageSize,
        pageNumber: this.pageEvent.pageIndex
      };
    }
    this.candidateService.getUnprocessedTransferee(options).subscribe(
      res => {
        this.paginator.length = 0;
        if (res) {
          this.getUnprocessData(res);
          if(this.paginator){
            this.paginator.length = res.count;
            this.paginator.pageIndex = this.pageEvent.pageIndex;
            this.paginator.pageSize = this.pageEvent.pageSize ? this.pageEvent.pageSize : null;
            const paginatorIntl = this.paginator._intl;
            paginatorIntl.nextPageLabel = 'Next';
            paginatorIntl.previousPageLabel = 'Previous';
            }
        } else {
          this.dataSource = new MatTableDataSource([]);
        }
      });
  }

  onPageChange(e) {
    if (this.pageEvent && e.pageSize && this.pageEvent.pageSize !== this.pageEvent.pageSize) {
      this.pageEvent.pageIndex = 0;
    } else {
      this.pageEvent.pageIndex = e.pageIndex;
      this.pageEvent.pageSize = e.pageSize;
    }
    this.options.itemsPerPage = this.pageEvent.pageSize;
    this.options.pageNumber = this.pageEvent.pageIndex;
    this.subscribeToUnpocessList(this.options);
  }

  checkFilters() {
    this.candidateService.unprocessedFilesFiletrs.subscribe(
      (res) => {
        this.options['filters'] = res;
        this.subscribeToUnpocessList(this.options);
      }
    )
  }

  onStatusClick(candidate) {
    const dialogRef = this.dialog.open(SubmissionFailureComponent, {
      data: {
        candidate: candidate
      },
      width: '52vw',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(result => {
      this.subscribeToUnpocessList();
      
    });
}

goToLink(data) {
  var newWindows = window.open('/#/original-submission','_blank');
  newWindows.localStorage.setItem('view-org', 'true');
  let object = { 
    "candidate": data,
    "client": data.client,
    "authorizationDate": data.authorizationDate,
    "validationMessages": data.validationMessages,
  }
  newWindows['data'] = object
}

}
