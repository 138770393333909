import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { HttpParams } from "@angular/common/http";
import { EventEmitter, Injectable } from '@angular/core';
import { BaseClientService } from './../../../core/services/base-client.service';
import { map, catchError } from 'rxjs/operators';
import { of, Observable, BehaviorSubject } from 'rxjs';
import { RemoteLoggingService } from './../../../core/services/remote-logging.service';


export interface GetConfigurableField  {
	includeDeActivated?:string;
	clientPartyId?: string;
	includeNotAuthorized?:string;
}

export interface ConfigurableField {
	displayName: string;
	type?: string;
	valueList?: string[];
	_specType?: string;
	value?: any;
}

export const fieldMapping = [
	{
		"DataItemTypDescr": "Text",
		"dataType": "string",
		"defaultValue": "",
		"base_specType": "scopedsimpleAttribute"
	},
	{
		"DataItemTypDescr": "Multiple Select",
		"dataType": "string",
		"defaultValue": [],
		"base_specType": "scopedlistboundMultiAttribute "
	},
	{
		"DataItemTypDescr": "Single Select",
		"dataType": "string",
		"defaultValue": "",
		"base_specType": "scopedlistboundSingleAttribute"
	},
	{
		"DataItemTypDescr": "Date",
		"dataType": "Date",
		"defaultValue": "",
		"base_specType": "scopedsimpleAttribute"
	},
	{
		"DataItemTypDescr": "Country",
		"dataType": "string",
		"defaultValue": "",
		"base_specType": "scopedsimpleAttribute"
	},
	{
		"DataItemTypDescr": "Number",
		"dataType": "number",
		"defaultValue": "",
		"base_specType": "scopedsimpleAttribute"
	},
	{
		"DataItemTypDescr": "Currency",
		"dataType": {currencyCode:"string", currencyAmount:"number"},
		"defaultValue": {currencyCode: "", currencyAmount: null },
		"base_specType": "scopedsimpleAttribute"
	},
	{
		"DataItemTypDescr": "Integer",
		"dataType": "number",
		"defaultValue": "",
		"base_specType": "scopedsimpleAttribute"
	}
];

@Injectable({
  providedIn: 'root'
})

export class ConfigurableFieldService {
	clientList = new BehaviorSubject<Array<any>>(null);
  
	constructor(private baseClientService: BaseClientService, private logSvc: RemoteLoggingService) { }
  
	getConfigurableFields(clientPartyId: string, includeDeActivated: boolean, includeNotAuthorized: boolean): Observable<ConfigurableField[]> {
		let httpParams = new HttpParams();
		httpParams = httpParams.set('clientPartyId', clientPartyId);
		httpParams = httpParams.set('includeDeActivated', `${includeDeActivated}`);
		httpParams = httpParams.set('includeNotAuthorized', `${includeNotAuthorized}`);
		return this.baseClientService
			.getOne<any>(`${'v1/admin/configurable-field?' + httpParams}`)
			.pipe(
				map(r => r.body),
				catchError(err => {
					this.logSvc.logError(err);
					const emptyArray = null;
					return of(emptyArray);
				})
			);
	}

  
  }

  
