import { Injectable, Injector } from '@angular/core';
import { phoneTypes as staticPhoneTypes } from '../../../core/models/phone.model';
import { emailTypes as staticEmailTypes } from '../../../core/models/email.model';
import { FormGroup, FormBuilder, Validators, AbstractControl, ValidationErrors, FormArray } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class AddEditSharedService {

  constructor(
    private injector: Injector
  ) { }

  initEmailList(emailDetails, 
    emailTypeList: Array<Array<string>>): { emailTypes: Array<Array<string>> } {
    const usedEmailTypes = [];
    emailDetails.forEach((email, index) => {
      emailTypeList[index] = [...staticEmailTypes];
      if (index == 0) {
        usedEmailTypes.push(email.usageType);
      } else {
        emailTypeList[index] = emailTypeList[index].filter(emailType => !usedEmailTypes.includes(emailType));
      }
    });

    return { emailTypes: emailTypeList };
  }

  initPhoneList(phoneDetails, phoneTypesList: Array<Array<string>>):
   { phoneTypes: Array<Array<string>> } {
    const usedPhoneTypes = [];
    phoneDetails.forEach((phone, index) => {
      phoneTypesList[index] = [...staticPhoneTypes];
      if (index == 0) {
        usedPhoneTypes.push(phone.type);
      } else {
        phoneTypesList[index] = phoneTypesList[index].filter(phoneType => !usedPhoneTypes.includes(phoneType));
      }
    });
    return { phoneTypes: phoneTypesList };

  }
  addNewMemberRow(familyForm: FormGroup, newIndex: number) {
    const fb = this.injector.get(FormBuilder);
    (familyForm.controls.accompaningMember as FormArray).push(fb.group({
      emailAddress: [null, [Validators.pattern('^[A-Za-z0-9!#$%&\'*+/=?^_‘{|}~-]+(?:\\.[A-Za-z0-9!#$%&\'*+/=?^_‘{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])$')]],
      relationship: [null, [Validators.required]],
      salutation: [null],
      firstName: [null, [Validators.required]],
      lastName: [null, [Validators.required]],
      phoneNumber: [null,[Validators.minLength(7),
        Validators.maxLength(18), Validators.min(1000000), Validators.max(999999999999999999),
        Validators.pattern('^[0-9]*$')]],
      phoneDialCode:[null],
      currentAge: [],
      isDelete:[false]
    }));
  }
  addNewPhoneEmailRow(type: 'phone' | 'email', contactForm: FormGroup, newIndex: number, phoneTypesList: any, emailTypesList: any) {
    const fb = this.injector.get(FormBuilder);
    if (type == 'phone') {
      const existingPhoneTypes: Array<string> = ((contactForm.controls.phone as FormArray).
      getRawValue() as any).map(phone => phone.type);

      // updating phoneTypes
      phoneTypesList[newIndex] = [...staticPhoneTypes];
      phoneTypesList[newIndex] = phoneTypesList[newIndex].filter(phone => !existingPhoneTypes.includes(phone));

      (contactForm.controls.phone as FormArray).push(fb.group({
        // primary: false,
        type: [null, [Validators.required]],
        phoneDialCode: [null, [Validators.required]],
        phoneNumber: [null, [Validators.required, Validators.minLength(7), Validators.maxLength(18),
          Validators.min(1000000), Validators.max(999999999999999999), Validators.pattern('^[0-9]*$')]]
      }));
    } else {
      // need to find used phone types and filter from existing.
      const existingEmailTypes: Array<string> = ((contactForm.controls.email as FormArray).
      getRawValue() as any).map(email => email.usageType);

      // updating emailTypes
      emailTypesList[newIndex] = [...staticEmailTypes];
      emailTypesList[newIndex] = emailTypesList[newIndex].filter(email => !existingEmailTypes.includes(email));

      (contactForm.controls.email as FormArray).push(fb.group({
        // primary: false,
        emailAddress: [null, [Validators.required, Validators.pattern('^[A-Za-z0-9!#$%&\'*+/=?^_‘{|}~-]+(?:\\.[A-Za-z0-9!#$%&\'*+/=?^_‘{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])$')]],
        usageType: [null, [Validators.required]],
      }));
    }
  }

  /**
   * Remove row from Mobile or Email and insert the deleted email/phone type to the list of email/phone 
   * used by needs assessment and add family information
   * @param type location of the row
   * @param index index of the location
   * @param contactForm Contact Form
   * @param phoneTypes Phone List
   * @param emailTypes Email List
   */
   deletePhoneEmailRow(type: 'phone' | 'email', index: number, contactForm: FormGroup, phoneTypes: any,
   emailTypes: any): Array<Array<string>> {
     if (type === 'phone') {
       const missingPhoneType: string = (contactForm.getRawValue()).phone[index].type;
       if (missingPhoneType) {
         phoneTypes = phoneTypes.map(phoneType => {
           phoneType.push(missingPhoneType);
           return [...new Set(phoneType)];
         });
       }
       (contactForm.controls.phone as FormArray).removeAt(index);
       return phoneTypes;
     } else {
       const missingEmailType: string = (contactForm.getRawValue()).email[index].usageType;
       if (missingEmailType) {
         emailTypes = emailTypes.map(emailType => {
           emailType.push(missingEmailType);
           return [...new Set(emailType)];
         });
       }
       (contactForm.controls.email as FormArray).removeAt(index);
       return emailTypes;
     }
  }
  
  deleteFamilyRow(familyForm: FormGroup, index: number) {
    (familyForm.controls.accompaningMember as FormArray).removeAt(index);
  }
}
