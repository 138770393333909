import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { PointRecalculationModalComponent } from '../point-recalculation-modal/point-recalculation-modal.component';
export interface noMatchedData {
  details: string;
  recordValue: string;
  searchCriteria: string;
}

@Component({
  selector: 'app-points-recalculation-history-modal',
  templateUrl: './points-recalculation-history-modal.component.html',
  styleUrls: ['./points-recalculation-history-modal.component.scss']
})
  

export class PointsRecalculationHistoryModalComponent implements OnInit {
  displayedColumns: string[] = ['details', 'recordValue', 'searchCriteria'];
  testStructuredData: any = [];
  dataSource:any = new MatTableDataSource
  constructor(public dialogRef: MatDialogRef<PointRecalculationModalComponent>,@Inject(MAT_DIALOG_DATA) public data: any) { }
  tableData: any;
  
  ngOnInit() {
    this.tableData = this.data;
  this.tableData.forEach((ele, index) => {
      let displayData: noMatchedData[] = [];
      if (ele.newValueInfo && ele.newValueInfo.departureCountry) {
        displayData.push({
          details: 'Departure country',
          recordValue: ele.priorValueInfo ? ele.priorValueInfo.departureCountry : 'None' ,
          searchCriteria: ele.newValueInfo.departureCountry
        })
      }
      
      if (ele.newValueInfo && ele.newValueInfo.destinationCountry) {
        displayData.push({
          details: 'Destination country',
          recordValue: ele.priorValueInfo ? ele.priorValueInfo.destinationCountry : 'None' ,
          searchCriteria: ele.newValueInfo.destinationCountry
        })
      }
      
      if (ele.newValueInfo && ele.newValueInfo.offerExtendedDate) {
        displayData.push({
          details: 'Offer extended Date',
          recordValue: ele.priorValueInfo ? ele.priorValueInfo.offerExtendedDate.split('T')[0] :'None' ,
          searchCriteria: ele.newValueInfo.offerExtendedDate.split('T')[0]
        })
      }
      
      if (ele.newValueInfo && ele.newValueInfo.jobLevel) {
        displayData.push({
          details: 'Job Level',
          recordValue: ele.priorValueInfo ? ele.priorValueInfo.jobLevel :'None',
          searchCriteria: ele.newValueInfo.jobLevel
        })
      }
      
      if (ele.newValueInfo && ele.newValueInfo.familyType) {
        displayData.push({
          details: 'Family Type',
          recordValue: ele.priorValueInfo ? ele.priorValueInfo.familyType : 'None',
          searchCriteria: ele.newValueInfo.familyType
        })
      }
     this.testStructuredData.push(displayData);
    ele.tableValue = this.testStructuredData[index];
    if (ele.manualOverride) {
        delete ele.tableValue
      }
    });
  }
  onDismiss(evt) {
    evt.preventDefault();
    this.dialogRef.close(null)
  }

}
