import { Component, OnInit, Inject, Input, Output, SimpleChanges } from '@angular/core';
import { AddFamilyInfoDialogComponent } from './../add-family-info-dialog/add-family-info-dialog.component';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FamilyMember } from './../../../../../../core/models/family-member.model';
import { FamilyInfoService } from './../../../../services/family-info.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { UserType, UserTypeService } from './../../../../services/user-type.service';
import { EventEmitter } from '@angular/core';

/** Family card delete warning message */
export const familyMemDelWarningMsg = 'Are you sure you want to delete?';
export const failureMsg = 'We are unable to process your request at this time. Please try again later.';

@Component({
  selector: 'app-family-info',
  templateUrl: './family-info.component.html',
  styleUrls: ['./family-info.component.scss']
})

export class FamilyInfoComponent implements OnInit {
  /**Contains family member capability */
  @Input() familyMemberCapability: string;
  /**Contains total number of family members relocating including yourself*/
  @Input() relocatingMembers: number;
  /**Contains total number of family members relocating*/
  totalNumberOfRelocatingMembers: number;
  /**Flag to check if family details are available*/
  retrievedFamilyDetails = false;
  /**contains family details*/
  familyDetails: Array < FamilyMember > = [];
  /** To store count of family members to be updated */
  newFamilyInformation: number;
  /** To store user role */
  userRole: UserType;
  /** Flag to set card to read only by role */
  isFamilyReadOnly = false;
  /** Flag to identify if needs assessment has been completed by candidate */
  isNeedsAssessmentCompleted: boolean;
  /** To store the new family member length*/
  updatedFamilyLength = 0;
  /** To emit updated number of relicating members */
  @Output() updateMembers: EventEmitter < number > = new EventEmitter < number > ();

  @Input() moveStatus: string;
  /**
   * @param dialog Inject dialog object
   * @param familyInfoService Inject family information service
   * @param spinner Inject spinner
   * @param toastrService Inject notification service
   */
  constructor(private dialog: MatDialog,
    private familyInfoService: FamilyInfoService,
    private readonly spinner: NgxSpinnerService,
    private readonly toastrService: ToastrService,
    private userTypeService: UserTypeService,
  ) {}

  ngOnInit() {
    this.spinner.show();
    this.retrievedFamilyDetails = false;
    // if needs aseessment has been completed by candidate 
    // if (this.relocatingMembers > 0) {
      this.isNeedsAssessmentCompleted = true;
      this.userRole = this.userTypeService.userType;
      if (this.userRole === 'file-setup-specialist' || this.userRole === 'consultant' || this.userRole === 'client-contact-initiator') {
        this.isFamilyReadOnly = false;
      } else {
        this.isFamilyReadOnly = true;
      }
      this.familyInfoService.getFamilyMemberInfo().subscribe(familyMemberList => {
        if (familyMemberList && familyMemberList.length > 0) {
          this.displayExistingFamilyDetails(familyMemberList);
        } else {
          this.totalNumberOfRelocatingMembers = this.relocatingMembers - 1;
          this.newFamilyInformation = this.totalNumberOfRelocatingMembers;
          this.retrievedFamilyDetails = true;
        }
        this.spinner.hide();
      });
    // } else {
    //   this.isNeedsAssessmentCompleted = false;
    //   this.spinner.hide();
    // }
  }

  ngOnChanges(changes: SimpleChanges) {
    changes && changes.familyMemberCapability && changes.familyMemberCapability.currentValue ? this.familyMemberCapability = changes.familyMemberCapability.currentValue : null;
  }

  /**
   * To display list of updated family members
   * @param familyMemberList List of family members retrieved
   */
  displayExistingFamilyDetails(familyMemberList) {
    this.spinner.show();
    this.familyDetails = familyMemberList;
    this.totalNumberOfRelocatingMembers = (this.familyDetails.length > this.relocatingMembers - 1) ?
      this.familyDetails.length : this.relocatingMembers - 1;
    this.updatedFamilyLength = this.familyDetails.length;
    this.newFamilyInformation = this.totalNumberOfRelocatingMembers - this.familyDetails.length;
    this.emitUpdatedMemberCount(this.totalNumberOfRelocatingMembers);
    this.retrievedFamilyDetails = true;
    this.spinner.hide();
  }

  /**
   * To delete family members from the cards
   * @param index index of family member to be deleted
   */
  deleteFamilyMember(index: number) {
    const dialogRef = this.dialog.open(WarningDialogComponent, {
      id: 'dialog2',
      width: 'auto',
      autoFocus: false,
      data: {
        message: familyMemDelWarningMsg
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.action === 'delete') {
        dialogRef.close();
        const familycardToBeDeleted = (this.familyDetails[index] && this.familyDetails[index].id) ? this.familyDetails[index].id :
          'deleteEmptyCard';
        this.deleteFamilyInformation(familycardToBeDeleted, index);
      }
    });
  }

  /**
   * To add new family members
   */
  addFamilyMember() {
    const dialogRef = this.dialog.open(AddFamilyInfoDialogComponent, {
      width: '890px',
      panelClass: 'custom-dialog',
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.event === 'add') {
        this.familyDetails = this.familyDetails.concat(result.data);
        if (this.familyDetails.length < this.newFamilyInformation) {
          this.updatedFamilyLength = this.familyDetails.length;
          this.displayExistingFamilyDetails(this.familyDetails);
        } else {
          this.totalNumberOfRelocatingMembers = this.familyDetails.length;
          this.relocatingMembers = this.familyDetails.length + 1;
          this.displayExistingFamilyDetails(this.familyDetails);
          this.emitUpdatedMemberCount(this.totalNumberOfRelocatingMembers);
        }
      }
    });
  }

  /**
   * To open edit component on click of family member
   * @param id index of family member clicked for editing
   */
  editFamilyMember(id) {
    const dialogRef = this.dialog.open(AddFamilyInfoDialogComponent, {
      width: '890px',
      panelClass: 'custom-dialog',
      data: this.familyDetails[id],
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == 'update') {
        const updatedFamilyData = this.familyDetails.find(member => member.id === result.data.id);
        const indexOfUpdatedFamily = this.familyDetails.indexOf(updatedFamilyData);
        this.familyDetails[indexOfUpdatedFamily] = result.data;

      }
    });
  }
  /**
   * Track elements during addition and deletion of cards
   * @param index index of item
   * @param item item
   */
  trackByFn(index, item) {
    return item.id;
  }

  /**
   * To delete family member from family details and needs assessment shared service
   * @param familyMemberId id of family member to be deleted
   * @param index index of family member card to be deleted
   */
  deleteFamilyInformation(familyMemberId, index) {
    this.spinner.show();
    this.familyInfoService.deleteFamilyMemberInfo(familyMemberId)
      .subscribe(statusCode => {
          if (statusCode === 204) {
            (this.familyDetails.length >= index) ? this.familyDetails.splice(index, 1): null;
            this.totalNumberOfRelocatingMembers--;
            this.relocatingMembers = this.totalNumberOfRelocatingMembers + 1;
            this.displayExistingFamilyDetails(this.familyDetails);
            this.spinner.hide();
          } else {
            this.toastrService.error(failureMsg, null, {
              closeButton: true,
              enableHtml: true,
              disableTimeOut: false // User must explicitly dismiss error messages
            });
          }
          this.spinner.hide();
        },
        err => {
          this.spinner.hide();
        });
  }

  /**
   * To emit updated family member count to detail component
   * @param updatedcount updated family member count
   */
  emitUpdatedMemberCount(updatedcount) {
    this.updateMembers.emit(updatedcount);
  }

  getTooltipContent(member) {
    return `Age is calculated based on when this information was provided to us, which was on ${member.age && member.age.recordedAtDate && member.age.recordedAtDate.split('T').shift()}`;
  }
}

/**
 * Component to display warning dialog box on dismiss
 */
@Component({
  selector: 'app-warning-dialog',
  template: `<div mat-dialog-content>{{ data.message }}<div>
    <div class="button-wrapper">
     <button mat-button (click)='onDelete()' class="delete-card">Yes</button>
     <button mat-button [mat-dialog-close]='true' cdkFocusInitial class="cancel">No</button>
   </div>`,
})
export class WarningDialogComponent {
  constructor(
    public dialog: MatDialogRef < WarningDialogComponent > ,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  onDelete(): void {
    this.dialog.close({
      action: 'delete'
    });
  }
}
