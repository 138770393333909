import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { LinkedListComponent } from '../../core/components/linked-list/linked-list.component';
import {
  MatTableModule,
  MatMenuModule,
  MatSortModule,
  MatPaginatorModule,
  MatTooltipModule,
  MatDialogModule,
  MatButtonModule,
  MatFormFieldModule,
  MatInputModule,
  MatChipsModule,
  MatSelectModule,
  MatCardModule,
  MatDividerModule,
  MatButtonToggleModule,
  MatExpansionModule,
  MatIconModule,
  MatCheckboxModule,
  MatListModule,
  MatDatepickerModule,
  MatNativeDateModule, MatSlideToggleModule
} from '@angular/material';
import { A11yModule } from '@angular/cdk/a11y';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard.component';
import { EmployeeDetailsComponent } from './components/employee-details/employee-details.component';
import { DashboardViewModalComponent } from './components/dashboard-view-modal/dashboard-view-modal.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
// import { EmployeeInfoComponent } from './components/employee-info/employee-info.component';
import { AggregationViewComponent } from './components/aggregation-view/aggregation-view.component';
// import { MultiStepComponent } from '../../core/components/multi-step/multi-step.component';
import { FilterModalComponent } from './components/filter-modal/filter-modal.component';
import { CostTransactionsComponent } from './components/cost-transactions/cost-transactions.component';
import { EmployeeRecentTransactionsComponent } from './components/employee-recent-transactions/employee-recent-transactions.component';
import { EmployeeRecentSentimentComponent } from './components/employee-recent-sentiment/employee-recent-sentiment.component';
import { ProviderPaymentSummaryComponent } from './components/provider-payment-summary/provider-payment-summary.component';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../../../environments/environment';
// import { EmployeeHeaderComponent } from './components/employee-header/employee-header.component';
import { FilterSelectionComponent } from '../../core/components/filter-selection/filter-selection.component';
// import { StarRatingModule } from 'angular-star-rating';
import { AvatarModule } from '../../core/components/avatar/avatar.module';
import { EmployeeRecentMilestonesComponent } from './components/employee-recent-milestones/employee-recent-milestones.component';
import { ModalData } from '../../core/services/popup-position.service';
import { PowerBiReportContainerComponent } from '../../core/components/powerbi-report-container/powerbi-report-container.component';
import { PowerBiReportComponent } from '../../core/components/powerbi-report/powerbi-report.component';
import { PowerBiModalComponent } from '../../core/components/powerbi-modal/powerbi-modal.component';
import { NgxPowerBiModule } from 'ngx-powerbi';
import { FilterChipComponent } from '../../core/components/filter-chip/filter-chip.component';
import { HighlightSearchPipe } from '../dashboard/pipes/highlight-search/highlight-search.pipe';
import { TruncatePipe } from '../dashboard/pipes/truncate/truncate.pipe';
// import { BreadcrumbComponent } from '../components/breadcrumb/breadcrumb.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MoveRiskComponent } from '../../core/components/move-risk/move-risk.component';
import { AccountSettingsComponent } from './components/account-settings/account-settings.component';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { DataviewSelectionModalComponent } from './components/dataview-selection-modal/dataview-selection-modal.component';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { SharedTraditionalOrderModule } from '../../shared/sharedTraditionalOrder.module';
import { MoveRiskModalComponent } from './components/move-risk-modal/move-risk-modal.component';
import { EmployeeMovePhaseComponent } from './components/employee-move-phase/employee-move-phase.component';
import { CronofyAvailablilityPickerComponent } from '../administration/modules/cronofy-availablility-picker/cronofy-availablility-picker/cronofy-availablility-picker.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    A11yModule,
    MatMenuModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatTooltipModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatSelectModule,
    MatIconModule,
    MatCardModule,
    MatExpansionModule,
    MatDividerModule,
    MatButtonToggleModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatChipsModule,
    MatIconModule,
    MatListModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FormsModule,
    MatCheckboxModule,
    AvatarModule,
    NgxPowerBiModule,
    NgxSpinnerModule,
    DragDropModule,
    CdkTableModule,
    CdkTreeModule,
    MatSlideToggleModule,
    MatInputModule,
    environment.animation ? BrowserAnimationsModule : NoopAnimationsModule,
    DashboardRoutingModule,
    // StarRatingModule.forRoot(),
    SharedTraditionalOrderModule
  ],
  exports: [
    // EmployeeHeaderComponent, 
    // BreadcrumbComponent, 
    HighlightSearchPipe, 
    NgxSpinnerModule,
    EmployeeRecentTransactionsComponent
  ],

  declarations: [
    DashboardComponent,
    EmployeeDetailsComponent,
    // BreadcrumbComponent,
    DashboardViewModalComponent,
    FilterModalComponent,
    AggregationViewComponent,
    // MultiStepComponent,
    // LinkedListComponent,
    // EmployeeInfoComponent,
    AggregationViewComponent,
    CostTransactionsComponent,
    EmployeeRecentTransactionsComponent,
    EmployeeRecentSentimentComponent,
    ProviderPaymentSummaryComponent,
    FilterSelectionComponent,
    // EmployeeHeaderComponent,
    EmployeeRecentMilestonesComponent,
    PowerBiReportContainerComponent,
    PowerBiReportComponent,
    PowerBiModalComponent,
    HighlightSearchPipe,
    FilterChipComponent,
    HighlightSearchPipe,
    TruncatePipe,
    MoveRiskComponent,
    AccountSettingsComponent,
    DataviewSelectionModalComponent,
    CronofyAvailablilityPickerComponent
    //MoveRiskModalComponent,
    
  ],
  providers: [
    MatDatepickerModule,
    { provide: ModalData, useValue: 'PopupPositionService' }
  ],
  entryComponents: [
    DashboardViewModalComponent,
    EmployeeRecentTransactionsComponent,
    EmployeeRecentSentimentComponent,
    FilterModalComponent,
    ProviderPaymentSummaryComponent,
    EmployeeRecentMilestonesComponent,
    PowerBiReportContainerComponent,
    PowerBiReportComponent,
    PowerBiModalComponent,
    MoveRiskModalComponent,
    EmployeeMovePhaseComponent,
    DataviewSelectionModalComponent,
  ]
})
export class DashboardModule { }
