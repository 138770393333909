import { Component, Inject, OnInit } from '@angular/core';
import { CandidateProfileService } from '../../../services/candidate-profile.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-origin-view-submission',
  templateUrl: './origin-view-submission.component.html',
  styleUrls: ['./origin-view-submission.component.scss']
})

export class OriginViewSubmissionComponent implements OnInit {
  originalData: any;

  constructor( ) { }

  ngOnInit() {
    localStorage.removeItem('view-org')
    this.originalData = window['data'];
    this.getOptionalDetals();
  }

  getOptionalDetals() {
    if(this.originalData && this.originalData.candidate && this.originalData.candidate.optionalDetails) {
      this.originalData.candidate.optionalDetails = Object.entries(this.originalData.candidate.optionalDetails)
    }
    console.log(window['data'])
    this.originalData = window['data'];
  }

}
