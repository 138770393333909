import { Component, OnInit, Inject, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatAutocompleteTrigger } from '@angular/material';
import { EmptyError, Observable } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { map, startWith } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { Country } from '../../../../core/models/country.model';
import { LocationsService } from '../../services/locations.service';
import { MatTableDataSource, MatMenuTrigger } from '@angular/material';
import { CandidateProfileService } from './../../services/candidate-profile.service';
import { AddEditTransfereeComponent } from '../add-edit-transferee/add-edit-transferee.component';
import { DupCheckPopupComponent } from '../dup-check-popup/dup-check-popup.component';
import { ClientContactsService } from '../../services/client-contacts.service';
import { UserTypeService } from '../../services/user-type.service';



@Component({
  selector: 'app-search-duplicate-check',
  templateUrl: './search-duplicate-check.component.html',
  styleUrls: ['./search-duplicate-check.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SearchDuplicateCheckComponent implements OnInit {

  @ViewChild(MatMenuTrigger, {
    static: false
  }) menuFor: MatMenuTrigger;
  displayedColumns: string[] = ['fullName', 'email', 'departureAddr', 'destinationAddr', 'add'];
  dataSource = new MatTableDataSource();
  readonly formControl: AbstractControl;
  /**Countries retrieved from service */
  countryList: Array<Country> = [];
  /** List of filtered options by autocomplete phone code field */
  filteredOptions: Observable<Country[]>;
  formDisable = false;
  isEditable = false;
  titleValues: string[] = ['Dr.', 'Mr.', 'Mrs.', 'Ms.', 'Miss'];
  addCusForm: FormGroup;
  formTitle: string;
  fname: string;
  lname: string;
  ename: string;
  ssoNumber:string;
  searchValue: string;
  searchfname: string;
  searchlname: string;
  searchEmail: string;
  searchEmp: string;
  searchSSO: string;
  PhoneCode: number;
  PhoneNumber: number;
  title: string;
  checkConfirm: Array<boolean> = [];
  manageAuthorization: number;
  bbOrTrad: boolean = false;
  checkData: Array<string> = [];
  emailFieldSize: any;

  constructor(
    private readonly locationService: LocationsService,
    public dialogRef: MatDialogRef<SearchDuplicateCheckComponent>,
    private readonly formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data,
    private readonly spinner: NgxSpinnerService,
    public dialog: MatDialog,
    private readonly toastrService: ToastrService,
    public live: LiveAnnouncer,
    private datePipe: DatePipe,
    private candidateProfileService: CandidateProfileService,
    private readonly clientContactsService: ClientContactsService,
    private userTypeService: UserTypeService,
  ) { }

  ngOnInit() {
    this.bbOrTrad = ((this.data.bbRole.includes('benefitsBuilder') && !this.data.bbRole.includes('traditionalAuthorization')) || (!this.data.bbRole.includes('benefitsBuilder') && this.data.bbRole.includes('traditionalAuthorization')));
    this.data.bbRole.includes('sso')? this.displayedColumns =  ['fullName', 'email','sso', 'departureAddr', 'destinationAddr', 'add'] : null;
    this.emailFieldSize = this.data.bbRole.includes('sso') ? 26 : 48;
    this.userTypeService.capabilities$.subscribe(ele => {
      ele ? this.manageAuthorization = ele.findIndex(element => element.name == "Manage Authorizations" && element.operation == "write") : null;
    });
    this.formTitle = this.data.isCostProjection? 'Add Cost Projection': 'Add Transferee';
    this.addCusForm = this.formBuilder.group({
      firstName: [this.fname, [Validators.required,]],
      lastName: [this.lname, [Validators.required,]],
      EmployeeName: [this.ename],
      sso:[],
      email: [null, [Validators.required, Validators.email, Validators.pattern('^[A-Za-z0-9!#$%&\'*+/=?^_‘{|}~-]+(?:\\.[A-Za-z0-9!#$%&\'*+/=?^_‘{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])$')]],
      title: [null],
      phoneDialingCode: [null, [Validators.required, Validators.pattern('^[0-9]*$')]],
      phoneNumber: [null, [Validators.required, Validators.maxLength(18), Validators.minLength(7),
      Validators.pattern('^[0-9]*$'), Validators.max(999999999999999999)]],
    });
    this.locationService.countryList.subscribe(countryList => {
      this.filteredOptions = this.addCusForm.get('phoneDialingCode').valueChanges
        .pipe(
          startWith(''),
          map(value => this._filter(value))
        );
      if (countryList.length > 0) {
        countryList.sort((a, b) => a.countryName.localeCompare(b.countryName));
        this.countryList = countryList;
      }
    });

  }
  private _filter(value): Country[] {
    if (value) {
      const filterValue = typeof value === 'number' ? value : value.toLowerCase();
      const countryList = this.countryList.length ? this.countryList.filter(option => option.countryName.toLowerCase().indexOf(filterValue) === 0) : [];
      return countryList;
    } else {
      return this.countryList;
    }
  }
  /**
   * Invoked on click of dismiss/close
   */
  onDismiss(evt) {
    evt.preventDefault();
    this.dialogRef.close(null)
  }

  onDismissTable() {
    this.isEditable = false;
    this.addCusForm.enable();
  }



  public onEditClick() {
    this.dataSource.data = [];
    this.isEditable = true;
    this.addCusForm.disable();
    let reqObj = {
      name: {
        title: this.addCusForm.get('title').value,
        firstName: this.addCusForm.get('firstName').value,
        lastName: this.addCusForm.get('lastName').value
      },
      contact: {
        phoneDialingCode: '+' + this.addCusForm.get('phoneDialingCode').value,
        phoneNumber: this.addCusForm.get('phoneNumber').value,
        email: this.addCusForm.get('email').value
      },
      employeeId : this.addCusForm.get('EmployeeName').value,
      ssoIdentifier: this.addCusForm.get('sso').value,
      clientId: undefined
    }
    //console.log(reqObj);
    this.userTypeService.userType === 'file-setup-specialist' ?
    this.clientContactsService.clientId.subscribe(r => reqObj.clientId = r) : null;
    reqObj.employeeId == null || reqObj.employeeId == "" ? delete reqObj.employeeId: null;
    reqObj.ssoIdentifier == null || reqObj.ssoIdentifier == "" ? delete reqObj.ssoIdentifier: null;
    this.candidateProfileService.getCandidateCheck(reqObj).subscribe(data => {
      this.dataSource.data = data;
      this.dataSource.data.forEach((i, index) => {
        this.checkConfirm[index] = false;
      });
    });
  }

  // adding new transferee
  openDialog(isTraditional) {
    this.dialogRef.close();
    const preData = {
      nameDetails: {
        title: this.addCusForm.get('title').value,
        firstName: this.addCusForm.get('firstName').value,
        lastName: this.addCusForm.get('lastName').value
      },
      phoneDetailsList:[{
        phoneDialCode: this.addCusForm.get('phoneDialingCode').value,
        phoneNumber: this.addCusForm.get('phoneNumber').value,
        type: 'Mobile',
        textingAvailable: true,
      }],
      emailDetailsList: [{
        emailAddress: this.addCusForm.get('email').value,
        usageType: "Business",
      }],
      optionalDetails: {
        employeeID: this.addCusForm.get('EmployeeName').value
      },
      ssoIdentifier: this.addCusForm.get('sso').value
    }
    const dialogRef = this.dialog.open(AddEditTransfereeComponent, {
      data: {
              isTraditional: isTraditional,
              associateTransferee: false,
              isCostProjection: this.data.isCostProjection,
              candidate: preData
            },
      panelClass: 'dialog-full-width',
      id: 'addEditDialog'
    });
  }

  displayFn(country: any): string {
    return country ? '+ ' + country : '';
  }

  // association of new Transferee to existing one
  openDialogAdd(isTraditional, transfereeData) {
    let isTransfereeSSOValue: boolean = true; 
    this.dialogRef.close();
    const candidatePartyId = transfereeData._id;
    delete transfereeData._id;
    delete transfereeData.departureAddr;
    delete transfereeData.destinationAddr;
    transfereeData.nameDetails = {
      firstName: transfereeData.name[0],
      lastName: transfereeData.name[1]
    }
    transfereeData.optionalDetails = {
      employeeID: transfereeData.empId
    }
    //transfereeData.name
    //transfereeData.nameDetails[1] = transfereeData.name[1];
    delete transfereeData.name;
    delete transfereeData.empId;
    this.checkData.forEach(ele => {
      if(ele === 'Full Name') { //over write condition
        transfereeData.nameDetails.firstName = this.addCusForm.get('firstName').value;
        transfereeData.nameDetails.lastName = this.addCusForm.get('lastName').value;
        transfereeData.overWriteName = true;
      }
      if(ele === 'Mobile Phone #') {
        if (transfereeData.phoneDetailsList[0]) {
          transfereeData.phoneDetailsList[0].phoneDialCode = this.addCusForm.get('phoneDialingCode').value;
          transfereeData.phoneDetailsList[0].phoneNumber = this.addCusForm.get('phoneNumber').value;
          transfereeData.overWritePhone = true;
        }
      }
      if(ele === 'Email Address') {
        if (transfereeData.emailDetailsList[0]) {
          transfereeData.emailDetailsList[0].emailAddress = this.addCusForm.get('email').value;
          transfereeData.overWriteEmail = true;
        }
      }
      if(ele === 'Emp #') {
        transfereeData.optionalDetails ? transfereeData.optionalDetails.employeeID = this.addCusForm.get('EmployeeName').value : null;
        transfereeData.overWriteEmpId = true;
      }
    })
    if(transfereeData.ssoIdentifier == null || transfereeData.ssoIdentifier == "") {
      transfereeData.ssoIdentifier =  this.addCusForm.get('sso').value;
      isTransfereeSSOValue = false;
    }
    transfereeData.nameDetails.title = this.addCusForm.get('title').value;
    //transfereeData.optionalDetails ? transfereeData.optionalDetails.employeeID = this.addCusForm.get('EmployeeName').value : null;
    const dialogRef = this.dialog.open(AddEditTransfereeComponent, {
      data: {
              isTraditional: isTraditional,
              associateTransferee: true,
              candidate: transfereeData,
              candidatePartyId: candidatePartyId,
              isCostProjection: this.data.isCostProjection,
              isTransfereeSSOValue: isTransfereeSSOValue,
            },
      panelClass: 'dialog-full-width',
      id: 'addEditDialog'
    });
    dialogRef.afterClosed().subscribe(data => {
      this.dialogRef.close()
    });
  }

  openWarningPopUp(isTraditional,searchRecord, recordedValue , transfereeData){
    const dialogRef = this.dialog.open(DupCheckPopupComponent, {
      data:{
        searchRecord : searchRecord,
        transfereeRecord: recordedValue
      },
      // panelClass: 'dialog-full-width',
      width: "60vw",
    });
    dialogRef.afterClosed().subscribe(res => {
       if(res.data){
        //this.checkConfirm[i] = true;
        this.checkData = res.data
        this.preCheckRoleToOPenAddPopUp(isTraditional, transfereeData);

      }
    });
  }

  /**
   *  New Implementation
  */

  openWarningOrAddModal(isTraditional, transfereeData) {
    var searchRecord = []
    var recordedValue = []
    if(transfereeData.name && transfereeData.name[0].toLowerCase() != this.addCusForm.get('firstName').value.toLowerCase() || transfereeData.name && transfereeData.name[1].toLowerCase() != this.addCusForm.get('lastName').value.toLowerCase()) {
      searchRecord.push({name1: this.addCusForm.get('firstName').value , name2:this.addCusForm.get('lastName').value , title: 'Full Name'});
      recordedValue.push(`${transfereeData.name[0] },${transfereeData.name[1]}`)
    }
    if(transfereeData.empId != this.addCusForm.get('EmployeeName').value) {
      searchRecord.push({value: this.addCusForm.get('EmployeeName').value , title: 'Emp #'});
      recordedValue.push(transfereeData.empId)
    }
    if(transfereeData.phoneDetailsList[0] && transfereeData.phoneDetailsList[0].phoneDialCode != this.addCusForm.get('phoneDialingCode').value || transfereeData.phoneDetailsList[0] && transfereeData.phoneDetailsList[0].phoneNumber != this.addCusForm.get('phoneNumber').value) {
      searchRecord.push({code: this.addCusForm.get('phoneDialingCode').value , value: this.addCusForm.get('phoneNumber').value, title: 'Mobile Phone #'});
      recordedValue.push(`+${transfereeData.phoneDetailsList[0].phoneDialCode} ${ transfereeData.phoneDetailsList[0].phoneNumber}`)
    }
    if(transfereeData.emailDetailsList[0] && transfereeData.emailDetailsList[0].emailAddress.toLowerCase() != this.addCusForm.get('email').value.toLowerCase()) {
      searchRecord.push({value:this.addCusForm.get('email').value, title: 'Email Address'});
      recordedValue.push(transfereeData.emailDetailsList[0].emailAddress);
    }
    if(transfereeData.ssoIdentifier && this.addCusForm.get('sso').value && transfereeData.ssoIdentifier != this.addCusForm.get('sso').value) {
      searchRecord.push({value:this.addCusForm.get('sso').value, title: 'SSO#'});
      recordedValue.push(transfereeData.ssoIdentifier);
    }
    if (searchRecord.length) {
      //mis-match
      this.openWarningPopUp(isTraditional, searchRecord, recordedValue, transfereeData);
    } else {
      //match
      this.preCheckRoleToOPenAddPopUp(isTraditional, transfereeData);
    }
  }

  preCheckRoleToOPenAddPopUp(isTraditional, transfereeData) {
    if(this.bbOrTrad) {
      // handled for not bb role
      this.data.bbRole.includes('benefitsBuilder') ?this.openDialogAdd(false, transfereeData): null;
      this.data.bbRole.includes('traditionalAuthorization') ?this.openDialogAdd(true, transfereeData): null;
    } else {
      this.openDialogAdd(isTraditional, transfereeData);
    }
  }
  

}


