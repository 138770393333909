import { Injectable, NgModule, NO_ERRORS_SCHEMA } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { NgxSpinnerModule } from "ngx-spinner";
import { AdministrationRoutingModule } from "./administration-routing.module";
import { AdministrationComponent } from "./administration.component";
import { AddEditTransfereeComponent } from "./components/add-edit-transferee/add-edit-transferee.component";
import { WarningDialogComponent } from "./components/add-edit-transferee/add-edit-transferee.component";
import { CharacterOnlyDirective } from "./../../core/directives/character-only.directive";
import { CharacterSpaceOnlyDirective } from "./../../core/directives/character-space-only.directive";
import { MultiTypeDirective } from "./../../core/directives/multi-type.directive";
import { CharNumSpecialDirective } from "./../../core/directives/char-num-special.directive";
import { SharedModule } from "./../../core/shared.module";
import { MatRadioModule } from "@angular/material/radio";
import {
  MatFormFieldModule,
  MatInputModule,
  MatButtonModule,
  MatIconModule,
  MatDialogModule,
  MatSelectModule,
  MatAutocompleteModule,
  MatExpansionModule,
  MatDatepickerModule,
  MatCardModule,
  MatTableModule,
  MatTooltipModule,
  MatSortModule,
  MatPaginatorModule,
  MatNativeDateModule,
  MatCheckboxModule,
  MatMenuModule,
  MatDividerModule,
  MatButtonToggleModule,
  MatChipsModule,
  MatListModule,
  MatTabsModule,
  MatSlideToggleModule,
  MatStepperModule
} from "@angular/material";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { FlexLayoutModule } from "@angular/flex-layout";
import { TransfereeListComponent } from "./components/transferee-list/transferee-list.component";
// import { MatMomentDateModule, MomentDateModule } from '@angular/material-moment-adapter';

import {DragDropModule} from '@angular/cdk/drag-drop';

import { ListFilterModalComponent } from "./components/transferee-list-filter/list-filter-modal/list-filter-modal.component";
import { AdminFilterSelectionComponent } from "./components/transferee-list-filter/filter-selection/filter-selection.component";
import { ClientListComponent } from "./components/client-list/client-list.component";
import { ClientDetailsComponent } from "./components/client-details/client-details.component";
import { ClientContactsListComponent } from "./components/client-contacts-list/client-contacts-list.component";
import { ProgramListComponent } from "./components/program-list/program-list.component";
import { ListComponent } from "./components/list/list.component";
import {
  ProgramModalComponent,
  ProgramCloseWarningComponent
} from "./components/program-modal/program-modal.component";
import { ProgramModule } from "./modules/program/program.module";
import { TransfereeModule } from "./modules/transferee/transferee.module";
import { SharedTransfereeProgramModule } from "./modules/shared/shared.module";
import { AdminDataviewSelectionModalComponent } from "./components/admin-dataview-selection-modal/admin-dataview-selection-modal.component";
import { DivisionAssociationComponent } from "./components/division-association/division-association.component";
import { CopyProgramModalComponent } from "./components/copy-program-modal/copy-program-modal.component";
import { DeactivateProgramModalComponent } from "./components/deactivate-program-modal/deactivate-program-modal.component";
import { AdminFilterChipComponent } from "./components/admin-filter-chip/admin-filter-chip.component";
import { FilterProgramModalComponent } from "./components/filter-program-modal/filter-program-modal.component";
import { ConsultantOnlyModalComponent } from "./components/consultant-only-modal/consultant-only-modal.component";
import { ShowOverrideHistoryComponent } from "./components/show-override-history/show-override-history.component";
import { NameInitialsPipe } from "./pipes/name-initials.pipe";
import { ArrayIterationOrderPipe } from "./pipes/array-iteration-order.pipe";
import { UnconfirmBenefitsModalComponent } from "./modules/transferee/components/unconfirm-benefits-modal/unconfirm-benefits-modal.component";
import { AgmCoreModule, LAZY_MAPS_API_CONFIG } from "@agm/core";
import { MatGoogleMapsAutocompleteModule } from "@angular-material-extensions/google-maps-autocomplete";
import { PointsLogicModalComponent } from "./modules/program/components/points-logic-modal/points-logic-modal.component";
import { CustomdataDeleteConfirmComponent } from "../components/customdata-delete-confirm/customdata-delete-confirm.component";
import { ViewProgamDetailsComponent } from "./components/view-program-details/view-program-details-component";
import { TransfereeOfferingsListComponent } from "./components/transferee-offerings-list/transferee-offerings-list.component";
import { SearchDuplicateCheckComponent } from "./components/search-duplicate-check/search-duplicate-check.component";
import { DupCheckPopupComponent } from "./components/dup-check-popup/dup-check-popup.component";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { SharedTraditionalOrderModule } from "../../shared/sharedTraditionalOrder.module";
import { ApproveMovePopupComponent } from "./components/approve-move-popup/approve-move-popup.component";
import { AppConfigService } from "../../core/services/app-config.service";
import { AtlasPolicyWarningComponent } from './components/atlas-policy-warning/atlas-policy-warning.component';
import { CronofyAvailablilityPickerModule } from "./modules/cronofy-availablility-picker/cronofy-availablility-picker/cronofy-availablility-picker.module";
import { CronofyAvailablilityPickerComponent } from "./modules/cronofy-availablility-picker/cronofy-availablility-picker/cronofy-availablility-picker.component";
import { UnprocessedListComponent } from "./components/unprocessed-list/unprocessed-list.component";
import { UnprocessedFilterModalComponent } from './components/unprocessed-filter-modal/unprocessed-filter-modal.component';
import { SubmissionFailureComponent } from './components/submission-failure/submission-failure.component';
import { DuplicateCheckComponent } from './components/submission-failure/duplicate-check/duplicate-check.component';
import { ErrorReviewComponent } from './components/submission-failure/error-review/error-review.component';
import { OriginViewSubmissionComponent } from "./components/submission-failure/origin-view-submission/origin-view-submission.component";
import { DuplicateRecordsDeletePopupComponent } from "./components/submission-failure/duplicate-records-delete-popup/duplicate-records-delete-popup.component";

@Injectable()
export class GoogleMapsConfig {
  apiKey: any;
  libraries: string[];
  constructor(config: AppConfigService) {
    this.apiKey = config.getConfig("googleApiKey");
    this.libraries = ["places"];
  }
}
@NgModule({
  schemas: [NO_ERRORS_SCHEMA],
  declarations: [
    AdministrationComponent,
    AddEditTransfereeComponent,
    WarningDialogComponent,
    CharacterOnlyDirective,
    CharacterSpaceOnlyDirective,
    MultiTypeDirective,
    CharNumSpecialDirective,
    TransfereeListComponent,
    ListFilterModalComponent,
    AdminFilterSelectionComponent,
    ClientDetailsComponent,
    ClientContactsListComponent,
    ClientListComponent,
    ClientDetailsComponent,
    ProgramListComponent,
    ListComponent,
    ProgramModalComponent,
    ProgramCloseWarningComponent,
    AdminDataviewSelectionModalComponent,
    DivisionAssociationComponent,
    CopyProgramModalComponent,
    AdminFilterChipComponent,
    FilterProgramModalComponent,
    DeactivateProgramModalComponent,
    // SearchHighlightPipe,
    ConsultantOnlyModalComponent,
    PointsLogicModalComponent,
    ShowOverrideHistoryComponent,
    NameInitialsPipe,
    ArrayIterationOrderPipe,
    CustomdataDeleteConfirmComponent,
    ViewProgamDetailsComponent,
    TransfereeOfferingsListComponent,
    SearchDuplicateCheckComponent,
    DupCheckPopupComponent,
    ApproveMovePopupComponent,
    AtlasPolicyWarningComponent,
    UnprocessedListComponent,
    UnprocessedFilterModalComponent,
    SubmissionFailureComponent,
    DuplicateCheckComponent,
    ErrorReviewComponent,
    OriginViewSubmissionComponent,
    DuplicateRecordsDeletePopupComponent
  ],
  imports: [
    CommonModule,
    AdministrationRoutingModule,
    NgxSpinnerModule,
    MatAutocompleteModule,
    MatMenuModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatTooltipModule,
    MatDialogModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatCardModule,
    MatExpansionModule,
    MatDividerModule,
    MatButtonToggleModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatChipsModule,
    MatIconModule,
    MatListModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    // MomentDateModule,
    MatTabsModule,
    SharedModule,
    SharedTransfereeProgramModule,
    ProgramModule,
    CronofyAvailablilityPickerModule,
    TransfereeModule,
    MatRadioModule,
    DragDropModule,
    AgmCoreModule.forRoot(),
    MatGoogleMapsAutocompleteModule,
    NgxMaterialTimepickerModule,
    SharedTraditionalOrderModule,
    MatStepperModule

  ],
  providers: [
    DatePipe,
    MatNativeDateModule,
    { provide: LAZY_MAPS_API_CONFIG, useClass: GoogleMapsConfig }
    // MatMomentDateModule
  ],
  entryComponents: [
    AddEditTransfereeComponent,
    SearchDuplicateCheckComponent,
    WarningDialogComponent,
    ListFilterModalComponent,
    ProgramModalComponent,
    ProgramCloseWarningComponent,
    DivisionAssociationComponent,
    AdminDataviewSelectionModalComponent,
    FilterProgramModalComponent,
    CopyProgramModalComponent,
    DeactivateProgramModalComponent,
    ConsultantOnlyModalComponent,
    PointsLogicModalComponent,
    ShowOverrideHistoryComponent,
    UnconfirmBenefitsModalComponent,
    ViewProgamDetailsComponent,
    DupCheckPopupComponent,
    ApproveMovePopupComponent,
    AtlasPolicyWarningComponent,
    CronofyAvailablilityPickerComponent,
    UnprocessedFilterModalComponent,
    SubmissionFailureComponent,
    DuplicateRecordsDeletePopupComponent
  ]
})
export class AdministrationModule {}
